import logo from "./logo-black.svg";
import logoWhite from "./logo-white.svg";
import webinarImage from "./DOLCE_BLUE_JASMINE_1920x900_GRIFFE_HP.jpg";
import webinarImage2 from "./DG-light-blue.jpeg";
import "./App.css";
import { useMemo, useEffect, useState } from "react";
import serialize from "form-serialize";
import _ from "lodash";

const TAPPE = [
    { label: "9 Novembre - Catania - Hotel Baia Verde 20.30 - 23.00", value: "9 novembre" },
    { label: "14 Novembre - Napoli - Grand Hotel Vesuvio 20.30 - 23.00", value: "14 novembre" },
    { label: "20 Novembre - Bologna - I Portici Hotel 20.30 - 23.00", value: "20 novembre" },
    { label: "22 Novembre - Torino - Hotel Principi di Piemonte - 20.30", value: "22 novembre" },
];
const WEBINAR = [
    { label: "(SOLD OUT) - SERATA DOLCE&GABBANA_UN'ESPERIENZA ESCLUSIVA_23 Luglio ore 20.00", value: "SERATA DOLCE&GABBANA_UN'ESPERIENZA ESCLUSIVA_23 Luglio ore 20.00", thankyou: "23 Luglio ore 20.00<br/>SERATA DOLCE&GABBANA_UN'ESPERIENZA ESCLUSIVA" },
    { label: "(SOLD OUT) - SERATA DOLCE&GABBANA_UN'ESPERIENZA ESCLUSIVA_24 Luglio ore 20.00", value: "SERATA DOLCE&GABBANA_UN'ESPERIENZA ESCLUSIVA_24 Luglio ore 20.00", thankyou: "24 Luglio ore 20.00<br/>SERATA DOLCE&GABBANA_UN'ESPERIENZA ESCLUSIVA" }
];

function App() {
    const [puntiVendita, setPuntiVendita] = useState([
        {"title":"A & G PROFUMERIA DI GAGLIARDI LUISA GELSOMINA","acf":{"ragione_sociale":"A & G PROFUMERIA DI GAGLIARDI LUISA GELSOMINA","indirizzo":"VIA MATTEOTTI 120","citta":"BITONTO"}},
        {"title":"A & N PROFUMERIE SRL","acf":{"ragione_sociale":"A & N PROFUMERIE SRL","indirizzo":"VIA MAZZINI 48","citta":"PIETRASANTA"}},
        {"title":"A & N PROFUMERIE SRL","acf":{"ragione_sociale":"A & N PROFUMERIE SRL","indirizzo":"VIA ANTONIO ROSMINI 20/22","citta":"VIAREGGIO"}},
        {"title":"A.R. DI MUZIO R. & C. SNC","acf":{"ragione_sociale":"A.R. DI MUZIO R. & C. SNC","indirizzo":"VIA CONCA D'ORO 226/228","citta":"ROMA"}},
        {"title":"ADA SAS DI ROCCHINI MARIAVITTORIA & C","acf":{"ragione_sociale":"ADA SAS DI ROCCHINI MARIAVITTORIA & C","indirizzo":"VIA CASSIA AURELIA 77-79","citta":"CHIUSI SCALO"}},
        {"title":"ALINE FIRENZE SRL","acf":{"ragione_sociale":"ALINE FIRENZE SRL","indirizzo":"VIALE EUROPA 165","citta":"FIRENZE"}},
        {"title":"ALINE FIRENZE SRL","acf":{"ragione_sociale":"ALINE FIRENZE SRL","indirizzo":"VIA DEI CALZAIUOLI 53/R","citta":"FIRENZE"}},
        {"title":"ALINE FIRENZE SRL","acf":{"ragione_sociale":"ALINE FIRENZE SRL","indirizzo":"VIA FILIPPO CORRIDONI 168","citta":"FIRENZE"}},
        {"title":"ALINE FIRENZE SRL","acf":{"ragione_sociale":"ALINE FIRENZE SRL","indirizzo":"VIA BENCIVENNI RUCELLAI 8","citta":"CAMPI BISENZIO"}},
        {"title":"ALINE FIRENZE SRL","acf":{"ragione_sociale":"ALINE FIRENZE SRL","indirizzo":"VIA VINCENZO GIOBERTI 32/C","citta":"FIRENZE"}},
        {"title":"ALINE FIRENZE SRL","acf":{"ragione_sociale":"ALINE FIRENZE SRL","indirizzo":"VIA ERBOSA 68","citta":"FIRENZE"}},
        {"title":"ALINE FIRENZE SRL","acf":{"ragione_sociale":"ALINE FIRENZE SRL","indirizzo":"VIA SENESE 12/R","citta":"FIRENZE"}},
        {"title":"ALINE SRL","acf":{"ragione_sociale":"ALINE SRL","indirizzo":"PIAZZA ALDO MORO 7","citta":"CONVERSANO"}},
        {"title":"AMORETTI","acf":{"ragione_sociale":"AMORETTI","indirizzo":"PIAZZA SAN DONATO 4","citta":"PINEROLO"}},
        {"title":"AMORETTI","acf":{"ragione_sociale":"AMORETTI","indirizzo":"VIA FREJUS 49","citta":"TORINO"}},
        {"title":"AMORETTI","acf":{"ragione_sociale":"AMORETTI","indirizzo":"CORSO SEBASTOPOLI 174","citta":"TORINO"}},
        {"title":"AMORETTI","acf":{"ragione_sociale":"AMORETTI","indirizzo":"CORSO PESCHIERA 265","citta":"TORINO"}},
        {"title":"AMORETTI","acf":{"ragione_sociale":"AMORETTI","indirizzo":"CORSO ORBASSANO 280","citta":"TORINO"}},
        {"title":"AMORETTI","acf":{"ragione_sociale":"AMORETTI","indirizzo":"PIAZZA GARAVELLA 3","citta":"CARMAGNOLA"}},
        {"title":"AMORETTI","acf":{"ragione_sociale":"AMORETTI","indirizzo":"VIA CERNAIA 1 > VIA SOLFERINO 2","citta":"TORINO"}},
        {"title":"AMORETTI","acf":{"ragione_sociale":"AMORETTI","indirizzo":"VIA VITTORIO EMANUELE II 39","citta":"CHIERI"}},
        {"title":"AMORETTI","acf":{"ragione_sociale":"AMORETTI","indirizzo":"VIA ROMA 42","citta":"ORBASSANO"}},
        {"title":"AMORETTI","acf":{"ragione_sociale":"AMORETTI","indirizzo":"CORSO DE GASPERI 20","citta":"TORINO"}},
        {"title":"AMORETTI","acf":{"ragione_sociale":"AMORETTI","indirizzo":"CORSO TRAIANO 16","citta":"TORINO"}},
        {"title":"ANNA SRL","acf":{"ragione_sociale":"ANNA SRL","indirizzo":"CORSO VITTORIO EMANUELE 163","citta":"SALERNO"}},
        {"title":"ANTONINO RUSSOTTI & C. SNC","acf":{"ragione_sociale":"ANTONINO RUSSOTTI & C. SNC","indirizzo":"CORSO UMBERTO I 41","citta":"TAORMINA"}},
        {"title":"AR PROGRAM","acf":{"ragione_sociale":"AR PROGRAM","indirizzo":"VIA CIPRO 6","citta":"ROMA"}},
        {"title":"BEAUTY & BEAUTY SRL","acf":{"ragione_sociale":"BEAUTY & BEAUTY SRL","indirizzo":"VIA MARCO POLO 34","citta":"VIAREGGIO"}},
        {"title":"BEAUTY STORE SRL","acf":{"ragione_sociale":"BEAUTY STORE SRL","indirizzo":"VIA MICHELE BUONORI 21 - CENTRO COMMERCIALE LE TORRI","citta":"ROMA"}},
        {"title":"BEAUTYPROF SPA","acf":{"ragione_sociale":"BEAUTYPROF SPA","indirizzo":"VIALE ISONZO 41","citta":"COSENZA"}},
        {"title":"BEAUTYPROF SPA","acf":{"ragione_sociale":"BEAUTYPROF SPA","indirizzo":"CONTRADA PIZZICATO - CENTRO COMMERCIALE PEGUY","citta":"CINQUEFRONDI"}},
        {"title":"BEAUTYPROF SPA","acf":{"ragione_sociale":"BEAUTYPROF SPA","indirizzo":"CORSO GARIBALDI 188","citta":"SIDERNO"}},
        {"title":"BEAUTYPROF SPA","acf":{"ragione_sociale":"BEAUTYPROF SPA","indirizzo":"STRADA STATALE 106 KM 84 - CENTRO COMMERCIALE LA GALLERIA CENTERGROSS","citta":"BOVALINO"}},
        {"title":"BEAUTYPROF SPA","acf":{"ragione_sociale":"BEAUTYPROF SPA","indirizzo":"LOCALITA' MALAVICINA - CENTRO COMMERCIALE CARREFOUR","citta":"ZUMPANO"}},
        {"title":"BEAUTYPROF SPA","acf":{"ragione_sociale":"BEAUTYPROF SPA","indirizzo":"STRADA STATALE 106 KM 106.400 - CENTRO COMMERCIALE LA GRU","citta":"SIDERNO"}},
        {"title":"BEAUTYPROF SPA","acf":{"ragione_sociale":"BEAUTYPROF SPA","indirizzo":"STRADA STATALE 106R KM. 18 - CENTRO COMMERCIALE I PORTALI","citta":"CORIGLIANO CALABRO"}},
        {"title":"BEAUTYPROF SPA","acf":{"ragione_sociale":"BEAUTYPROF SPA","indirizzo":"VIALE EMILIA - CENTRO COMMERCIALE LE FONTANE","citta":"CATANZARO"}},
        {"title":"BEAUTYPROF SPA","acf":{"ragione_sociale":"BEAUTYPROF SPA","indirizzo":"VIA ZANOTTI BIANCO 46/48 - CENTRO COMMERCIALE LA PERLA DELLO STRETTO","citta":"VILLA SAN GIOVANNI"}},
        {"title":"BEAUTYPROF SPA","acf":{"ragione_sociale":"BEAUTYPROF SPA","indirizzo":"CORSO UMBERTO 88/90","citta":"SOVERATO"}},
        {"title":"BEAUTYPROF SPA","acf":{"ragione_sociale":"BEAUTYPROF SPA","indirizzo":"VIA A. MORO - GALLERIA SANTA FILOMENA","citta":"ROSSANO"}},
        {"title":"BEAUTYPROF SPA","acf":{"ragione_sociale":"BEAUTYPROF SPA","indirizzo":"VIA ADRIANO OLIVETTI - CENTRO COMMERCIALE LA MONGOLFIERA","citta":"MOLFETTA"}},
        {"title":"BEAUTYPROF SPA","acf":{"ragione_sociale":"BEAUTYPROF SPA","indirizzo":"CENTRO COMMERCIALE LE SPIGHE","citta":"CROTONE"}},
        {"title":"BEAUTYPROF SPA","acf":{"ragione_sociale":"BEAUTYPROF SPA","indirizzo":"CORSO GARRONE - CENTRO COMMERCIALE AUCHAN BELVEDERE","citta":"MELILLI"}},
        {"title":"BEG SRL UNIPERSONALE","acf":{"ragione_sociale":"BEG SRL UNIPERSONALE","indirizzo":"VIA EMILIA LEVANTE 206","citta":"OZZANO DELL'EMILIA"}},
        {"title":"BEGHIN","acf":{"ragione_sociale":"BEGHIN","indirizzo":"VIA VANDELLI 1","citta":"PADOVA"}},
        {"title":"BEGHIN","acf":{"ragione_sociale":"BEGHIN","indirizzo":"VIA ZABARELLA 87","citta":"PADOVA"}},
        {"title":"BOB SRL","acf":{"ragione_sociale":"BOB SRL","indirizzo":"CORSO REPUBBLICA 161/163","citta":"CISTERNA DI LATINA"}},
        {"title":"BONTEMPO PROFUMI SRL","acf":{"ragione_sociale":"BONTEMPO PROFUMI SRL","indirizzo":"CORSO NAZIONALE 73","citta":"TERMOLI"}},
        {"title":"BONTEMPO PROFUMI SRL","acf":{"ragione_sociale":"BONTEMPO PROFUMI SRL","indirizzo":"PIAZZA PEPE 30/31","citta":"CAMPOBASSO"}},
        {"title":"BONTEMPO PROFUMI SRL","acf":{"ragione_sociale":"BONTEMPO PROFUMI SRL","indirizzo":"VIA ANGELO ZUCCARELLI 22","citta":"CAMPOBASSO"}},
        {"title":"BONTEMPO PROFUMI SRL","acf":{"ragione_sociale":"BONTEMPO PROFUMI SRL","indirizzo":"CORSO GARIBALDI 289/291","citta":"ISERNIA"}},
        {"title":"BRESSAN LORENZINA","acf":{"ragione_sociale":"BRESSAN LORENZINA","indirizzo":"VIA MASOTTO 5","citta":"NOVENTA VICENTINA"}},
        {"title":"BU.GIA. SNC DI LOREDANA GIANNESCHI & C.","acf":{"ragione_sociale":"BU.GIA. SNC DI LOREDANA GIANNESCHI & C.","indirizzo":"VIA IS MIRRIONIS 154","citta":"CAGLIARI"}},
        {"title":"BUSATO SRL","acf":{"ragione_sociale":"BUSATO SRL","indirizzo":"VIA MAZZINI 18","citta":"GRISIGNANO DI ZOCCO"}},
        {"title":"BUSATO SRL","acf":{"ragione_sociale":"BUSATO SRL","indirizzo":"VIA XX SETTEMBRE 14","citta":"CAMISANO VICENTINO"}},
        {"title":"CA.DE.PA DI CAMILLERI C.& C.SAS","acf":{"ragione_sociale":"CA.DE.PA DI CAMILLERI C.& C.SAS","indirizzo":"VIA GAETANO DONIZETTI 14/B","citta":"PALERMO"}},
        {"title":"CALCAGNI","acf":{"ragione_sociale":"CALCAGNI","indirizzo":"VIA INNOCENZO 19","citta":"COMO"}},
        {"title":"CALCAGNI","acf":{"ragione_sociale":"CALCAGNI","indirizzo":"VIA MAGENTA 29","citta":"GALLARATE"}},
        {"title":"CAMELI MARIA LUISA","acf":{"ragione_sociale":"CAMELI MARIA LUISA","indirizzo":"VIALE SECONDO MORETTI 39","citta":"SAN BENEDETTO DEL TRONTO"}},
        {"title":"CAMURATI","acf":{"ragione_sociale":"CAMURATI","indirizzo":"VIA ETTORE DE SONNAZ 13","citta":"TORINO"}},
        {"title":"CAMURATI","acf":{"ragione_sociale":"CAMURATI","indirizzo":"PIAZZA ADRIANO 1","citta":"TORINO"}},
        {"title":"CAMURATI","acf":{"ragione_sociale":"CAMURATI","indirizzo":"STRADA SETTIMO 338/340","citta":"SAN MAURO TORINESE"}},
        {"title":"CAPITOLINA PROFUMI S.R.L.","acf":{"ragione_sociale":"CAPITOLINA PROFUMI S.R.L.","indirizzo":"VIA CASILINA 253","citta":"ROMA"}},
        {"title":"CAPITOLINA PROFUMI S.R.L.","acf":{"ragione_sociale":"CAPITOLINA PROFUMI S.R.L.","indirizzo":"VIA ENEA 9/A","citta":"ROMA"}},
        {"title":"CAPONE PROFUMI SRL","acf":{"ragione_sociale":"CAPONE PROFUMI SRL","indirizzo":"VIA BOSCOFANGONE - CENTRO COMMERCIALE VULCANO BUONO","citta":"NOLA"}},
        {"title":"CAPONE PROFUMI SRL","acf":{"ragione_sociale":"CAPONE PROFUMI SRL","indirizzo":"CORSO VITTORIO EMANUELE 23/25","citta":"SALERNO"}},
        {"title":"CAPONE PROFUMI SRL","acf":{"ragione_sociale":"CAPONE PROFUMI SRL","indirizzo":"CORSO VITTORIO EMANUELE 94","citta":"AVELLINO"}},
        {"title":"CAPONE PROFUMI SRL","acf":{"ragione_sociale":"CAPONE PROFUMI SRL","indirizzo":"PIAZZA DELLE MEDAGLIE D'ORO 48","citta":"NAPOLI"}},
        {"title":"CAPONE PROFUMI SRL","acf":{"ragione_sociale":"CAPONE PROFUMI SRL","indirizzo":"VIA STAZIONE 1","citta":"FONDI"}},
        {"title":"CAPONE PROFUMI SRL","acf":{"ragione_sociale":"CAPONE PROFUMI SRL","indirizzo":"VIA DELLA BUFALOTTA 548 - CC TALENTI VILLAGE","citta":"ROMA"}},
        {"title":"CAPONE PROFUMI SRL","acf":{"ragione_sociale":"CAPONE PROFUMI SRL","indirizzo":"VIA COLLATINA INT 82 - CENTRO COMMERCIALE ROMA EST","citta":"ROMA"}},
        {"title":"CAPRICCIO PROFUMERIE SRL","acf":{"ragione_sociale":"CAPRICCIO PROFUMERIE SRL","indirizzo":"VIA VITTORIO VENETO SNC - CENTRO COMMERCIALE IL GRANAIO","citta":"GUALDO TADINO"}},
        {"title":"CAPRICCIO PROFUMERIE SRL","acf":{"ragione_sociale":"CAPRICCIO PROFUMERIE SRL","indirizzo":"VIA B. UBALDI - CENTRO COMMERCIALE LE MURA","citta":"GUBBIO"}},
        {"title":"CARELLA ALTOMARE ANNA","acf":{"ragione_sociale":"CARELLA ALTOMARE ANNA","indirizzo":"CORSO PIETRO GIANNONE 81","citta":"FOGGIA"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA MACELLO 22 - CENTRO COMMERCIALE LA CARTIERA","citta":"POMPEI"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIALE INDUSTRIA 225 - CENTRO COMMERCIALE IL DUCALE","citta":"VIGEVANO"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA ALDO CAPITINI 8 - CENTRO COMMERCIALE QUASAR","citta":"CORCIANO"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"PIAZZALE CARLO ROSSELLI 25 - CENTRO COMMERCIALE PORTA SIENA","citta":"SIENA"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"STRADA STATALE 33 DEL SEMPIONE 62 - CENTRO COMMERCIALE AUCHAN","citta":"NERVIANO"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA GIOVANNI GELATI 10 - CENTRO COMMERCIALE PARCO LEVANTE","citta":"LIVORNO"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA DEI GRECI 5 - CENTRO COMMERCIALE LE COTONIERE","citta":"SALERNO"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA MASULLO 76 - CC QUARTO NUOVO","citta":"QUARTO"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA FOSSE ARDEATINE 14 - CENTRO COMMERCIALE I TEMPLI","citta":"AGRIGENTO"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA DEI LAURI > VIA MARCONI","citta":"APRILIA"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA AURELIA ANTICA 46 - CENTRO COMMERCIALE AURELIA ANTICA","citta":"GROSSETO"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"CORSO VITTORIO EMANUELE II 20","citta":"PESCARA"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA LAURENTINA 865 - CENTRO COMMERCIALE MAXIMO","citta":"ROMA"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"PIAZZA GARIBALDI - STAZIONE CENTRALE","citta":"NAPOLI"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"STRADA STATALE 9 - CENTRO COMMERCIALE BELPO'","citta":"SAN ROCCO AL PORTO"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA PONTE DI PISCINA CUPA SNC - CASTEL ROMANO SHOPPING VILLAGE","citta":"ROMA"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIALE DELLA PRIMAVERA 194 - CENTRO COMMERCIALE PRIMAVERA","citta":"ROMA"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA VITRUVIO 230","citta":"FORMIA"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIAlE LEONE TOLSTOJ 86 - CENTRO COMMERCIALE SAN GIULIANO","citta":"SAN GIULIANO MILANESE"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"CORSO FERRARI 107","citta":"ALBISOLA"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIALE DELLE AMERICHE - CENTRO COMMERCIALE IBLEO","citta":"RAGUSA"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA F. MASCI - CENTRO COMMERCIALE IL CENTAURO","citta":"CHIETI"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"PIAZZA CARITA' 36","citta":"NAPOLI"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"STRADA STATALE 84 CONTRADA GAETA - CENTRO COMMERCIALE LANCIANO","citta":"LANCIANO"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA ARMANDO DIAZ 5","citta":"LATINA"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA FONTEVIVO 17 - CENTRO COMMERCIALE LE TERRAZZE","citta":"LA SPEZIA"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA BERGAMO 19 - CENTRO COMMERCIALE ADDA CENTER","citta":"MERATE"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"CONTRADA COLLE DELLE API - CENTRO COMMERCIALE MONFORTE","citta":"CAMPOBASSO"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA PASQUALE TURBACCI 1 - CENTRO COMMERCIALE I MANDORLI","citta":"SAN GIOVANNI ROTONDO"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"LOCALITA' VALCORRENTE 23 - CENTRO COMMERCIALE ETNAPOLIS","citta":"BELPASSO"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA GIUSEPPE LANZA DI SCALEA 1963 - CENTRO COMMERCIALE CONCA D'ORO","citta":"PALERMO"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"CORSO MANFREDI 89","citta":"MANFREDONIA"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA MICHELE ZANNOTTI 200","citta":"SAN SEVERO"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"CORSO SARDEGNA 165","citta":"GENOVA"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA FEDERICO FELLINI 2","citta":"SPOLTORE"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA LARIO SS 36 - CC ROND0' DEI PINI","citta":"MONZA"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA PRETORIA 171/173","citta":"POTENZA"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIALE VALENTE, 190","citta":"ROMA"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA ANTONIO BARDELLI 4 - CENTRO COMMERCIALE CITTA' FIERA","citta":"UDINE"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"SP 126 KM 1,6 - PALMANOVA VILLAGE","citta":"PALMANOVA"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA FELICE CASATI 1/A > BUENOS AIRES","citta":"MILANO"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"PIAZZA G. JEMINA - MONDOVICINO OUTLET VILLAGE","citta":"MONDOVI'"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA E. FENOGLIETTI 230 - CC LINGOTTO","citta":"TORINO"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA CARLO AMORETTI 1 - CC IL METROPOLI","citta":"NOVATE MILANESE"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA ADIGE 250/H - CC IL NAVIGLIO","citta":"BOMPORTO"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA ANTICA ROMANA - BRUGNATO 5 TERRE OUTLET VILLAGE","citta":"BRUGNATO"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA SAN VALENTINO 9/A","citta":"BRENNERO"}},
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"LOCALITA' FERLINA 11","citta":"BUSSOLENGO"}}
        ,
        {"title":"CARLOTTA SRL","acf":{"ragione_sociale":"CARLOTTA SRL","indirizzo":"VIA GELSO BIANCO KM 84 - CENTRO COMMERCIALE PORTE DI CATANIA","citta":"CATANIA"}},
        {"title":"CAROLEI DOMENICO & FIGLI SAS","acf":{"ragione_sociale":"CAROLEI DOMENICO & FIGLI SAS","indirizzo":"PIAZZA VITTORIA 10/4","citta":"CROTONE"}},
        {"title":"CASTELLI","acf":{"ragione_sociale":"CASTELLI","indirizzo":"VIA FRATTINA 54","citta":"ROMA"}},
        {"title":"CERCHIER SNC","acf":{"ragione_sociale":"CERCHIER SNC","indirizzo":"RIO TERRA DELLE BOTTEGHE 53","citta":"CAORLE"}},
        {"title":"CHIARELLI PROFUMERIE S.R.L.","acf":{"ragione_sociale":"CHIARELLI PROFUMERIE S.R.L.","indirizzo":"VIA FARDELLA 284","citta":"TRAPANI"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"VIA NAZARIO SAURO 29","citta":"LECCE"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"VIA NOICATTARO 2 - CENTRO COMMERCIALE AUCHAN","citta":"CASAMASSIMA"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"VIA ZAMBONATE 11","citta":"BERGAMO"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"VIA CAPRARIE 2 > VIA RIZZOLI","citta":"BOLOGNA"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"CORSO MAGENTA 2","citta":"BRESCIA"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"VIA DANTE 132","citta":"CAGLIARI"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"VIA SAN QUIRICO 165 - CENTRO COMMERCIALE I GIGLI","citta":"CAMPI BISENZIO"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"VIA ETNEA 112","citta":"CATANIA"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"VIA BOLDONI 3","citta":"COMO"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"PIAZZA DEL GRANO 5/6","citta":"FIRENZE"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"VIA XX SETTEMBRE 16/A","citta":"GENOVA"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"PIAZZA ATTIAS 17","citta":"LIVORNO"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"PIAZZA XXVII OTTOBRE 81","citta":"MESTRE"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"PIAZZA CANTORE 12","citta":"MILANO"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"CORSO VERCELLI 30/32","citta":"MILANO"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"PIAZZA DELLE CINQUE GIORNATE 1","citta":"MILANO"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"VIA SCARLATTI 90/98","citta":"NAPOLI"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"VIA ALTINATE 16/8","citta":"PADOVA"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"CORSO VITTORIO EMANUELE II 148","citta":"PIACENZA"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"CORSO D'AUGUSTO 59","citta":"RIMINI"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"VIA COLA DI RIENZO 173","citta":"ROMA"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"VIA ALBERTO LIONELLO - CENTRO COMMERCIALE PORTA DI ROMA","citta":"ROMA"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"VIALE PALMIRO TOGLIATTI 2 - CENTRO COMMERCIALE CINECITTA' 2","citta":"ROMA"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"PIAZZALE APPIO 10 > PIAZZA DI PORTA SAN GIOVANNI","citta":"ROMA"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"VIA MARCONI 2","citta":"SASSARI"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"CORSO DEL POPOLO 42","citta":"TREVISO"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"CORSO ITALIA 16","citta":"TRIESTE"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"VIA VITTORIO VENETO 14","citta":"VARESE"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"VIA CAPPELLO 30","citta":"VERONA"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"PIAZZA CASTELLO 19","citta":"VICENZA"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"VIALE SAN MARTINO 101","citta":"MESSINA"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"VIA CREA 10 - CENTRO COMMERCIALE LE GRU","citta":"GRUGLIASCO"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"PIAZZA TRE TORRI - CITY LIFE","citta":"MILANO"}},
        {"title":"COIN","acf":{"ragione_sociale":"COIN","indirizzo":"VIA COLLATINA 858 - CENTRO COMMERCIALE ROMA EST","citta":"ROMA"}},
        {"title":"COMAR PROFUMERIE SRL","acf":{"ragione_sociale":"COMAR PROFUMERIE SRL","indirizzo":"VIA MONTANARA 15","citta":"PARMA"}},
        {"title":"COMAR PROFUMERIE SRL","acf":{"ragione_sociale":"COMAR PROFUMERIE SRL","indirizzo":"VIALE PERTINI 43","citta":"COLLECCHIO"}},
        {"title":"COMAR PROFUMERIE SRL","acf":{"ragione_sociale":"COMAR PROFUMERIE SRL","indirizzo":"VIA GONZAGA 10/B","citta":"GUASTALLA"}},
        {"title":"COMAR PROFUMERIE SRL","acf":{"ragione_sociale":"COMAR PROFUMERIE SRL","indirizzo":"VIA MAZZINI 1/C","citta":"PARMA"}},
        {"title":"COMAR PROFUMERIE SRL","acf":{"ragione_sociale":"COMAR PROFUMERIE SRL","indirizzo":"VIA MAIELLA 28","citta":"REGGIO EMILIA"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"CORSO VITTORIO EMANUELE 30","citta":"AVELLINO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"LARGO FERROVIA 1  - CENTRO COMMERCIALE PLAZA","citta":"MERCATO SAN SEVERINO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA DE GASPERI 340","citta":"PAGANI"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"STRADA STATALE 16 ADRIATICA - CENTRO COMMERCIALE COSTAVERDE","citta":"MONTENERO DI BISACCIA"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA SERRACAPILLI - CENTRO COMMERCIALE LE BOLLE","citta":"EBOLI"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIALE DANTE 21","citta":"CASSINO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA CITTADELLA 14","citta":"SORA"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA VITRUVIO 204","citta":"FORMIA"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA CASILINA SUD - CENTRO COMMERCIALE GLI ARCHI","citta":"CASSINO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"STRADA STATALE ADRIATICA 16 - CENTRO COMMERCIALE CONFORAMA","citta":"FASANO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA SAN LOJA - CENTRO COMMERCIALE LUCANIA","citta":"TITO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA IGINO GARBINO - CENTRO COMMERCIALE E. LECLERC","citta":"VITERBO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA CHIESA NUOVA 1 - CENTRO COMMERCIALE LA SELVA","citta":"SORA"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA FRATELLI BRIZIARELLI - CENTRO COMMERCIALE LA FORNACE","citta":"MARSCIANO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA FLAMINIA VECCHIA 3 - CENTRO COMMERCIALE CORTACCI","citta":"SPOLETO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIALE B. BUOZZI  35 - CENTRO COMMERCIALE MONTEROTONDO","citta":"MONTEROTONDO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA PETROCELLI 240 - CENTRO COMMERCIALE PETROCELLI","citta":"ROMA"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA NAPOLI - CENTRO COMMERCIALE ELISEO","citta":"ISOLA DEL LIRI"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA BARBATO - CENTRO COMMERCIALE TORRESINA","citta":"ROMA"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA VITTORIO EMANUELE III 13","citta":"FONDI"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA SARAGAT 56 - CENTRO COMMERCIALE GLOBO","citta":"L'AQUILA"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA ALDO MORO 511","citta":"FROSINONE"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA MAREMMANA INFERIORE - CENTRO COMMERCIALE LA NOCE","citta":"SAN CESAREO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA CASILINA SUD KM 141.500 - CENTRO COMMERCIALE PANORAMA","citta":"CASSINO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA LUIGI CARDONE - CENTRO COMMERCIALE CENTRO DEL VASTO","citta":"VASTO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"CONTRADA PACE LOCALITA' MACCHIA - CENTRO COMMERCIALE GARGANO","citta":"MONTE SANT'ANGELO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIALE DELLA REPUBBLICA 8","citta":"SULMONA"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"STRADA STATALE 85 VENAFRANA KM 28.100 - CENTRO COMMERCIALE I MELOGRANI","citta":"MONTAQUILA"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA DEI GRECI 5 - CENTRO COMMERCIALE LE COTONIERE","citta":"SALERNO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"CONTRADA PANTONI - CENTRO COMMERCIALE DIANO","citta":"ATENA LUCANA"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"STRADA STATALE 16 KM 432 - CENTRO COMMERCIALE UNIVERSO","citta":"SILVI MARINA"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"STRADA STATALE 18 KM 79,50 - CILENTO OUTLET VILLAGE","citta":"EBOLI"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA NAZIONALE 74 - CENTRO COMMERCIALE BORGO D'ABRUZZO","citta":"CEPAGATTI"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA RITA ATRIA 2  - CENTRO COMMERCIALE FERRATELLE","citta":"GUBBIO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"CORSO VITTORIO EMANUELE II, 53","citta":"CAMPOBASSO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA PER SAN GIORGIO IONICO KM 10 - CENTRO COMMERCIALE AUCHAN","citta":"TARANTO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA DEI PRINCIPATI 37","citta":"SALERNO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA MONTEFIORINI 48 - CENTRO COMMERCIALE COSPEA","citta":"TERNI"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA ARISTIDE MERLONI 145","citta":"ROMA"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"STRADA STATALE GELSO BIANCO KM 84 - CENTRO COMMERCIALE PORTE DI CATANIA","citta":"CATANIA"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA TIBURTINA VALERIA 386 - CENTRO COMMERCIALE PORTE DI PESCARA","citta":"PESCARA"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA NETTUNENSE - CENTRO COMMERCIALE ZODIACO","citta":"ANZIO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA ALDO MORO 237","citta":"FROSINONE"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA G. NATTA 9 - CENTRO COMMERCIALE DA VINCI","citta":"FIUMICINO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA NOICATTARO 2 - CENTRO COMMERCIALE AUCHAN","citta":"CASAMASSIMA"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA PAPA GIOVANNI II - CENTRO COMMERCIALE AUCHAN","citta":"MODUGNO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA NAPOLI 20","citta":"VAIRANO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA CESARE BECCARIA 24 - CENTRO COMMERCIALE IL NOCE","citta":"BENEVENTO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA COLLATINA KM 12.800 - CENTRO COMMERCIALE ROMA EST","citta":"ROMA"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"VIA EINAUDI 30 - CENTRO COMMERCIALE FANO CENTER","citta":"FANO"}},
        {"title":"CONSORZIO PROFUMERIE ESTASI","acf":{"ragione_sociale":"CONSORZIO PROFUMERIE ESTASI","indirizzo":"CORSO MESSAPIA 44","citta":"MARTINA FRANCA"}},
        {"title":"COSE BELLE DI SGOBBA FRANCESCO","acf":{"ragione_sociale":"COSE BELLE DI SGOBBA FRANCESCO","indirizzo":"CORSO VITTORIO EMANUELE 32","citta":"ALBEROBELLO"}},
        {"title":"D.M. SERVICE DI E. OLIVIERO & C. SAS","acf":{"ragione_sociale":"D.M. SERVICE DI E. OLIVIERO & C. SAS","indirizzo":"CONTRADA BOSCHETTO DI BOREA - CENTRO COMMERCIALE ARCOBALENO","citta":"MELFI"}},
        {"title":"D.M. SERVICE DI E. OLIVIERO & C. SAS","acf":{"ragione_sociale":"D.M. SERVICE DI E. OLIVIERO & C. SAS","indirizzo":"VIA ARGINE 380 - CENTRO COMMERCIALE AUCHAN","citta":"NAPOLI"}},
        {"title":"D.M. SERVICE DI E. OLIVIERO & C. SAS","acf":{"ragione_sociale":"D.M. SERVICE DI E. OLIVIERO & C. SAS","indirizzo":"VIA ALOIS 8/10","citta":"CASERTA"}},
        {"title":"D.M. SERVICE DI E. OLIVIERO & C. SAS","acf":{"ragione_sociale":"D.M. SERVICE DI E. OLIVIERO & C. SAS","indirizzo":"VIA C. BETTELONI 44","citta":"VERONA"}},
        {"title":"D.M. SERVICE DI E. OLIVIERO & C. SAS","acf":{"ragione_sociale":"D.M. SERVICE DI E. OLIVIERO & C. SAS","indirizzo":"VIALE DEGLI AVIATORI 126 - CENTRO COMMERCIALE LA MONGOLFIERA","citta":"FOGGIA"}},
        {"title":"D.M. SERVICE DI E. OLIVIERO & C. SAS","acf":{"ragione_sociale":"D.M. SERVICE DI E. OLIVIERO & C. SAS","indirizzo":"PIAZZA MAZZINI 52","citta":"SANTA MARIA CAPUA VETERE"}},
        {"title":"D.M. SERVICE DI E. OLIVIERO & C. SAS","acf":{"ragione_sociale":"D.M. SERVICE DI E. OLIVIERO & C. SAS","indirizzo":"CENTRO DIREZIONALE ISOLA A/3 - GALLERIA LE GEMME","citta":"NAPOLI"}},
        {"title":"DABBENE CLUB SRL","acf":{"ragione_sociale":"DABBENE CLUB SRL","indirizzo":"PIAZZA G. AMENDOLA, 44","citta":"PALERMO"}},
        {"title":"DAFNE TRADING SRL","acf":{"ragione_sociale":"DAFNE TRADING SRL","indirizzo":"VIA NICOLA RICCIOTTI 14","citta":"MANDURIA"}},
        {"title":"DELLEPIANE MARTELLA SNC DI DELLEPIANE S. E MARTELLA A.","acf":{"ragione_sociale":"DELLEPIANE MARTELLA SNC DI DELLEPIANE S. E MARTELLA A.","indirizzo":"CORSO ITALIA 172/R","citta":"SAVONA"}},
        {"title":"DELLEPIANE MARTELLA SNC DI DELLEPIANE S. E MARTELLA A.","acf":{"ragione_sociale":"DELLEPIANE MARTELLA SNC DI DELLEPIANE S. E MARTELLA A.","indirizzo":"PIAZZA STALLANI 2","citta":"CAIRO MONTENOTTE"}},
        {"title":"DELLEPIANE MARTELLA SNC DI DELLEPIANE S. E MARTELLA A.","acf":{"ragione_sociale":"DELLEPIANE MARTELLA SNC DI DELLEPIANE S. E MARTELLA A.","indirizzo":"VIA BARTOLOMEO ASQUASCIATI 10","citta":"SANREMO"}},
        {"title":"DELLEPIANE MARTELLA SNC DI DELLEPIANE S. E MARTELLA A.","acf":{"ragione_sociale":"DELLEPIANE MARTELLA SNC DI DELLEPIANE S. E MARTELLA A.","indirizzo":"VIA ROMA 18/R","citta":"GENOVA"}},
        {"title":"DELLEPIANE MARTELLA SNC DI DELLEPIANE S. E MARTELLA A.","acf":{"ragione_sociale":"DELLEPIANE MARTELLA SNC DI DELLEPIANE S. E MARTELLA A.","indirizzo":"VIA MALOCELLO 41","citta":"VARAZZE"}},
        {"title":"DELLEPIANE MARTELLA SNC DI DELLEPIANE S. E MARTELLA A.","acf":{"ragione_sociale":"DELLEPIANE MARTELLA SNC DI DELLEPIANE S. E MARTELLA A.","indirizzo":"VIA GIUSEPPE GARIBALDI 27","citta":"LOANO"}},
        {"title":"DELLEPIANE MARTELLA SNC DI DELLEPIANE S. E MARTELLA A.","acf":{"ragione_sociale":"DELLEPIANE MARTELLA SNC DI DELLEPIANE S. E MARTELLA A.","indirizzo":"CORSO MATTEOTTI 108","citta":"ARENZANO"}},
        {"title":"DELLEPIANE MARTELLA SNC DI DELLEPIANE S. E MARTELLA A.","acf":{"ragione_sociale":"DELLEPIANE MARTELLA SNC DI DELLEPIANE S. E MARTELLA A.","indirizzo":"PIAZZA MARCONI 16/R","citta":"SAVONA"}},
        {"title":"DEPRETIS GROUP  SRL","acf":{"ragione_sociale":"DEPRETIS GROUP  SRL","indirizzo":"VIALE GIUSEPPE MAZZINI 31","citta":"TERNI"}},
        {"title":"DI BENEDETTO PROFUMI SRL","acf":{"ragione_sociale":"DI BENEDETTO PROFUMI SRL","indirizzo":"VIA ROMA 318/320","citta":"ENNA"}},
        {"title":"DI BENEDETTO PROFUMI SRL","acf":{"ragione_sociale":"DI BENEDETTO PROFUMI SRL","indirizzo":"VIA PERGUSINA 20","citta":"ENNA"}},
        {"title":"DI BENEDETTO PROFUMI SRL","acf":{"ragione_sociale":"DI BENEDETTO PROFUMI SRL","indirizzo":"VIA LEONARDO SCIASCIA 4/A","citta":"REGALBUTO"}},
        {"title":"DITANO SRL","acf":{"ragione_sociale":"DITANO SRL","indirizzo":"PIAZZA CIAIA 18","citta":"FASANO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"PIAZZA CAMPO MARZIO 41/42","citta":"ARZIGNANO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA SAFFI 7","citta":"CASALE MONFERRATO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA FUCECCHIELLO 7 - CC FUCECCHIO","citta":"FUCECCHIO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA MARE MEDITERRANEO - CC CONAD","citta":"MIRA"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA GIOVANNI TABACCHI 29","citta":"MIRANDOLA"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA CAVIN DI SALA 167 - CC MIRANO DUE","citta":"MIRANO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA CALTANA 169 - CC CENTRO NICO","citta":"SANTA MARIA DI SALA"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA FOGAZZARO 2","citta":"THIENE"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA IV NOVEMBRE 21","citta":"VALDAGNO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"CORSO VITTORIO EMANUELE 97","citta":"ADRIA"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"CORSO GARIBALDI 21","citta":"BORGOMANERO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"CORSO VICENZA 47 - CENTRO COMMERCIALE LE VALLI","citta":"BORGO VALSUGANA"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA STRAELLE 15 - CENTRO COMMERCIALE IL PARCO","citta":"CAMPOSAMPIERO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA COMMERCIO 3 - CC 102","citta":"CENTO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA MATTEOTTI 22","citta":"ESTE"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"PIAZZA VITTORINO DA FELTRE 4","citta":"FELTRE"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA EMILIA 117","citta":"IMOLA"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA IV NOVEMBRE 24","citta":"VERONA"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA CAVALLOTTI 25","citta":"MONTICHIARI"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"PIAZZA GRANDE 17","citta":"ODERZO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA TAMARISI 2 - CC SHOP CENTER","citta":"PERGINE VALSUGANA"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA FRATELLI SANGUINAZZI 1 - CC PIAZZAGRANDE","citta":"PIOVE DI SACCO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA ANTONIO GAZZOLETTI 2","citta":"RIVA DEL GARDA"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA DANTE 4","citta":"ROVERETO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA ROMA 9","citta":"TOLMEZZO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA MALCANTON 40 - CENTRO COMMERCIALE EMISFERO","citta":"TREBASELEGHE"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA TRENER 16 - CENTRO COMMERCIALE BREN CENTER","citta":"TRENTO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA BRENNERO 366 - CENTRO COMMERCIALE TOP CENTER","citta":"TRENTO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"LARGO CARDUCCI > VIA ORIOLA 37","citta":"TRENTO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA ORIANI 4","citta":"TRIESTE"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"CORSO VITTORIO EMANUELE II 1","citta":"VIGEVANO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA DANIELE MANIN 92","citta":"VITTORIO VENETO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA ROMA 42","citta":"MONSELICE"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA I MAGGIO 189 - CC I PIOPPI","citta":"COPPARO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA CAMPI 1","citta":"MARENO DI PIAVE"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA MERCATO VECCHIO 28","citta":"UDINE"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"PIAZZA MARCONI 4","citta":"JESOLO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA FRATELLI PASINI 10","citta":"SCHIO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"CORSO PORTA BORSARI 55","citta":"VERONA"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA CAVOUR 31","citta":"LECCO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA CASELLE 1 - CENTRO COMMERCIALE LE CENTURIE","citta":"SAN GIORGIO DELLE PERTICHE"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA MAZZINI 9","citta":"CIVIDALE DEL FRIULI"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"PIAZZA DEL POPOLO 59","citta":"SACILE"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA EROI DI PODRUTE 3-5-7","citta":"SILEA"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"PIAZZA ITALIA 3","citta":"SEREGNO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA CAVOUR 1","citta":"GALLARATE"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"CORSO ITALIA 66","citta":"SARONNO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA VITANI 2","citta":"COMO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA CARDINAL TOSI 6","citta":"BUSTO ARSIZIO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA CREMONA 36","citta":"MANTOVA"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"CORSO DELLA LIBERTA' 184","citta":"MERANO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"CORSO SUSA 301/307","citta":"RIVOLI"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"PIAZZA ITALIA 12","citta":"ACQUI TERME"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA GALVANI 3 - CC CENTRUM GALVANI","citta":"BOLZANO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"PIAZZA KENNEDY 11","citta":"JESOLO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA DI CAVANA 14","citta":"TRIESTE"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA MIGLIARA 17","citta":"ALESSANDRIA"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA DEI MILLE 8D/E","citta":"BOLOGNA"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"CORSO PALESTRO 31","citta":"BRESCIA"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA DON TOSATTO 22 - CENTRO COMMERCIALE PORTE DI MESTRE","citta":"MESTRE"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA SAN LAZZARO 8/D","citta":"TRIESTE"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"STRADA TORINO 30-32  - CC LE FORNACI","citta":"BEINASCO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA PIOL 41/B","citta":"RIVOLI"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA G. CARDUCCI, 55","citta":"BERGAMO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"CORSO CANALE 99 LOCALITA' MUSSOTTO - CENTRO COMMERCIALE BIG STORE","citta":"ALBA"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA MADAMA CRISTINA 16","citta":"TORINO"}},
        {"title":"DMO","acf":{"ragione_sociale":"DMO","indirizzo":"VIA GUIDO MARTINO 8 - CENTRO COMMERCIALE LA GRAND'A","citta":"CUNEO"}},
        {"title":"DOCTOR LOOK & MRS STYLE DI VITALI FAUSTO & C. SAS","acf":{"ragione_sociale":"DOCTOR LOOK & MRS STYLE DI VITALI FAUSTO & C. SAS","indirizzo":"VIA D.CHIESA 20","citta":"CHIAVARI"}},
        {"title":"DOCTOR LOOK & MRS STYLE DI VITALI FAUSTO & C. SAS","acf":{"ragione_sociale":"DOCTOR LOOK & MRS STYLE DI VITALI FAUSTO & C. SAS","indirizzo":"CORSO GARIBALDI 20","citta":"CHIAVARI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO MATTEOTTI 85","citta":"PONTEDERA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DELLE TERME - CENTRO COMMERCIALE IL CICLOPE","citta":"ACIREALE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA VITTORIO VENETO 51","citta":"ALASSIO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA ROGGERI 50 - CENTRO COMMERCIALE LA RIVIERA","citta":"ARMA DI TAGGIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO ALFIERI 234","citta":"ASTI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO VITTORIO EMANUELE 109","citta":"AVELLINO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MARCO BIAGI 25 - CENTRO COMMERCIALE FASHION DISTRICT","citta":"BAGNOLO SAN VITO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA E. DE NICOLA 176/178","citta":"CASSINO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO DELLA LIBERTA' 12","citta":"CASTIGLIONE DELLA PESCAIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO ITALIA 131","citta":"CATANIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA D'ANNUNZIO 101","citta":"CATANIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA CARDUCCI 17","citta":"CECINA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO CENTOCELLE 19/21","citta":"CIVITAVECCHIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA SPAGNA 10/12 - CENTRO COMMERCIALE LA CERTOSA","citta":"COLLEGNO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA CADUTI DEL LAGER - CENTRO COMMERCIALE LE VELE","citta":"DESENZANO DEL GARDA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DEL GIGLIO 12","citta":"EMPOLI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MAESTRI DEL LAVORO 42","citta":"FIUME VENETO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO CAVOUR 12/18","citta":"FOLIGNO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA ROMA 40","citta":"FOLLONICA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MAZZINI 13/C","citta":"FORTE DEI MARMI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO CARDUCCI 43","citta":"GROSSETO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA TIBURTINA VALERIA - CENTRO COMMERCIALE TIBURTINO","citta":"GUIDONIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MONTANARI - CC PARCO COMMERCIALE DA VINCI","citta":"FIUMICINO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MANTOVA - CENTRO COMMERCIALE GALASSIA","citta":"LEGNAGO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA GRANDE 35","citta":"LIVORNO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA GRAZIANI 6 - CENTRO COMMERCIALE LE FONTI DEL CORALLO","citta":"LIVORNO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA RICASOLI 34","citta":"LIVORNO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA FILLUNGO 75","citta":"LUCCA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"STRADA STATALE SANNITICA 87 LOCALITA' AURNO - CENTRO COMMERCIALE CAMPANIA","citta":"MARCIANISE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIALE GIUSEPPE VERDI 15B","citta":"MONTECATINI TERME"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA DEL MERCATO 31","citta":"NETTUNO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO ITALIA 29","citta":"ORBETELLO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA EINAUDI 3 - CENTRO COMMERCIALE LE PIAZZE","citta":"ORZINUOVI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MARCHESE DI ROCCAFORTE 37","citta":"PALERMO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA SAN LEONARDO - CENTRO COMMERCIALE LE TORRI","citta":"PARMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"STRADA PROVINCIALE VIGENTINA - CENTRO COMMERCIALE CARREFOUR","citta":"PAVIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA FIESOLE 1","citta":"PERUGIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA POIRINO - CENTRO COMMERCIALE LE DUE VALLI","citta":"PINEROLO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA FERRER 6","citta":"PIOMBINO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO ITALIA 16","citta":"PISA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA GAVINANA 2/3","citta":"PISTOIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA ROMA > VIA MARCONI","citta":"PORTO SANTO STEFANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CALATA ITALIA 18/19","citta":"PORTOFERRAIO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA NAZIONALE 108 - CENTRO COMMERCIALE BENNET","citta":"PRADAMANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA RICASOLI 30","citta":"PRATO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DELLE SERRE - LOCALITA' QUARTOCCIU - CENTRO COMMERCIALE MILLENIUM","citta":"CAGLIARI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA NAZIONALE - CENTRO COMMERCIALE PORTO BOLARO","citta":"REGGIO CALABRIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZALE ATLETI AZZURRI D'ITALIA 1 - CENTRO COMMERCIALE I PETALI","citta":"REGGIO EMILIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO EUROPA 126 - CENTRO COMMERCIALE RHO CENTER","citta":"RHO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA CADUTI DI NASSIRIYA 20 - CENTRO COMMERCIALE LE BEFANE","citta":"RIMINI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA CASCINA MOIE 1/2 - FRANCIACORTA OUTLET VILLAGE","citta":"RODENGO SAIANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DI TORRE MEZZAVIA - CENTRO COMMERCIALE ANAGNINA","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA ALBERTO LIONELLO - CENTRO COMMERCIALE PORTA DI ROMA (STORE GARDENIA)","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA APPIA NUOVA 501/505","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA M. RIGAMONTI 100 - CENTRO COMMERCIALE I GRANAI","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA COLOMBO 714 - CENTRO COMMERCIALE EUROMA 2 (STORE GARDENIA)","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DEL CORSO 260","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIALE BRAMANTE - CC PARCO LEONARDO","citta":"FIUMICINO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"STRADA STATALE SONCINESE 498 - CENTRO COMMERCIALE IL BORGO","citta":"ROMANO DI LOMBARDIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"STRADA STATALE 16 - CENTRO COMMERCIALE LA FATTORIA","citta":"ROVIGO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA GIOVANNI DA PESCINO 4","citta":"SANTA MARGHERITA LIGURE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO VITTORIO EMANUELE 107","citta":"SALERNO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MONTELLI CONTRADA BOTTAZZI - CENTRO COMMERCIALE LE ZAGARE","citta":"SAN GIOVANNI LA PUNTA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DELLA PACE 26 - CENTRO COMMERCIALE LE CUPOLE","citta":"SAN GIULIANO MILANESE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO MATTEOTTI 160","citta":"SANREMO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"STRADA MASSETANA ROMANA 58/B","citta":"SIENA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA MATTEOTTI 21","citta":"SIENA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA BANCHI DI SOTTO 6","citta":"SIENA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA SAN GIUSEPPE - CENTRO COMMERCIALE SAN NICOLO'","citta":"SPOLETO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DELLA PACE LOCALITA' PASCOLARO - VALMONTONE OUTLET","citta":"VALMONTONE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA CAIROLI 50","citta":"VELLETRI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA CAVOUR","citta":"VERCELLI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA ANTONIO FRATTI 168","citta":"VIAREGGIO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"QUARTIERE TORRI BIANCHE - CENTRO COMMERCIALE TORRI BIANCHE","citta":"VIMERCATE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA TANGENZIALE OVEST - CENTRO COMMERCIALE TUSCIA","citta":"VITERBO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA VENEZIA 61 - CENTRO COMMERCIALE GIOTTO","citta":"PADOVA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA SAN SIMONE 60 - CENTRO COMMERCIALE SANTA GILLA","citta":"CAGLIARI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIALE PALMIRO TOGLIATTI 2 - CENTRO COMMERCIALE CINECITTA' 2","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA TOMMASO LANDOLFI 8","citta":"FROSINONE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO ITALIA 51","citta":"FIUGGI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA A. BARDELLI 4 - CENTRO COMMERCIALE FIERA (PIANO TERRA)","citta":"TORREANO DI MARTIGNACCO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA CASILINA 1011 - CENTRO COMMERCIALE CASILINO","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA POCAR - CENTRO COMMERCIALE EMISFERO","citta":"MONFALCONE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA BIANDRATE  - CENTRO COMMERCIALE SAN MARTINO","citta":"NOVARA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA TRENTINO 1 - CENTRO COMMERCIALE LA GRANDE MELA","citta":"LUGAGNANO DI SONA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA ORLANDO SCAVAZZA 1769 - CENTRO COMMERCIALE IL FARO","citta":"GIACCIANO CON BARUCHELLA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA TREVISO 24 - CENTRO COMMERCIALE PARCO DORA","citta":"TORINO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CONTRADA GALDO ZONA INDUSTRIALE - CENTRO COMMERCIALE CROSSCONAD","citta":"LAURIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA VALLE ISOLA 9 > STRADA STATALE ROMEA 309  - CENTRO COMMERCIALE LE VALLI","citta":"COMACCHIO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"STRADA STATALE APPIA KM 144 - CENTRO COMMERCIALE APPIA ANTICA","citta":"MESAGNE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA SESTRI 88","citta":"GENOVA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"STRADA STATALE PADANA SUPERIORE KM. 292 - CENTRO COMMERCIALE AUCHAN","citta":"VIMODRONE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"STRADA STATALE 255 - CENTRO COMMERCIALE GUERCINO","citta":"CENTO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA FERDINANDO TARGETTI 12","citta":"PRATO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PETROSA 19 - CENTRO COMMERCIALE SESTO","citta":"SESTO FIORENTINO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DI TIGLIO 1075","citta":"LUCCA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DEL MARE KM 16.8 - CENTRO COMMERCIALE SEDICI PINI","citta":"POMEZIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIALE ITALIA 555 - CENTRO COMMERCIALE VULCANO","citta":"SESTO SAN GIOVANNI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA E. RATTI 2 - CENTRO COMMERCIALE LA PORTA DELL'EUROPA","citta":"MONTANO LUCINO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO ASTI 24 - CENTRO COMMERCIALE ALBA CENTER","citta":"ALBA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO MARCONI 42/44 - CENTRO COMMERCIALE LE ISOLE","citta":"GRAVELLONA TOCE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA BAGNOLI 35 - CENTRO COMMERCIALE LE SERRE","citta":"ALBENGA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA DEI MARTIRI 37","citta":"BELLUNO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA COLLATINA 858 - CENTRO COMMERCIALE ROMA EST","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA CAMPO DI PILE - CENTRO COMMERCIALE L'AQUILONE","citta":"L'AQUILA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA D'ITALIA 24","citta":"SASSARI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIALE PORTO TORRES - LOCALITA' PRIEDDA NIEDDA - GALLERIA AUCHAN","citta":"SASSARI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA GRAMSCI 5","citta":"MACOMER"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA DEI MARTIRI 39/40","citta":"MIRANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA FORTUNATO POSTIGLIONE 1 - CENTRO COMMERCIALE 45 NORD","citta":"MONCALIERI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA GIOVANNI VERGA 1 - CENTRO COMMERCIALE IPERCITY","citta":"ALBIGNASEGO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO CASALE 319 - CENTRO COMMERCIALE IL BORGO","citta":"ASTI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA FERRANTE APORTI 18","citta":"SAN MINIATO BASSO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PORTUENSE 716 E/F","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA FOSSO VECCHIO - CENTRO COMMERCIALE IPERCOOP CENTRO I BORGHI","citta":"NAVACCHIO DI CASCINA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA LIBERO ANDREOTTI 19","citta":"PESCIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"LARGO CAMUSSI 1","citta":"GALLARATE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"STRADA PROVINCIALE 60 LOCALITA' CUTIZZA - CENTRO COMMERCIALE BARI BLU","citta":"TRIGGIANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DON ORIONE 45 - CENTRO COMMERCIALE BIG STORE","citta":"BRA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"STRADA PROVINCIALE 1 LOCALITA' SANDALLI - CENTRO COMMERCIALE PORTO DEGLI ULIVI","citta":"RIZZICONI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZALE CLODIO 35","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"STRADA STATALE 18 LOCALITA' AEROPORTO - CENTRO COMMERCIALE VIBO CENTER","citta":"VIBO VALENTIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIALE DEI MILLE 140 - CENTRO COMMERCIALE OVS","citta":"FIRENZE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO GELONE 29","citta":"SIRACUSA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIALE DI TRASTEVERE 60 - CENTRO COMMERCIALE OVS","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO DELLE LANGHE 10 - CENTRO COMMERCIALE OVS","citta":"ALBA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA TALENTI - CENTRO COMMERCIALE OVS","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO BATTAGLIONE 5 - CENTRO COMMERCIALE OVS","citta":"AOSTA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA CHIAPPORI 1/C > CORSO GENOVA - CENTRO COMMERCIALE OVS","citta":"VENTIMIGLIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"LARGO LEONARDO DA VINCI 110 - CENTRO COMMERCIALE OVS","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA TRIONFALE 8441/8445 - CENTRO COMMERCIALE OVS","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA XXV APRILE 26 - CENTRO COMMERCIALE OVS","citta":"SONDRIO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA CORFU' 1/A - CENTRO COMMERCIALE OVS","citta":"VENEZIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO BUENOS AIRES 36","citta":"MILANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA ACILIA 61/63 - CENTRO COMMERCIALE OVS","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DEI PANZANI 31/R - CENTRO COMMERCIALE OVS","citta":"FIRENZE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO M. D'AZEGLIO 6/8 - CENTRO COMMERCIALE OVS","citta":"IVREA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DI PALMA 118","citta":"TARANTO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA SULIS - CENTRO COMMERCIALE OVS","citta":"ALGHERO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA NICOLA FABRIZI 32","citta":"TORINO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"LOCALITA' PIANO D'ACCIO - CENTRO COMMERCIALE GRAN SASSO","citta":"TERAMO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA TRICESIMO 149 - CENTRO COMMERCIALE TERMINAL NORD","citta":"UDINE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA FIRENZE - CENTRO COMMERCIALE PARCO COROLLA","citta":"MILAZZO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA QUASIMODO - CENTRO COMMERCIALE KATANE","citta":"GRAVINA DI CATANIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIALE SAN MARTINO 52","citta":"MESSINA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PRINCIPE DI BELMONTE 93","citta":"PALERMO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA U. BASSI 21/B","citta":"BOLOGNA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIALE BECCARIA 1 - CENTRO COMMERCIALE LA FAVORITA","citta":"MANTOVA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO VITTORIO EMANUELE 72","citta":"VILLAFRANCA DI VERONA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO ITALIA 69/71/R","citta":"SAVONA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA ITALIA 40","citta":"BIELLA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA CENTRALE 1","citta":"BRUNICO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA LEONARDO DA VINCI 26","citta":"MERANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA CARDUCCI 24","citta":"TRIESTE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DUCA D'AOSTA 91","citta":"MONFALCONE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO GIUSEPPE VERDI 46","citta":"GORIZIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO VITTORIO EMANUELE II 18","citta":"PORDENONE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA MASSIMO D'AZEGLIO 45","citta":"VIAREGGIO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA DELLA BORSA 8","citta":"TRIESTE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA COLETTA 20/22","citta":"BARLETTA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PAPA GIOVANNI XXIII 205 - 207","citta":"BARI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA SALVATORE TRINCHESE 80","citta":"LECCE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA ALDO MORO 69","citta":"BISCEGLIE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA SANTA CATERINA 19 - CENTRO COMMERCIALE LA MONGOLFIERA","citta":"BARI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA UMBERTO GIORDANO 36","citta":"FOGGIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA POLA 42","citta":"OSTUNI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO UMBERTO I 1","citta":"BRINDISI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA GIUSEPPE FANELLI 50","citta":"MARTINA FRANCA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"STRADA STATALE APPIA KM 144 - CENTRO COMMERCIALE BUONVENTO","citta":"BENEVENTO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO VITTORIO EMANUELE 135/137","citta":"TRANI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA ALDO MORO 94 / 96","citta":"CERIGNOLA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MATTEOTTI 22","citta":"SINISCOLA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA SONNINO 89","citta":"CAGLIARI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PAOLI 30/A","citta":"CAGLIARI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"LARGO CARLO FELICE 34","citta":"CAGLIARI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"LOCALITA' MORE CORRAXE - CENTRO COMMERCIALE LA CORTE DEL SOLE","citta":"SESTU"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA LAMPEDUSA - CENTRO COMMERCIALE I GABBIANI","citta":"CAPOTERRA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA SARDEGNA 84","citta":"ASSEMINI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DIEGO CONTINI 1","citta":"ORISTANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA CAGLIARI - CENTRO COMMERCIALE PORTA NUOVA","citta":"ORISTANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA SULIS 11","citta":"ALGHERO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA LAMARMORA 169","citta":"NUORO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA GIOVANNI AMENDOLA 38","citta":"SASSARI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA LAMARMORA 5","citta":"BOSA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DE GASPERI 8","citta":"OZIERI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MARIO ANGELONI 60/E","citta":"PERUGIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MONTEFIORINO 12 - CENTRO COMMERCIALE COSPEA","citta":"TERNI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO VANNUCCI 65","citta":"PERUGIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DELLA VALTIERA 181 - CENTRO COMMERCIALE COLLESTRADA","citta":"PERUGIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"STRADA STATALE ADRIATICA NORD - CENTRO COMMERCIALE IL MAESTRALE","citta":"SENIGALLIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DEL COMMERCIO 52 - CENTRO COMMERCIALE AL BATTENTE","citta":"ASCOLI PICENO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO AMENDOLA 55","citta":"ANCONA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO MATTEOTTI 76","citta":"FANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA LUIGI EINAUDI - CENTRO COMMERCIALE METAURO AUCHAN","citta":"FANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA SETTEVALLI 328","citta":"PERUGIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA VENETO 21","citta":"BASTIA UMBRA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA AMENDOLA - CENTRO COMMERCIALE SETTEPONTI","citta":"AREZZO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PROSPERI 42 - CENTRO COMMERCIALE IL GIRASOLE","citta":"FERMO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA COLLE DELLE API - CENTRO COMMERCIALE CENTRO DEL MOLISE","citta":"CAMPOBASSO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"LOCALITA' NUNZIATELLA - CENTRO COMMERCIALE IN PIAZZA","citta":"ISERNIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA RIGNANO 10 - CENTRO COMMERCIALE IL CASENTINO","citta":"BIBBIENA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MERCATANTI LOCALITA' GIARDINO - CENTRO COMMERCIALE PERSEO","citta":"RIETI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PIERO DELLA FRANCESCA - CENTRO COMMERCIALE CASTELLO","citta":"CITTA' DI CASTELLO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DEI SAMPIERI - CENTRO COMMERCIALE CASETTA MATTEI CENTER","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA BOLOGNA 32","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DELLE BALENIERE 12/14","citta":"OSTIA LIDO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PIO VI 10","citta":"LATINA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO UMBERTO I 78","citta":"PESCARA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA IV NOVEMBRE 2","citta":"GENZANO DI ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"SS125 MARINEDDA - CENTRO COMMERCIALE OLBIAMARE 2 P","citta":"OLBIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO UMBERTO I 105","citta":"OLBIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"STRADA STATALE 125 - CENTRO COMMERCIALE TERRANOVA IPERSTANDA","citta":"OLBIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA COSTITUENTE - FRONTE STADIO","citta":"CARBONIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA SELLA 10","citta":"IGLESIAS"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA GARIBALDI 4/6","citta":"LA MADDALENA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIALE COSTA SMERALDA 51","citta":"ARZACHENA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA TAGLIAFICO 27","citta":"CARLOFORTE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MONS. VIRGILIO 37","citta":"TORTOLI'"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO DEL POPOLO 32","citta":"TREVISO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA GUZZANICA 62 - CENTRO COMMERCIALE LE DUE TORRI","citta":"STEZZANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PAOLO SARPI 29","citta":"MILANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA MARESCOTTI 1/1A","citta":"LADISPOLI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA SAN GIUSEPPE 25 - CENTRO COMMERCIALE IPERCOOP CONE'","citta":"CONEGLIANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIALE UMBRIA 16 - CENTRO COMMERCIALE PIAZZALODI","citta":"MILANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA ASSORO 25B LOCALITA' TORRE INGASTONE - CENTRO COMMERCIALE LA TORRE","citta":"PALERMO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA GALILEO GALILEI 20 - CENTRO COMMERCIALE TWENTY","citta":"BOLZANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA GORGNI 20 - CENTRO COMMERCIALE GALASSIA","citta":"PIACENZA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO VERCELLI 8","citta":"MILANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DELL'AGRICOLTURA 1","citta":"VENTURINA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIALE EUROPA 1 - CENTRO COMMERCIALE I SALICI","citta":"SACILE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MARCO POLO 3 - CENTRO COMMERCIALE CENTRO LAME","citta":"BOLOGNA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA FONTEVIVO - CENTRO COMMERCIALE LE TERRAZZE","citta":"LA SPEZIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PRATI GUORI - CENTRO COMMERCIALE ADRIATICO","citta":"PORTOGRUARO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DE GASPERI 80/82","citta":"BASSANO DEL GRAPPA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA SAN COSTANTINO 98 - CENTRO COMMERCIALE IL CUORE ADRIATICO","citta":"CIVITANOVA MARCHE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DELLE PLEIADI 19 - CENTRO COMMERCIALE PARCO PRATO","citta":"PRATO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA COSTANZI - CENTRO COMMERCIALE PORTA D'ORVIETO","citta":"ORVIETO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIALE AFRICA 1 - CENTRO COMMERCIALE LE RUGHE","citta":"FORMELLO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA STOPPANI 12","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA APPIA 162/164","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA ANDREA MANTEGNA 27","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PO 128-130-132","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DEL TRITONE 20/21/22/23","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA TIBERINA KM. 16.400 - CENTRO COMMERCIALE ARCA","citta":"CAPENA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIALE LIBIA 219","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PRENESTINA ANTICA 220","citta":"PALESTRINA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA LE LAME 1 - CENTRO COMMERCIALE LE SORGENTI","citta":"FROSINONE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PERTINI 1 - CENTRO COMMERCIALE BRACCIANO","citta":"BRACCIANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA FILIPPO CIVININI 113","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DI DONNA OLIMPIA 191/195","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA ATTILIO REGOLO 12","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DI TORREVECCHIA 46/54","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA F. IL MACEDONE ED.2 IS. 53","citta":"CASALPALOCCO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA EUGENIO LURAGHI - CENTRO COMMERCIALE ARESE SHOPPING CENTER (STORE DOUGLAS)","citta":"ARESE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"ZONA INDUSTRIALE A.S.I. - CENTRO COMMERCIALE GRAND'APULIA","citta":"FOGGIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA DEL POPOLO 26","citta":"ASCOLI PICENO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA CASILINA - CENTRO COMMERCIALE LE GRANGE","citta":"CASSINO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA POLA 20 - CENTRO COMMERCIALE LE PIRAMIDI","citta":"TORRI DI QUARTESOLO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA FOSSE ARDEATINE - CENTRO COMMERCIALE I TEMPLI","citta":"AGRIGENTO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO ROMITA - CENTRO COMMERCIALE PANORAMA","citta":"ALESSANDRIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO ROMA 47","citta":"ALESSANDRIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PONTINA STRADA STATALE 148 - CENTRO COMMERCIALE APRILIA 2","citta":"APRILIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA EUGENIO LURAGHI - CENTRO COMMERCIALE ARESE SHOPPING CENTER (STORE LIMONI)","citta":"ARESE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA TIBURTINA - CENTRO COMMERCIALE I MARSI","citta":"AVEZZANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MEUCCI 6 LOCALITA' SCOPICCI - PARCO COMMERCIALE VIA MEUCCI","citta":"BARBERINO DI MUGELLO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO CAVOUR 147/149/151","citta":"BARI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA SPARANO 140","citta":"BARI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PADANA SUPERIORE 154 - CENTRO COMMERCIALE LA CORTE LOMBARDA","citta":"BELLINZAGO LOMBARDO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA VALCORRENTE 2 - CENTRO COMMERCIALE ETNAPOLIS","citta":"BELPASSO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA XX SETTEMBRE 65/67/69","citta":"BERGAMO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA SANTA BARBARA - CENTRO COMMERCIALE GLI ORSI","citta":"BIELLA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA INDIPENDENZA 27","citta":"BOLOGNA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA RIZZOLI 10","citta":"BOLOGNA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA ROMAIRONE 10 - CENTRO COMMERCIALE L'AQUILONE","citta":"BOLZANETO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"GALLERIA GREIF LAUBENGASSE 7","citta":"BOLZANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PORTICI 52","citta":"BOLZANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA SAN QUIRICO 165 - CENTRO COMMERCIALE I GIGLI","citta":"CAMPI BISENZIO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CONTRADA CHIACHEA - CENTRO COMMERCIALE POSEIDON","citta":"CARINI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA POLLIO 50 - CENTRO COMMERCIALE AUCHAN","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA NOICATTARO 2 - CENTRO COMMERCIALE AUCHAN","citta":"CASAMASSIMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"STRADA PER TORINO 23 - CENTRO COMMERCIALE BENNET","citta":"CASELLE TORINESE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DEL COMMERCIO 8/22 - CENTRO COMMERCIALE OUTLET","citta":"CASTELGUELFO DI BOLOGNA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DEL PONTE DI PISCINA CUPA SNC - CASTEL ROMANO SHOPPING VILLAGE","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DEI CARPANI - CENTRO COMMERCIALE I GIARDINI DEL SOLE","citta":"CASTELFRANCO VENETO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA GELSO BIANCO KM 84 - CENTRO COMMERCIALE PORTE DI CATANIA","citta":"CATANIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"STRADA STATALE 16 KM. 962.700 - CENTRO COMMERCIALE CAVALLINO","citta":"CAVALLINO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MATTEOTTI 4","citta":"CERVIA-MILANO MARITTIMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MARTIRI DELLA LIBERAZIONE 23","citta":"CHIAVARI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"LOCALITA' SANTA FILOMENA - CENTRO COMMERCIALE MEGALO'","citta":"CHIETI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA EDMONDO DE AMICIS 2 - CENTRO COMMERCIALE LA FONTANA","citta":"CINISELLO BALSAMO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA L. PETRUZZI 140/144 - CENTRO COMMERCIALE PESCARA NORD","citta":"CITTA' SANT'ANGELO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA CASTELLEONE 108 - CENTRO COMMERCIALE IPERCOOP","citta":"CREMONA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA NERVI - CENTRO COMMERCIALE WARNER VILLAGE","citta":"ELLERA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO PORTA RENO 63/65","citta":"FERRARA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO GIOVECCA 82/84","citta":"FERRARA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZALE DELLA COOPERAZIONE 4 - CENTRO COMMERCIALE PUNTA DI FERRO","citta":"FORLI'"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA 25 OTTOBRE - CENTRO COMMERCIALE LE FORNACI","citta":"FORLIMPOPOLI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA BERLINGUER - CENTRO COMMERCIALE CREMONA 2","citta":"GADESCO PIEVE DELMONA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA FIUMARA 15/16 - CENTRO COMMERCIALE FIUMARA","citta":"GENOVA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA XX SETTEMBRE 89","citta":"GENOVA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DEL COMMENDONE - CENTRO COMMERCIALE  MAREMA'","citta":"GROSSETO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA AMENDOLA 129 - CENTRO COMMERCIALE LEONARDO","citta":"IMOLA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MILANO 5 - CENTRO COMMERCIALE SCALO MILANO","citta":"LOCATE DI TRIULZI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MANTOVA 36 - CENTRO COMMERCIALE IPER IL LEONE","citta":"LONATO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA MARCONI 19","citta":"MANTOVA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MASSA AVENZA 32 - CENTRO COMMERCIALE MARI E MONTI","citta":"MASSA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DON TOSATTO 22 - CENTRO COMMERCIALE PORTE DI MESTRE","citta":"MESTRE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA GROSOTTO 7 - CENTRO COMMERCIALE PORTELLO","citta":"MILANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO BUENOS AIRES > VIA PANFILO CASTALDI 42","citta":"MILANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CONTRADA CUBBA MARLETTA - CENTRO COMMERCIALE CENTRO SICILIA TENUTELLA","citta":"MISTERBIANCO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DEI PORTUALI 12 - CENTRO COMMERCIALE PUGLIA OUTLET VILLAGE","citta":"MOLFETTA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DE GASPERI 6 - CENTRO COMMERCIALE AUCHAN IL TRIANGOLO","citta":"MAZZANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MAZZA 50 - CENTRO COMMERCIALE MONTEBELLO","citta":"MONTEBELLO DELLA BATTAGLIA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DEI CACCIATORI 111 - CENTRO COMMERCIALE I VIALI","citta":"NICHELINO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA BOSCOFANGONE - CENTRO COMMERCIALE VULCANO BUONO","citta":"NOLA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA AMORETTI - CENTRO COMMERCIALE METROPOLI","citta":"NOVATE MILANESE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PORTICO 71 - CENTRO COMMERCIALE ORIO CENTER","citta":"ORIO AL SERIO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA SANTA LUCIA 13/15","citta":"PADOVA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PECORAINO LOCALITA' ROCCELLA  - CENTRO COMMERCIALE FORUM","citta":"PALERMO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA BRANCA 54 > VIA PEDROTTI","citta":"PESARO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA GAGARIN 5/N - CENTRO COMMERCIALE ROSSINI CENTER","citta":"PESARO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"STRADA STATALE415 PAULLESE - CENTRO COMMERCIALE GALLERIA BORROMEA","citta":"PESCHIERA BORROMEO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PACINOTTI - CENTRO COMMERCIALE CARREFOUR","citta":"PONTECAGNANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DIAZ 27","citta":"RAVENNA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA TOGLIATTI 2 - CENTRO COMMERCIALE AUCHAN","citta":"RESCALDINA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIALE CECCARINI 11/13/15","citta":"RICCIONE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO D'AUGUSTO 79","citta":"RIMINI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CONTRADA ROVETAZZO - CENTRO COMMERCIALE EMMEZETA","citta":"RIPOSTO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA ALBERTO LIONELLO - CENTRO COMMERCIALE PORTA DI ROMA (STORE DOUGLAS)","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA COLOMBO 714 - CENTRO COMMERCIALE EUROMA 2 (STORE DOUGLAS)","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIALE DI VALLE AURELIA - CENTRO COMMERCIALE AURA","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA EINAUDI - CENTRO COMMERCIALE RONCADELLE SHOPPING CENTER","citta":"RONCADELLE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DEL GARDA 95 - CENTRO COMMERCIALE MILLENNIUM","citta":"ROVERETO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA CURIEL 25 - CENTRO COMMERCIALE FIORDALISO","citta":"ROZZANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA ISEO 1/A LOCALITA' CALVECCHIA - CENTRO COMMERCIALE CENTROPIAVE","citta":"SAN DONA' DI PIAVE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MONTE CRISTALLO 3 - CENTRO COMMERCIALE VERONA UNO","citta":"SAN GIOVANNI LUPATOTO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIALE DEL LAVORO - CENTRO COMMERCIALE LE CORTI VENETE","citta":"SAN MARTINO BUON ALBERGO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PROVVIDENZA - CENTRO COMMERCIALE LE BRENTELLE","citta":"SARMEOLA DI RUBANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"PIAZZA COLOMBO 3 - CENTRO COMMERCIALE ROMAGNA CENTER","citta":"SAVIGNANO SUL RUBICONE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA BRUSAPORTO 41 - CENTRO COMMERCIALE ALLE VALLI","citta":"SERIATE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PER BUSTO ARSIZIO 11 - CENTRO COMMERCIALE LE BETULLE","citta":"SOLBIATE OLONA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"STRADA STATALE LE-BR KM 1.9 - CENTRO COMMERCIALE LA MONGOLFIERA","citta":"SURBO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PER MONTEMESOLA - CENTRO COMMERCIALE LA MONGOLFIERA","citta":"TARANTO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA GOMBE - CENTRO COMMERCIALE CONTINENTE","citta":"THIENE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA GARIBALDI 5","citta":"TORINO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA ROMA 95","citta":"TORINO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA NIZZA 262 - CENTRO COMMERCIALE LINGOTTO","citta":"TORINO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA A. BARDELLI 4 - CENTRO COMMERCIALE FIERA","citta":"TORREANO DI MARTIGNACCO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"STRADA PROVINCIALE PER VIGUZZOLO 2 - CENTRO COMMERCIALE LE OASI","citta":"TORTONA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA OSS MAZZURANA 37","citta":"TRENTO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA CALMAGGIORE 21","citta":"TREVISO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA CAPPELLO 35/A","citta":"VERONA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DELLE NAZIONI 1 - CENTRO COMMERCIALE ADIGEO","citta":"VERONA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA MAZZINI 74","citta":"VERONA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA GALILEO GALILEI - CENTRO COMMERCIALE ACQUARIO","citta":"VIGNATE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"LOCALITA' MARANUZ 2 - CENTRO COMMERCIALE TIARE SHOPPING","citta":"VILLESSE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA DELLA CARTIERA 1 - CENTRO COMMERCIALE PANORAMA","citta":"VILLORBA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"STRADA PROVINCIALE 227 - CENTRO COMMERCIALE IL DESTRIERO","citta":"VITTUONE"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PASUBIO 144 - CENTRO COMMERCIALE PORTO GRANDE","citta":"SAN BENEDETTO DEL TRONTO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA PRENESTINA BIS - CENTRO COMMERCIALE GRANROMA","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"A19 PALERMO-CATANIA - CENTRO COMMERCIALE SICILIA OUTLET VILLAGE","citta":"AGIRA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CORSO ROMANIA 460","citta":"TORINO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA COSTANTE NERI 57/59 - COMPENDIO OFFICINE STORICHE","citta":"LIVORNO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA LAURENTINA - CENTRO COMMERCIALE MAXIMO","citta":"ROMA"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA TORINO 36","citta":"MILANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA WEINGARTNER 31 - CC ALGO","citta":"LAGUNDO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA GOTTLIEB WILHELM DAIMLER - CC CASCINA MERLATA","citta":"MILANO"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"CONTRADA SPALLA - PARCO COMMERCIALE BELVEDERE","citta":"MELILLI"}},
        {"title":"DOUGLAS","acf":{"ragione_sociale":"DOUGLAS","indirizzo":"VIA GIOLITTI 2","citta":"ROMA"}},
        {"title":"ENNIO SRL","acf":{"ragione_sociale":"ENNIO SRL","indirizzo":"VIA SAN FELICE 22/D","citta":"BOLOGNA"}},
        {"title":"ERIKA SAS DI L. MARAZZI E C.","acf":{"ragione_sociale":"ERIKA SAS DI L. MARAZZI E C.","indirizzo":"VIALE FULVIO TESTI 70","citta":"MILANO"}},
        {"title":"ERNIANI CENTROSTILE SRL","acf":{"ragione_sociale":"ERNIANI CENTROSTILE SRL","indirizzo":"STRADA STATALE LAGO DI VIVERONE 23","citta":"BUROLO"}},
        {"title":"ETEREA PROFUMERIA DI ZINGARO FRANCESCO","acf":{"ragione_sociale":"ETEREA PROFUMERIA DI ZINGARO FRANCESCO","indirizzo":"PIAZZA DELLA REPUBBLICA, 54","citta":"TRANI"}},
        {"title":"ETEREA PROFUMERIA DI ZINGARO FRANCESCO","acf":{"ragione_sociale":"ETEREA PROFUMERIA DI ZINGARO FRANCESCO","indirizzo":"CORSO DE GASPERI 5","citta":"TRANI"}},
        {"title":"EUROCOSMO 2000 SRL","acf":{"ragione_sociale":"EUROCOSMO 2000 SRL","indirizzo":"VIA LEONARDO DA VINCI 101","citta":"ROMA"}},
        {"title":"F&F SRL","acf":{"ragione_sociale":"F&F SRL","indirizzo":"VIA PANFILO CASTALDI 41","citta":"MILANO"}},
        {"title":"F.LLI CIRROTTOLA SRL","acf":{"ragione_sociale":"F.LLI CIRROTTOLA SRL","indirizzo":"VIA PARRI 36","citta":"MATERA"}},
        {"title":"F.LLI CIRROTTOLA SRL","acf":{"ragione_sociale":"F.LLI CIRROTTOLA SRL","indirizzo":"VIA GIOIA 38","citta":"SANTERAMO IN COLLE"}},
        {"title":"F.LLI CIRROTTOLA SRL","acf":{"ragione_sociale":"F.LLI CIRROTTOLA SRL","indirizzo":"VIALE MARTIRI 139","citta":"ALTAMURA"}},
        {"title":"FARMACIA S.CATERINA S.R.L.","acf":{"ragione_sociale":"FARMACIA S.CATERINA S.R.L.","indirizzo":"PIAZZA MATTEOTTI","citta":"CASERTA"}},
        {"title":"FARMACIA VATICANA","acf":{"ragione_sociale":"FARMACIA VATICANA","indirizzo":"VIA DELLA POSTA","citta":"CITTA' DEL VATICANO"}},
        {"title":"FUSCO FULVIO & C. SRL","acf":{"ragione_sociale":"FUSCO FULVIO & C. SRL","indirizzo":"CORSO LUCCI ARNALDO 110","citta":"NAPOLI"}},
        {"title":"FUSCO FULVIO & C. SRL","acf":{"ragione_sociale":"FUSCO FULVIO & C. SRL","indirizzo":"VIA F.IMPARATO 198 - CENTRO COMMERCIALE MARKET CENTER TWO","citta":"NAPOLI"}},
        {"title":"GALASSIA SRL","acf":{"ragione_sociale":"GALASSIA SRL","indirizzo":"VIALE SACCO E VANZETTI 155/157","citta":"ROMA"}},
        {"title":"GALASSIA SRL","acf":{"ragione_sociale":"GALASSIA SRL","indirizzo":"VIALE SOMALIA 244","citta":"ROMA"}},
        {"title":"GALASSIA SRL","acf":{"ragione_sociale":"GALASSIA SRL","indirizzo":"CIRCONVALLAZIONE CORNELIA 18","citta":"ROMA"}},
        {"title":"GALASSIA SRL","acf":{"ragione_sociale":"GALASSIA SRL","indirizzo":"VIA G. DE' CALVI 8/10","citta":"ROMA"}},
        {"title":"GALASSIA SRL","acf":{"ragione_sociale":"GALASSIA SRL","indirizzo":"VIA LAURENTINA 611","citta":"ROMA"}},
        {"title":"GALASSIA SRL","acf":{"ragione_sociale":"GALASSIA SRL","indirizzo":"VIA PARRACCIANI 32","citta":"ROMA"}},
        {"title":"GALASSIA SRL","acf":{"ragione_sociale":"GALASSIA SRL","indirizzo":"VIA ROBERTO MALATESTA 72","citta":"ROMA"}},
        {"title":"GALASSIA SRL","acf":{"ragione_sociale":"GALASSIA SRL","indirizzo":"PIAZZA ROSOLINO PILO 23","citta":"ROMA"}},
        {"title":"GALASSIA SRL","acf":{"ragione_sociale":"GALASSIA SRL","indirizzo":"VIA ANICIO GALLO 36/38","citta":"ROMA"}},
        {"title":"GALASSIA SRL","acf":{"ragione_sociale":"GALASSIA SRL","indirizzo":"PIAZZA DEI MIRTI 6/7","citta":"ROMA"}},
        {"title":"GALASSIA SRL","acf":{"ragione_sociale":"GALASSIA SRL","indirizzo":"VIA TURATI 20 - CENTRO COMMERCIALE LA PASSEGGIATA","citta":"VELLETRI"}},
        {"title":"GALASSIA SRL","acf":{"ragione_sociale":"GALASSIA SRL","indirizzo":"VIA ANGELICO 84","citta":"ROMA"}},
        {"title":"GALASSIA SRL","acf":{"ragione_sociale":"GALASSIA SRL","indirizzo":"VIA AGOSTINO FAUSTI 15","citta":"BRACCIANO"}},
        {"title":"GALASSIA SRL","acf":{"ragione_sociale":"GALASSIA SRL","indirizzo":"PIAZZA BOLOGNA 29","citta":"ROMA"}},
        {"title":"GALASSIA SRL","acf":{"ragione_sociale":"GALASSIA SRL","indirizzo":"VIA TUSCOLANA 687 A","citta":"ROMA"}},
        {"title":"GALEAZZI PROFUMERIE SAS","acf":{"ragione_sociale":"GALEAZZI PROFUMERIE SAS","indirizzo":"VIA GIORDANO BRUNO 4","citta":"ANCONA"}},
        {"title":"GALEAZZI PROFUMERIE SAS","acf":{"ragione_sociale":"GALEAZZI PROFUMERIE SAS","indirizzo":"VIA DELLA SBROZZOLA 26 - CENTRO COMMERCIALE CARGOPIER","citta":"OSIMO"}},
        {"title":"GALEAZZI PROFUMERIE SAS","acf":{"ragione_sociale":"GALEAZZI PROFUMERIE SAS","indirizzo":"VIA N. ABBAGNANO 7 - CENTRO COMMERCIALE IL MOLINO","citta":"SENIGALLIA"}},
        {"title":"GALEAZZI PROFUMERIE SAS","acf":{"ragione_sociale":"GALEAZZI PROFUMERIE SAS","indirizzo":"VIALE SECONDO MORETTI 40","citta":"SAN BENEDETTO DEL TRONTO"}},
        {"title":"GALEAZZI PROFUMERIE SAS","acf":{"ragione_sociale":"GALEAZZI PROFUMERIE SAS","indirizzo":"CORSO GARIBALDI 114","citta":"ANCONA"}},
        {"title":"GALEAZZI PROFUMERIE SAS","acf":{"ragione_sociale":"GALEAZZI PROFUMERIE SAS","indirizzo":"CORSO GARIBALDI 112","citta":"ANCONA"}},
        {"title":"GALEAZZI PROFUMERIE SAS","acf":{"ragione_sociale":"GALEAZZI PROFUMERIE SAS","indirizzo":"VIA SCATAGLINI 6 - CENTRO COMMERCIALE CONERO","citta":"ANCONA"}},
        {"title":"GALEAZZI PROFUMERIE SAS","acf":{"ragione_sociale":"GALEAZZI PROFUMERIE SAS","indirizzo":"CORSO MATTEOTTI 121","citta":"PORTO RECANATI"}},
        {"title":"GALEAZZI PROFUMERIE SAS","acf":{"ragione_sociale":"GALEAZZI PROFUMERIE SAS","indirizzo":"VIA GARIBALDI 19","citta":"FOLIGNO"}},
        {"title":"GALEAZZI PROFUMERIE SAS","acf":{"ragione_sociale":"GALEAZZI PROFUMERIE SAS","indirizzo":"VIA CARLO LIVIERO 2","citta":"CITTA' DI CASTELLO"}},
        {"title":"GALEAZZI PROFUMERIE SAS","acf":{"ragione_sociale":"GALEAZZI PROFUMERIE SAS","indirizzo":"VIA DALLA CHIESA 2","citta":"GROTTAMMARE"}},
        {"title":"GALEAZZI PROFUMERIE SAS","acf":{"ragione_sociale":"GALEAZZI PROFUMERIE SAS","indirizzo":"VIA CAMPO DELL'AVIAZIONE 17 - CENTRO COMMERCIALE LE GROTTE","citta":"CAMERANO"}},
        {"title":"GALEAZZI PROFUMERIE SAS","acf":{"ragione_sociale":"GALEAZZI PROFUMERIE SAS","indirizzo":"PIAZZA GARIBALDI 4","citta":"FALCONARA MARITTIMA"}},
        {"title":"GALEAZZI PROFUMERIE SAS","acf":{"ragione_sociale":"GALEAZZI PROFUMERIE SAS","indirizzo":"CORSO MATTEOTTI 37","citta":"JESI"}},
        {"title":"GALEAZZI PROFUMERIE SAS","acf":{"ragione_sociale":"GALEAZZI PROFUMERIE SAS","indirizzo":"CORSO MATTEOTTI, 111","citta":"CHIARAVALLE"}},
        {"title":"GALEAZZI PROFUMERIE SAS","acf":{"ragione_sociale":"GALEAZZI PROFUMERIE SAS","indirizzo":"VIA FILONZI 12","citta":"ANCONA"}},
        {"title":"GALEAZZI PROFUMERIE SAS","acf":{"ragione_sociale":"GALEAZZI PROFUMERIE SAS","indirizzo":"PIAZZA TRE MARTIRI 27","citta":"RIMINI"}},
        {"title":"GARDACOMMERCIALE SAS DI GIRARDI BENIAMINO & C.","acf":{"ragione_sociale":"GARDACOMMERCIALE SAS DI GIRARDI BENIAMINO & C.","indirizzo":"VIALE SAN FRANCESCO 10/B","citta":"RIVA DEL GARDA"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"VIA S. ESPOSITO 32","citta":"AVELLINO"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"VIA BARATTA 46","citta":"BATTIPAGLIA"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"VIA PRINCIPE DI NAPOLI 1","citta":"BENEVENTO"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"VIA DEI LONGOBARDI - CENTRO COMMERCIALE I SANNITI","citta":"BENEVENTO"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"VIA ROMA 33","citta":"CASTELLAMMARE DI STABIA"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"LARGO ALESSANDRO LALA 22","citta":"NAPOLI"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"VIA S. MARIA A CUBITO 1  - CENTRO COMMERCIALE GRANDE SUD","citta":"GIUGLIANO"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"VIA LUCANA 30","citta":"MATERA"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"VIA NAZIONALE TORRETTE - CENTRO COMMERCIALE L'ORBITA","citta":"MERCOGLIANO"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"VIA MARIO FIORE 26","citta":"NAPOLI"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"VIA CAVALLERIZZA 42","citta":"NAPOLI"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"VIA BOSCOFANGONE - CENTRO COMMERCIALE VULCANO BUONO","citta":"NOLA"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"CORSO GARIBALDI 10","citta":"PORTICI"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"VIA PRETORIA 114","citta":"POTENZA"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"PIAZZA BOLOGNA 53/54","citta":"ROMA"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"VIA TUSCOLANA 1008","citta":"ROMA"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"VIA CARMINE 44","citta":"SALERNO"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"VIA CORREALE 11 B/C/D","citta":"SORRENTO"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"VIA ROMA 115","citta":"TERRACINA"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"PIAZZA AMEDEO 21/22","citta":"NAPOLI"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"VIA MACELLO 22 - CENTRO COMMERCIALE LA CARTIERA","citta":"POMPEI"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"CORSO UMBERTO I 215","citta":"NAPOLI"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"CORSO TRIESTE 103/105","citta":"CASERTA"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"CORSO VITTORIO EMANUELE 80","citta":"SALERNO"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"CORSO UMBERTO I 82","citta":"BRINDISI"}},
        {"title":"GARGIULO & MAIELLO SPA","acf":{"ragione_sociale":"GARGIULO & MAIELLO SPA","indirizzo":"VIA LUCA GIORDANO 118","citta":"NAPOLI"}},
        {"title":"GD PROFUMERIE SRL","acf":{"ragione_sociale":"GD PROFUMERIE SRL","indirizzo":"VIA DEI MILLE 50","citta":"ALBENGA"}},
        {"title":"GD PROFUMERIE SRL","acf":{"ragione_sociale":"GD PROFUMERIE SRL","indirizzo":"PIAZZA DEL PORTELLO 16/R","citta":"GENOVA"}},
        {"title":"GE.PRO. SRL","acf":{"ragione_sociale":"GE.PRO. SRL","indirizzo":"STRADA PROVINCIALE 2 - CENTRO COMMERCIALE BENNET IL PARCO","citta":"ANZANO DEL PARCO"}},
        {"title":"GE.PRO. SRL","acf":{"ragione_sociale":"GE.PRO. SRL","indirizzo":"VIA LOMBARDIA 68 - CENTRO COMMERCIALE MIRABELLO","citta":"CANTU'"}},
        {"title":"GE.PRO. SRL","acf":{"ragione_sociale":"GE.PRO. SRL","indirizzo":"CORSO XXV APRILE 129","citta":"ERBA"}},
        {"title":"GE.PRO. SRL","acf":{"ragione_sociale":"GE.PRO. SRL","indirizzo":"VIALE PREALPI 3 - CENTRO COMMERCIALE LAGHI","citta":"ERBA"}},
        {"title":"GE.PRO. SRL","acf":{"ragione_sociale":"GE.PRO. SRL","indirizzo":"VIA CARLO FABANI 21","citta":"MORBEGNO"}},
        {"title":"GE.PRO. SRL","acf":{"ragione_sociale":"GE.PRO. SRL","indirizzo":"VIA TRENTO 8","citta":"SONDRIO"}},
        {"title":"GE.PRO. SRL","acf":{"ragione_sociale":"GE.PRO. SRL","indirizzo":"CORSO ITALIA 27 - CENTRO COMMERCIALE BENNET","citta":"VANZAGHELLO"}},
        {"title":"GE.PRO. SRL","acf":{"ragione_sociale":"GE.PRO. SRL","indirizzo":"VIA UBOLDO 1","citta":"CERNUSCO SUL NAVIGLIO"}},
        {"title":"GE.PRO. SRL","acf":{"ragione_sociale":"GE.PRO. SRL","indirizzo":"PIAZZA SANTA MARIA 4","citta":"BUSTO ARSIZIO"}},
        {"title":"GE.PRO. SRL","acf":{"ragione_sociale":"GE.PRO. SRL","indirizzo":"VIALE TRENTO TRIESTE CENTRO COMMERCIALE IL CASTELLO","citta":"SANT'ANGELO LODIGIANO"}},
        {"title":"GE.PRO. SRL","acf":{"ragione_sociale":"GE.PRO. SRL","indirizzo":"VIA NUOVA VALASSINA 7","citta":"MERONE"}},
        {"title":"GENERAL","acf":{"ragione_sociale":"GENERAL","indirizzo":"VIA PISACANE 4/R","citta":"LERICI"}},
        {"title":"GENERAL","acf":{"ragione_sociale":"GENERAL","indirizzo":"CORSO MATTEOTTI 356/7","citta":"CECINA"}},
        {"title":"GENERAL","acf":{"ragione_sociale":"GENERAL","indirizzo":"VIA BARSANTI 15","citta":"FORTE DEI MARMI"}},
        {"title":"GENERAL","acf":{"ragione_sociale":"GENERAL","indirizzo":"VIA CANTORE 159","citta":"GENOVA"}},
        {"title":"GENERAL","acf":{"ragione_sociale":"GENERAL","indirizzo":"PIAZZA DELLA CHIESA 6","citta":"CECINA"}},
        {"title":"GENERAL","acf":{"ragione_sociale":"GENERAL","indirizzo":"VIA GENOVA 44","citta":"DIANO MARINA"}},
        {"title":"GENERAL","acf":{"ragione_sociale":"GENERAL","indirizzo":"VIA CLAVESANA 48","citta":"MARINA DI ANDORA"}},
        {"title":"GENERAL","acf":{"ragione_sociale":"GENERAL","indirizzo":"VIA GARIBALDI 14","citta":"CASTELNUOVO GARFAGNANA"}},
        {"title":"GI.DE.CA SRL","acf":{"ragione_sociale":"GI.DE.CA SRL","indirizzo":"CORSO GARIBALDI 139","citta":"REGGIO CALABRIA"}},
        {"title":"GI.DE.CA SRL","acf":{"ragione_sociale":"GI.DE.CA SRL","indirizzo":"CORSO GARIBALDI 315","citta":"REGGIO CALABRIA"}},
        {"title":"GI.DE.CA SRL","acf":{"ragione_sociale":"GI.DE.CA SRL","indirizzo":"CORSO GIOVANNI NICOTERA 214","citta":"NICASTRO"}},
        {"title":"GI.DE.CA SRL","acf":{"ragione_sociale":"GI.DE.CA SRL","indirizzo":"STRADA STATALE 280 LOCALITA' COMUNI CONDOMINI - CENTRO COMMERCIALE DUE MARI","citta":"MAIDA"}},
        {"title":"GI.DE.CA SRL","acf":{"ragione_sociale":"GI.DE.CA SRL","indirizzo":"VIALE CALABRIA 310 -  CENTRO COMMERCIALE LE NINFEE","citta":"REGGIO CALABRIA"}},
        {"title":"GIBIN PROFUMERIE SRL","acf":{"ragione_sociale":"GIBIN PROFUMERIE SRL","indirizzo":"CORSO VITTORIO EMANUELE 29","citta":"CONEGLIANO"}},
        {"title":"GIBIN PROFUMERIE SRL","acf":{"ragione_sociale":"GIBIN PROFUMERIE SRL","indirizzo":"VIA CAMPIELLO DEL DUOMO 6","citta":"ODERZO"}},
        {"title":"GIBIN PROFUMERIE SRL","acf":{"ragione_sociale":"GIBIN PROFUMERIE SRL","indirizzo":"VIA SAN MICHELE 10","citta":"TREVISO"}},
        {"title":"GIBIN PROFUMERIE SRL","acf":{"ragione_sociale":"GIBIN PROFUMERIE SRL","indirizzo":"CORSO ITALIA, 40","citta":"CORTINA D'AMPEZZO"}},
        {"title":"GIBIN PROFUMERIE SRL","acf":{"ragione_sociale":"GIBIN PROFUMERIE SRL","indirizzo":"VIA CADUTI SUL LAVORO 5","citta":"SUSEGANA"}},
        {"title":"GIORDANO F.LLI SRL","acf":{"ragione_sociale":"GIORDANO F.LLI SRL","indirizzo":"PIAZZA CAVOUR 21","citta":"PINEROLO"}},
        {"title":"GIORDANO F.LLI SRL","acf":{"ragione_sociale":"GIORDANO F.LLI SRL","indirizzo":"VIA GUALTIERI 1","citta":"SALUZZO"}},
        {"title":"GIORDANO F.LLI SRL","acf":{"ragione_sociale":"GIORDANO F.LLI SRL","indirizzo":"CORSO TORINO 68","citta":"PINEROLO"}},
        {"title":"GIORDANO F.LLI SRL","acf":{"ragione_sociale":"GIORDANO F.LLI SRL","indirizzo":"CORSO RAFFAELLO 9","citta":"TORINO"}},
        {"title":"GIULIANA TRAPANESE","acf":{"ragione_sociale":"GIULIANA TRAPANESE","indirizzo":"GALLERIA RAGGIO 1","citta":"RAPALLO"}},
        {"title":"GIUSY SRL","acf":{"ragione_sociale":"GIUSY SRL","indirizzo":"CORSO ITALIA 152/154","citta":"SORRENTO"}},
        {"title":"GOCCE PROFUMERIA DI ZINGALE CARMELO & C. SAS","acf":{"ragione_sociale":"GOCCE PROFUMERIA DI ZINGALE CARMELO & C. SAS","indirizzo":"VIALE MARIO RAPISARDI 219-221","citta":"CATANIA"}},
        {"title":"GPM SRL","acf":{"ragione_sociale":"GPM SRL","indirizzo":"VIALE COLLI AMINEI 339","citta":"NAPOLI"}},
        {"title":"GPM SRL","acf":{"ragione_sociale":"GPM SRL","indirizzo":"VIA S. MARIA A CUBITO 1  - CENTRO COMMERCIALE GRANDE SUD","citta":"GIUGLIANO"}},
        {"title":"GPM SRL","acf":{"ragione_sociale":"GPM SRL","indirizzo":"VIA SANTA MARIA LA NOVA - CENTRO COMMERCIALE GOLFO DEI DESIDERI","citta":"AFRAGOLA"}},
        {"title":"GPM SRL","acf":{"ragione_sociale":"GPM SRL","indirizzo":"VIA MASULLO 76 - CENTRO COMMERCIALE QUARTO NUOVO","citta":"QUARTO"}},
        {"title":"GPM SRL","acf":{"ragione_sociale":"GPM SRL","indirizzo":"VIA ROMA 143/145/147","citta":"AVERSA"}},
        {"title":"GPM SRL","acf":{"ragione_sociale":"GPM SRL","indirizzo":"CORSO EUROPA 2","citta":"MARANO DI NAPOLI"}},
        {"title":"GPM SRL","acf":{"ragione_sociale":"GPM SRL","indirizzo":"VIA MACELLO 22 - CENTRO COMMERCIALE LA CARTIERA","citta":"POMPEI"}},
        {"title":"GPM SRL","acf":{"ragione_sociale":"GPM SRL","indirizzo":"STRADA STATALE SANNITICA 87 LOCALITA' AURNO - CENTRO COMMERCIALE CAMPANIA","citta":"MARCIANISE"}},
        {"title":"GPM SRL","acf":{"ragione_sociale":"GPM SRL","indirizzo":"PIAZZA MADONNA DELL'ARCO 12 - CENTRO COMMERCIALE LA BIRRERIA","citta":"NAPOLI"}},
        {"title":"GPM SRL","acf":{"ragione_sociale":"GPM SRL","indirizzo":"STRADA PROVINCIALE TRENTOLA PARETE 22 - CENTRO COMMERCIALE JAMBO 1","citta":"TRENTOLA DUCENTA"}},
        {"title":"GPM SRL","acf":{"ragione_sociale":"GPM SRL","indirizzo":"CORSO EUROPA 193","citta":"VILLARICCA"}},
        {"title":"GREAT PARFUMERY SAS DI GRAZIOLI & C","acf":{"ragione_sociale":"GREAT PARFUMERY SAS DI GRAZIOLI & C","indirizzo":"CORSO DI PORTA ROMANA 57","citta":"MILANO"}},
        {"title":"GRECO PROFUMERIE SRL","acf":{"ragione_sociale":"GRECO PROFUMERIE SRL","indirizzo":"CORSO VITTORIO EMANUELE 68/72","citta":"CALTANISSETTA"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"VIA MEDICI 219","citta":"SANT'AGATA DI MILITELLO"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"VIA MADDALENA 47","citta":"MESSINA"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"VIALE SAN MARTINO 152","citta":"MESSINA"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"VIALE DELLA LIBERTA' 183","citta":"MESSINA"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"VIA PRINCIPE DI CASTELNUOVO 125 - CENTRO COMMERCIALE LA BRIOSA","citta":"VILLAFRANCA TIRRENA"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"VIA VITTORIO EMANUELE 18","citta":"CAPO D'ORLANDO"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"VIA VITTORIO EMANUELE 17","citta":"BROLO"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"PIAZZA GUGLIELMO MARCONI","citta":"PATTI"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"VIA NAXOS 239/B","citta":"GIARDINI NAXOS"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"VIA ETNEA 189","citta":"CATANIA"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"VIA ROMA 10","citta":"CEFALU'"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"SS 114 KM 3,9 - CONTRADA VETRO","citta":"CONTESSE"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"VIA KENNEDY 16","citta":"CALTANISSETTA"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"VIA CRISPI 485","citta":"SANTA TERESA DI RIVA"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"VIA UMBERTO I 4","citta":"SANTO STEFANO DI CAMASTRA"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"SS 114 KM 6.200 CENTRO COMMERCIALE TREMESTIERI","citta":"MESSINA"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"CONTRADA MASSERIA 17/D","citta":"CAPO D'ORLANDO"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"VIA ETNEA 726","citta":"CATANIA"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"PIAZZA ETTORE MAJORANA 13","citta":"CATANIA"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"VIA CANFORA 86","citta":"CATANIA"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"VIA REGINA MARGHERITA 65","citta":"BARCELLONA POZZO DI GOTTO"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"VIA CALLIPOLI 123","citta":"GIARRE"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"VIA MARINA GARIBALDI 51","citta":"MILAZZO"}},
        {"title":"GRIFFE SRL","acf":{"ragione_sociale":"GRIFFE SRL","indirizzo":"CORSO DELLE PROVINCE 52","citta":"CATANIA"}},
        {"title":"GRIFF'I SRL","acf":{"ragione_sociale":"GRIFF'I SRL","indirizzo":"VIA GRAMSCI 4","citta":"VERONA"}},
        {"title":"GRIFF'I SRL","acf":{"ragione_sociale":"GRIFF'I SRL","indirizzo":"PIAZZA DAVIDE BEGALLI 8","citta":"PEDEMONTE"}},
        {"title":"GRIFF'I SRL","acf":{"ragione_sociale":"GRIFF'I SRL","indirizzo":"VIA BRENNERO 26/A","citta":"BALCONI DI PESCANTINA"}},
        {"title":"GRIFF'I SRL","acf":{"ragione_sociale":"GRIFF'I SRL","indirizzo":"VIA PIORTA 1","citta":"BUSSOLENGO"}},
        {"title":"GRIFF'I SRL","acf":{"ragione_sociale":"GRIFF'I SRL","indirizzo":"VIA PADOVA 5 - CENTRO COMMERCIALE BLUE GARDEN","citta":"RIVA DEL GARDA"}},
        {"title":"GRIFF'I SRL","acf":{"ragione_sociale":"GRIFF'I SRL","indirizzo":"VIA NAPOLEONE III 2 - CENTRO COMMERCIALE NEW METROPOL","citta":"VILLAFRANCA DI VERONA"}},
        {"title":"GRIFF'I SRL","acf":{"ragione_sociale":"GRIFF'I SRL","indirizzo":"CORSO CAVOUR, 31/D","citta":"VERONA"}},
        {"title":"IDEALYS SRL","acf":{"ragione_sociale":"IDEALYS SRL","indirizzo":"VIA A. MANZONI 214/D","citta":"PONTE SAN GIOVANNI"}},
        {"title":"IDEALYS SRL","acf":{"ragione_sociale":"IDEALYS SRL","indirizzo":"COSRSO VANNUCCI 88/92","citta":"PERUGIA"}},
        {"title":"IDEALYS SRL","acf":{"ragione_sociale":"IDEALYS SRL","indirizzo":"VIA GIOVAN BATTISTA VELLUTI 31 - CENTRO COMMERCIALE VAL DI CHIENTI","citta":"MACERATA"}},
        {"title":"IDEALYS SRL","acf":{"ragione_sociale":"IDEALYS SRL","indirizzo":"VIA A. GRAMSCI 34","citta":"CORCIANO"}},
        {"title":"IDEALYS SRL","acf":{"ragione_sociale":"IDEALYS SRL","indirizzo":"VIA ENZO FERRARI 5 - OUTLET VAL DI CHIANA","citta":"FOIANO DELLA CHIANA"}},
        {"title":"IDEALYS SRL","acf":{"ragione_sociale":"IDEALYS SRL","indirizzo":"VIA DELLA PALLOTTA 38","citta":"PERUGIA"}},
        {"title":"IDEALYS SRL","acf":{"ragione_sociale":"IDEALYS SRL","indirizzo":"PIAZZA CIABOTTI 6 - CENTRO COMMERCIALE PARCO ARCOBALENO","citta":"JESI"}},
        {"title":"IL PUNTO SRL","acf":{"ragione_sociale":"IL PUNTO SRL","indirizzo":"VIA MERCANTI 19","citta":"SALERNO"}},
        {"title":"INTERPROF SRL","acf":{"ragione_sociale":"INTERPROF SRL","indirizzo":"VIA BERLINGUER SNC","citta":"SAN SECONDO PARMENSE"}},
        {"title":"INTERPROF SRL","acf":{"ragione_sociale":"INTERPROF SRL","indirizzo":"VIA DELLA LIBERAZIONE 15 - CENTRO COMMERCIALE EX OFFICINE MINGANTI","citta":"BOLOGNA"}},
        {"title":"ISA DI PITTACCO ISABELLA & C. SAS","acf":{"ragione_sociale":"ISA DI PITTACCO ISABELLA & C. SAS","indirizzo":"VIA TOLMEZZO 12/A","citta":"LIGNANO SABBIADORO"}},
        {"title":"ISA DI PITTACCO ISABELLA & C. SAS","acf":{"ragione_sociale":"ISA DI PITTACCO ISABELLA & C. SAS","indirizzo":"PIAZZA INDIPENDENZA 19","citta":"LATISANA"}},
        {"title":"JEUNESSE SRL","acf":{"ragione_sociale":"JEUNESSE SRL","indirizzo":"LARGO SOTTOTENENTE POZZI 2","citta":"BUSTO ARSIZIO"}},
        {"title":"JEUNESSE SRL","acf":{"ragione_sociale":"JEUNESSE SRL","indirizzo":"VIA SAN CRISTOFORO 38","citta":"SARONNO"}},
        {"title":"JEUNESSE SRL","acf":{"ragione_sociale":"JEUNESSE SRL","indirizzo":"VIA VITTORIO EMANUELE 65","citta":"VIMERCATE"}},
        {"title":"JEUNESSE SRL","acf":{"ragione_sociale":"JEUNESSE SRL","indirizzo":"VIA MANZONI 1","citta":"COGLIATE"}},
        {"title":"JOLLY","acf":{"ragione_sociale":"JOLLY","indirizzo":"VIA ALDO MANUZIO 25","citta":"GENOVA"}},
        {"title":"JOLLY","acf":{"ragione_sociale":"JOLLY","indirizzo":"PIAZZA PAOLO DA NOVI 46","citta":"GENOVA"}},
        {"title":"KALEYA SRL","acf":{"ragione_sociale":"KALEYA SRL","indirizzo":"VIALE MONTEGRAPPA 169","citta":"PRATO"}},
        {"title":"LA MAMMOLA DI ANDREA E STEFANO CHIASTRA SNC","acf":{"ragione_sociale":"LA MAMMOLA DI ANDREA E STEFANO CHIASTRA SNC","indirizzo":"VIA GRAMSCI 4/G","citta":"PARMA"}},
        {"title":"LA MAMMOLA DI ANDREA E STEFANO CHIASTRA SNC","acf":{"ragione_sociale":"LA MAMMOLA DI ANDREA E STEFANO CHIASTRA SNC","indirizzo":"VIA EMILIA EST 140","citta":"PARMA"}},
        {"title":"LA MAMMOLA DI ANDREA E STEFANO CHIASTRA SNC","acf":{"ragione_sociale":"LA MAMMOLA DI ANDREA E STEFANO CHIASTRA SNC","indirizzo":"STRADA FARINI 7/A","citta":"PARMA"}},
        {"title":"LABORATORIO DEL DUOMO SRL","acf":{"ragione_sociale":"LABORATORIO DEL DUOMO SRL","indirizzo":"CORSO MATTEOTTI 60/C","citta":"CASTEL SAN GIOVANNI"}},
        {"title":"LABORATORIO DEL DUOMO SRL","acf":{"ragione_sociale":"LABORATORIO DEL DUOMO SRL","indirizzo":"VIALE DANTE 121","citta":"PIACENZA"}},
        {"title":"LABORATORIO DEL DUOMO SRL","acf":{"ragione_sociale":"LABORATORIO DEL DUOMO SRL","indirizzo":"VIA CAVOUR 1/F","citta":"CORTEMAGGIORE"}},
        {"title":"LABORATORIO DEL DUOMO SRL","acf":{"ragione_sociale":"LABORATORIO DEL DUOMO SRL","indirizzo":"VIA VINCENZO MONTI 56","citta":"MILANO"}},
        {"title":"LABORATORIO DEL DUOMO SRL","acf":{"ragione_sociale":"LABORATORIO DEL DUOMO SRL","indirizzo":"VIA SAN VITTORE 23","citta":"VERBANIA"}},
        {"title":"LABORATORIO DEL DUOMO SRL","acf":{"ragione_sociale":"LABORATORIO DEL DUOMO SRL","indirizzo":"VIA ALDO MORO 3 - CENTRO COMMERCIALE IPER SIMPLY","citta":"CODOGNO"}},
        {"title":"LADURNER JOACHIM & CO. SAS","acf":{"ragione_sociale":"LADURNER JOACHIM & CO. SAS","indirizzo":"VIA PORTICI 313","citta":"MERANO"}},
        {"title":"LE PARFUM SRL","acf":{"ragione_sociale":"LE PARFUM SRL","indirizzo":"VIA ARENACCIA 130/136A","citta":"NAPOLI"}},
        {"title":"LE PARFUM SRL","acf":{"ragione_sociale":"LE PARFUM SRL","indirizzo":"CORSO GARIBALDI 215/216","citta":"NAPOLI"}},
        {"title":"LE PARFUM SRL","acf":{"ragione_sociale":"LE PARFUM SRL","indirizzo":"VIA SAN GIOVANNI 7/9","citta":"CASERTA"}},
        {"title":"LE PARFUM SRL","acf":{"ragione_sociale":"LE PARFUM SRL","indirizzo":"VIA SAN SALVATORE CASORIA - CENTRO COMMERCIALE CASORIA PARK","citta":"CASORIA"}},
        {"title":"LE PARFUM SRL","acf":{"ragione_sociale":"LE PARFUM SRL","indirizzo":"VIA NAZIONALE APPIA 5 - CENTRO COMMERCIALE APOLLO","citta":"CASAPULLA"}},
        {"title":"LE PROFUMERIE DI BALOCCHI M. E L. SRL","acf":{"ragione_sociale":"LE PROFUMERIE DI BALOCCHI M. E L. SRL","indirizzo":"VIA ALFREDO FAGGI 62","citta":"PESARO"}},
        {"title":"LE PROFUMERIE VACCARI","acf":{"ragione_sociale":"LE PROFUMERIE VACCARI","indirizzo":"VIA LARGA int. 45/46 - CENTRO COMMERCIALE VIA LARGA","citta":"BOLOGNA"}},
        {"title":"LE PROFUMERIE VACCARI","acf":{"ragione_sociale":"LE PROFUMERIE VACCARI","indirizzo":"VIALE INDUSTRIA 31 INT. 11  - CENTRO COMMERCIALE BORGOGIOIOSO","citta":"CARPI"}},
        {"title":"LE PROFUMERIE VACCARI","acf":{"ragione_sociale":"LE PROFUMERIE VACCARI","indirizzo":"VIA MARILYN MONROE 29 INT. 30 - CENTRO COMMERCIALE GRAN RENO","citta":"CASALECCHIO DI RENO"}},
        {"title":"LE PROFUMERIE VACCARI","acf":{"ragione_sociale":"LE PROFUMERIE VACCARI","indirizzo":"VIA GIUSTI 4/64 - CENTRO COMMERCIALE IL CASTELLO","citta":"FERRARA"}},
        {"title":"LE PROFUMERIE VACCARI","acf":{"ragione_sociale":"LE PROFUMERIE VACCARI","indirizzo":"VIALE DELLO SPORT 50/13  - CENTRO COMMERCIALE I PORTALI","citta":"MODENA"}},
        {"title":"LE PROFUMERIE VACCARI","acf":{"ragione_sociale":"LE PROFUMERIE VACCARI","indirizzo":"VIA MORANE 500 INT. 30  - CENTRO COMMERCIALE LA ROTONDA","citta":"MODENA"}},
        {"title":"LE PROFUMERIE VACCARI","acf":{"ragione_sociale":"LE PROFUMERIE VACCARI","indirizzo":"VIA MUTILATI DEL LAVORO 96 INT. 8 - CENTRO COMMERCIALE CITTA' DELLE STELLE","citta":"CAMPOLUNGO"}},
        {"title":"LE PROFUMERIE VACCARI","acf":{"ragione_sociale":"LE PROFUMERIE VACCARI","indirizzo":"VIA EMILIA OVEST 1480/76  - CENTRO COMMERCIALE GRANDEMILIA","citta":"MODENA"}},
        {"title":"LE PROFUMERIE VACCARI","acf":{"ragione_sociale":"LE PROFUMERIE VACCARI","indirizzo":"VIA EMILIA 67","citta":"MODENA"}},
        {"title":"LE PROFUMERIE VACCARI","acf":{"ragione_sociale":"LE PROFUMERIE VACCARI","indirizzo":"VIA TRENTO TRIESTE 51","citta":"MODENA"}},
        {"title":"LE PROFUMERIE VACCARI","acf":{"ragione_sociale":"LE PROFUMERIE VACCARI","indirizzo":"VIA SESTINI ANG VIA BURE VECCHIA INT.3 - CENTRO COMMERCIALE PANORAMA","citta":"PISTOIA"}},
        {"title":"LE PROFUMERIE VACCARI","acf":{"ragione_sociale":"LE PROFUMERIE VACCARI","indirizzo":"VIA PRATI GUORI 29 - CENTRO COMMERCIALE ADRIATICO","citta":"PORTOGRUARO"}},
        {"title":"LE PROFUMERIE VACCARI","acf":{"ragione_sociale":"LE PROFUMERIE VACCARI","indirizzo":"VIA KENNEDY 29/Q - CENTRO COMMERCIALE LA MERIDIANA","citta":"REGGIO EMILIA"}},
        {"title":"LE PROFUMERIE VACCARI","acf":{"ragione_sociale":"LE PROFUMERIE VACCARI","indirizzo":"VIA ARCHIMEDE 9  - CENTRO COMMERCIALE PANORAMA","citta":"SASSUOLO"}},
        {"title":"LE PROFUMERIE VACCARI","acf":{"ragione_sociale":"LE PROFUMERIE VACCARI","indirizzo":"VIA GRAZZI SONCINI 36 - CENTRO COMMERCIALE IL PARCO","citta":"VIADANA"}},
        {"title":"LEAM SRL","acf":{"ragione_sociale":"LEAM SRL","indirizzo":"VIALE MATTEOTTI 31","citta":"CUSANO MILANINO"}},
        {"title":"LEAM SRL","acf":{"ragione_sociale":"LEAM SRL","indirizzo":"VIA PIERO DELLA FRANCESCA 5","citta":"MILANO"}},
        {"title":"L'ISOLA DELLA BELLEZZA SRL","acf":{"ragione_sociale":"L'ISOLA DELLA BELLEZZA SRL","indirizzo":"VIA CONVENTO 5/7","citta":"PATERNO'"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"STRADA PROVINCIALE 208 KM 2 - CENTRO COMMERCIALE CAROSELLO","citta":"CARUGATE"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"VIA SAN PIETRO 1 - CENTRO COMMERCIALE CASCINA LEVADA","citta":"CASATENOVO"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"VIA BENEDETTO CROCE 2 - CENTRO COMMERCIALE AUCHAN","citta":"CESANO BOSCONE"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"VIA GIACOMO QUARENGHI 23  - CENTRO COMMERCIALE BONOLA","citta":"MILANO"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"CORSO GARIBALDI 2","citta":"MILANO"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"CORSO SAN GOTTARDO 11","citta":"MILANO"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"VIA TORINO 56","citta":"MILANO"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"VIA TOGLIATTI 1 - CENTRO COMMERCIALE AUCHAN","citta":"RESCALDINA"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"VIA CURIEL 25 - CENTRO COMMERCIALE FIORDALISO","citta":"ROZZANO"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"VIALE ITALIA 555 - CENTRO COMMERCIALE VULCANO","citta":"SESTO SAN GIOVANNI"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"VIA PER MILANO 3 - CENTRO COMMERCIALE BENNET","citta":"CORNAREDO"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"VIA PREALPI 41 - CENTRO COMMERCIALE GRAN GIUSSANO","citta":"GIUSSANO"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"PIAZZA GANDHI 6- CENTRO COMMERCIALE BENNET","citta":"SEDRIANO"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"VIALE CHIESE 60 - CENTRO COMMERCIALE BICOCCA VILLAGE","citta":"MILANO"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"VIALE BLIGNY 43","citta":"MILANO"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"VIA DELLA MOSCOVA 44","citta":"MILANO"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"VIA CAVOUR 74","citta":"CORSICO"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"VIA PORDENONE 34","citta":"MILANO"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"CORSO DI PORTA VITTORIA 40","citta":"MILANO"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"CORSO ITALIA 41","citta":"SARONNO"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"VIA CAVOUR 6","citta":"VARESE"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"VIA TRIBONIANO 256 - CC CASCINA MERLATA BLOOM","citta":"MILANO"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"PIAZZA NAPOLI 33","citta":"MILANO"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"VIA LORENTEGGIO 22","citta":"MILANO"}},
        {"title":"LIVELY SRL","acf":{"ragione_sociale":"LIVELY SRL","indirizzo":"VIA OSTI 10 > PORTA ROMANA","citta":"MILANO"}},
        {"title":"MABEL PROFUMERIE SRL","acf":{"ragione_sociale":"MABEL PROFUMERIE SRL","indirizzo":"VIA VITTOR PISANI 19","citta":"MILANO"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA ROMA 169","citta":"RAGUSA"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA TISIA 80/82","citta":"SIRACUSA"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA MONS. CARABELLI 6/8/10","citta":"SIRACUSA"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"CORSO MATTEOTTI 15","citta":"SIRACUSA"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA LUNGOMARE ANDREA DORIA 30","citta":"MARINA DI RAGUSA"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA NAPOLI 80","citta":"SCOGLITTI"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA NAXOS 233","citta":"GIARDINI NAXOS"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA CAVOUR 323","citta":"VITTORIA"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA SACRO CUORE 4","citta":"MODICA"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA UMBERTO I 60","citta":"COMISO"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"CORSO VITTORIO EMANUELE 417","citta":"GELA"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA ARCHIMEDE 137","citta":"RAGUSA"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"CORSO ITALIA 39","citta":"ACIREALE"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA CATIRA SANTA LUCIA - CENTRO COMMERCIALE I PORTALI","citta":"SAN GIOVANNI LA PUNTA"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIALE VITTORIO VENETO 102","citta":"CATANIA"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIALE PRINCIPE UMBERTO 131","citta":"CALTAGIRONE"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA FILIPPO CORDOVA 65","citta":"PALERMO"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA LEONARDO DA VINCI 147","citta":"PALERMO"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIALE STRASBURGO 243","citta":"PALERMO"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA G. ARCOLEO 24","citta":"PALERMO"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA AUSONIA 152","citta":"PALERMO"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA VINCENZO BARBERA 14","citta":"PALERMO"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"CORSO VITTORIO EMANUELE 52","citta":"SCIACCA"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA CADUTI DI NASSIRYA - CENTRO COMMERCIALE BELICITTA'","citta":"CASTELVETRANO"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA DANTE 8","citta":"BAGHERIA"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"CORSO ITALIA 5","citta":"CATANIA"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA ROMA 29","citta":"MARSALA"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"CONTRADA BIGINI SP6 - CENTRO COMMERCIALE IL CASALE","citta":"SAN CATALDO"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA XX SETTEMBRE, 54","citta":"PALERMO"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA SCIUTI 30","citta":"PALERMO"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA DELLA PALOMBELLA 30","citta":"ROMA"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIALE LIBIA 140/142","citta":"ROMA"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA TUSCOLANA 861","citta":"ROMA"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA RUGGIERO SETTIMO 14","citta":"PALERMO"}},
        {"title":"MABU'","acf":{"ragione_sociale":"MABU'","indirizzo":"VIA BALDOVINETTI 56","citta":"ROMA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"CORSO ITALIA 217","citta":"AREZZO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"CORSO CAVOUR 98/100","citta":"ARONA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"CORSO ALFIERI 339","citta":"ASTI"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"CORSO LAGHI 84 - CENTRO COMMERCIALE LE TORRI","citta":"AVIGLIANA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA MATTEOTTI 24","citta":"BELLUNO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA LEONARDO DA VINCI 36/40","citta":"BORGO SAN LORENZO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"PIAZZA MORA E GIBIN 9","citta":"BORGOMANERO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIALE LOMBARDIA 264 - CENTRO COMMERCIALE BENNET","citta":"BRUGHERIO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"PIAZZA GRAMSCI 39","citta":"CASTELFIORENTINO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"CORSO MATTEOTTI 42","citta":"CECINA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA ASSANO - CENTRO COMMERCIALE MONTEFIORE","citta":"CESENA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA GARIBALDI 35","citta":"COMO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA ROMA 78 - CENTRO COMMERCIALE LE TORBIERE","citta":"TIMOLINE DI CORTEFRANCA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"STRADA STATALE DEL SEMPIONE - CENTRO COMMERCIALE VALDOSSOLA","citta":"CREVOLADOSSOLA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA NAZIONALE 43 - CENTRO COMMERCIALE ADAMELLO","citta":"DARFO BOARIO TERME"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"CORSO FRATELLI DI DIO 3","citta":"DOMODOSSOLA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"PIAZZA MERCATO 35","citta":"DOMODOSSOLA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA NOSERE 33 - CENTRO COMMERCIALE SEMPIONE","citta":"DOMODOSSOLA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA DEL GIGLIO 6","citta":"EMPOLI"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"PIAZZA GRAMSCI 26","citta":"EMPOLI"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA ROVATO 44 - CENTRO COMMERCIALE LE PORTE FRANCHE","citta":"ERBUSCO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA CIMABUE 29/R","citta":"FIRENZE"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIALE FRANCESCO TALENTI 100","citta":"FIRENZE"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA CAVOUR 104","citta":"FIRENZE"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA DEGLI AVIATORI 126 - CENTRO COMMERCIALE LA MONGOLFIERA","citta":"FOGGIA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"CORSO MATTEOTTI 55","citta":"FUCECCHIO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA LARIO 37 - CENTRO COMMERCIALE MALPENSA UNO","citta":"GALLARATE"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIALE TICINO 82 - CENTRO COMMERCIALE CAMPO DEI FIORI","citta":"GAVIRATE"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA AURELIA NORD 68","citta":"GROSSETO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA SILVIO BONFANTE 27","citta":"IMPERIA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA VITTORIO VENETO 167","citta":"LA SPEZIA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIALE EUROPA","citta":"LUCCA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA FRATELLI ROSSELLI 46/48","citta":"LASTRA A SIGNA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA CAVOUR 3","citta":"LECCO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"PIAZZA DEL MERCATO 20","citta":"MARGHERA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA GINO ALLEGRI 13","citta":"MESTRE"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA MICHELANGELO BUONARROTI 5","citta":"MILANO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA PAOLO SARPI 35","citta":"MILANO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"PIAZZA UDINE 8","citta":"MILANO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA G. DA MILANO 7","citta":"MILANO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA GIUSEPPE MUSSI 11","citta":"MILANO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA MORGANTINI 19","citta":"MILANO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"CORSO MAGENTA 2","citta":"MILANO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA ROMA 101","citta":"MONTEVARCHI"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA FRATELLI ROSSELLI 14/A","citta":"NOVARA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA OVADA - CENTRO COMMERCIALE BENNET","citta":"NOVI LIGURE"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"PIAZZA NOBILI DE TOMA","citta":"OMEGNA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"STRADA STATALE DEI GIOVI 35 - CENTRO COMMERCIALE BRIANZA CARREFOUR","citta":"PADERNO DUGNANO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIALE NAZIONI UNITE 1 - CENTRO COMMERCIALE BENNET","citta":"PIEVE FISSIRAGA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA VENEZIA GIULIA - CENTRO COMMERCIALE PISANOVA","citta":"PISA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIALE ADUA 133","citta":"PISTOIA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"LARGO BELLUCCI 12","citta":"POGGIBONSI"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA ARETINA 4","citta":"PONTASSIEVE"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"CORSO MATTEOTTI 17","citta":"PONTEDERA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA DEL GALLITELLO 73","citta":"POTENZA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"PIAZZA RISORGIMENTO 24","citta":"QUARRATA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA NIZZA 5 - CENTRO COMMERCIALE LA PIAZZETTA","citta":"RIVOLI"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA COLLATINA 858 - CENTRO COMMERCIALE ROMA EST","citta":"ROMA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA BRIGATA ALPINA TAURINENSE 1  - CENTRO COMMERCIALE BENNET","citta":"ROMAGNANO SESIA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"CORSO ITALIA 202","citta":"SAN GIOVANNI VALDARNO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA EMILIA 292/659 - CENTRO COMMERCIALE BENNET","citta":"SAN MARTINO IN STRADA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA DEI MONTEFELTRO 1/C - CENTRO COMMERCIALE VALTIBERINO","citta":"SANSEPOLCRO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA DEL MURELLO > VAR. AURELIA","citta":"SARZANA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA VARIANTE CISA 40 - CENTRO COMMERCIALE CENTROLUNA","citta":"SARZANA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA DONIZETTI 20","citta":"SCANDICCI"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA POGGILUPPI 456","citta":"TERRANUOVA BRACCIOLINI"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA DUCHESSA JOLANDA 6","citta":"TORINO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"STRADA STATALE 32 TICINESE - CENTRO COMMERCIALE IL GIGANTE","citta":"VARALLO POMBIA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"SAN LIO CASTELLO SALIZADA","citta":"VENEZIA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA SAN VITTORE 59","citta":"VERBANIA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"PIAZZA MATTEOTTI 27","citta":"INTRA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA STELLA 21","citta":"VERONA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"PIAZZA DUCALE 41/42","citta":"VIGEVANO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"REGIONE TORAME - CENTRO COMMERCIALE IL RONDO'","citta":"BORGOSESIA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"STRADA PROVINCIALE 34 - CENTRO COMMERCIALE IL GIGANTE","citta":"CASTANO PRIMO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"STRADA PROVINCIALE 34 - CENTRO COMMERCIALE IL GIGANTE","citta":"CASTANO PRIMO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"PIAZZA DELLA BORSA PALAZZO DEL TERGESTEO","citta":"TRIESTE"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA LA ROSA 354 - CENTRO COMMERCIALE FUENTES","citta":"PIANTEDO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA RISORGIMENTO","citta":"CASSINA RIZZARDI"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"PIAZZA DELLA RADIO 25 > VIALE MARCONI","citta":"ROMA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA DEL COMMERCIO 3 - CENTRO COMMERCIALE SHOPPING CENTER","citta":"ANTEGNATE"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA GUZZANICA 62 - CENTRO COMMERCIALE LE DUE TORRI","citta":"STEZZANO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA SORAGANA 1 - CENTRO COMMERCIALE IL GIGANTE","citta":"SOMMA LOMBARDO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA AMOROTTI 35","citta":"FOLLONICA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"CORSO XXII MARZO 8","citta":"MILANO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA SOMMARIVA 29/1 - CENTRO COMMERCIALE CARMAGNOLA","citta":"CARMAGNOLA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"PIAZZA DELLE FONTANE MAROSE 4R/7R/9R","citta":"GENOVA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"STRADA STATALE415 PAULLESE - CENTRO COMMERCIALE GALLERIA BORROMEA","citta":"PESCHIERA BORROMEO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"STRADA STATALE 9 - CENTRO COMMERCIALE BELPO'","citta":"SAN ROCCO AL PORTO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA F. DI STRAMARE 119/E - CENTRO COMMERCIALE MONTEDORO","citta":"MUGGIA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA PISTOIESE 69","citta":"MONTECATINI TERME"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"CORSO MONTECUCCO 108 - CENTRO COMMERCIALE CARREFOUR","citta":"TORINO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA LIBERTA' 231 - CENTRO COMMERCIALE BENNET AIRONE","citta":"BELLINZAGO NOVARESE"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"CORSO EUROPA 23 - CENTRO COMMERCIALE CANTU' 2000","citta":"CANTU'"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA UMBERTO TERRACINI 1/3","citta":"PONTEDERA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA RAFFAELLO 199 - CENTRO COMMERCIALE IPERCOOP","citta":"EMPOLI"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"TANGENZIALE SUD 13  - CENTRO COMMERCIALE CARREFOUR","citta":"VERCELLI"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIUZZO DELLE CASE NUOVE 9 - CENTRO COMMERCIALE PONTE A GREVE","citta":"FIRENZE"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIALE G. PUCCINI 1718/B - CENTRO COMMERCIALE PUCCINI","citta":"LUCCA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA EMILIA 2 - CENTRO COMMERCIALE SAN GRATO","citta":"MONTANASO LOMBARDO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"STRADA STATALE 494 VIA CASE SPARSE - CENTRO COMMERCIALE PARONA","citta":"PARONA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"PIAZZA TRENTIN 1 - CENTRO COMMERCIALE PIAZZA PARADISO","citta":"COLLEGNO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"CORSO INDIPENDENZA 74 - CENTRO COMMERCALE URBAN CENTER","citta":"RIVAROLO CANAVESE"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA DIAZ 7 - CENTRO COMMERCIALE IL GIGANTE","citta":"CASTELLANZA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA NAZIONALE STELVIO 29 - CENTRO COMMERCIALE IPERAL","citta":"CASTIONE ANDEVENNO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA BORGO GIANNOTTI 230","citta":"LUCCA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA G. ROMANA 40/42 - CENTRO COMMERCIALE BENNET","citta":"CASTELLAMONTE"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA MADRE TERESA DI CALCUTTA - CENTRO COMMERCIALE LA CITTADELLA","citta":"CASALE MONFERRATO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA TRENTINO 1 - CENTRO COMMERCIALE LA GRANDE MELA","citta":"LUGAGNANO DI SONA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA STUPINIGI 182 - CENTRO COMMERCIALE MONDO JUVE","citta":"VINOVO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA VECELLIO - CENTRO COMMERCIALE IL GIGANTE","citta":"VILLASANTA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA CAPITELVECCHIO 88 - CENTRO COMMERCIALE IL GRIFONE","citta":"BASSANO DEL GRAPPA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA PER CALUSO - CENTRO COMMERCIALE BENNET","citta":"CHIVASSO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA BISCOLLA 48 - CENTRO COMMERCIALE MONTECATINI","citta":"MASSA E COZZILE"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIALE ABRUZZI 89","citta":"MILANO"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"PIAZZA CAVALLI","citta":"PIACENZA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"PIAZZA DALMAZIA 37","citta":"FIRENZE"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"VIA DEI MARTIRI 17","citta":"ALESSANDRIA"}},
        {"title":"MARIONNNAUD","acf":{"ragione_sociale":"MARIONNNAUD","indirizzo":"PIAZZETTA DEL GRANO","citta":"NOALE"}},
        {"title":"MARKET JOSEF","acf":{"ragione_sociale":"MARKET JOSEF","indirizzo":"PIAZZA DEL TEATRO 13","citta":"MERANO"}},
        {"title":"MAZZOLARI","acf":{"ragione_sociale":"MAZZOLARI","indirizzo":"VIA CARLO FARINI 73","citta":"MILANO"}},
        {"title":"MAZZOLARI","acf":{"ragione_sociale":"MAZZOLARI","indirizzo":"PIAZZETTA UMBERTO GIORDANO 4","citta":"MILANO"}},
        {"title":"MULIERE MARIO SRL","acf":{"ragione_sociale":"MULIERE MARIO SRL","indirizzo":"VIA G. CHIABRERA 9","citta":"ROMA"}},
        {"title":"MUZIO PROFUMI SRL","acf":{"ragione_sociale":"MUZIO PROFUMI SRL","indirizzo":"VIALE SOMALIA 86-88","citta":"ROMA"}},
        {"title":"MUZIO PROFUMI SRL","acf":{"ragione_sociale":"MUZIO PROFUMI SRL","indirizzo":"VIA FLAMINIA VECCHIA 648","citta":"ROMA"}},
        {"title":"MUZIO PROFUMI SRL","acf":{"ragione_sociale":"MUZIO PROFUMI SRL","indirizzo":"VIA VAL D'OSSOLA 60","citta":"ROMA"}},
        {"title":"MUZIO PROFUMI SRL","acf":{"ragione_sociale":"MUZIO PROFUMI SRL","indirizzo":"VIALE JONIO 363","citta":"ROMA"}},
        {"title":"MUZIO PROFUMI SRL","acf":{"ragione_sociale":"MUZIO PROFUMI SRL","indirizzo":"VIA FRANCESCO SAVERIO NITTI 36","citta":"ROMA"}},
        {"title":"NAIMA ROMA SRL","acf":{"ragione_sociale":"NAIMA ROMA SRL","indirizzo":"VIA CANDIA 31","citta":"ROMA"}},
        {"title":"NAIMA ROMA SRL","acf":{"ragione_sociale":"NAIMA ROMA SRL","indirizzo":"VIA UGO OJETTI 386","citta":"ROMA"}},
        {"title":"NAIMA ROMA SRL","acf":{"ragione_sociale":"NAIMA ROMA SRL","indirizzo":"VIA APPIA 10-12","citta":"ROMA"}},
        {"title":"NAIMA ROMA SRL","acf":{"ragione_sociale":"NAIMA ROMA SRL","indirizzo":"VIA VAGLIA 33","citta":"ROMA"}},
        {"title":"NAIMA ROMA SRL","acf":{"ragione_sociale":"NAIMA ROMA SRL","indirizzo":"VIA TUSCOLANA 793 B","citta":"ROMA"}},
        {"title":"NAIMA ROMA SRL","acf":{"ragione_sociale":"NAIMA ROMA SRL","indirizzo":"VIALE DEI COLLI PORTUENSI 570","citta":"ROMA"}},
        {"title":"NAIMA ROMA SRL","acf":{"ragione_sociale":"NAIMA ROMA SRL","indirizzo":"VIA MARMORATA 75","citta":"ROMA"}},
        {"title":"NAIMA ROMA SRL","acf":{"ragione_sociale":"NAIMA ROMA SRL","indirizzo":"VIA CONCA D'ORO 293/301","citta":"ROMA"}},
        {"title":"NAIMA ROMA SRL","acf":{"ragione_sociale":"NAIMA ROMA SRL","indirizzo":"VIA CANDIA 67-67/A","citta":"ROMA"}},
        {"title":"NAIMA ROMA SRL","acf":{"ragione_sociale":"NAIMA ROMA SRL","indirizzo":"VIALE ERITREA 89 G-F","citta":"ROMA"}},
        {"title":"NAIMA ROMA SRL","acf":{"ragione_sociale":"NAIMA ROMA SRL","indirizzo":"CORSO MATTEOTTI 189","citta":"ALBANO LAZIALE"}},
        {"title":"NAIMA ROMA SRL","acf":{"ragione_sociale":"NAIMA ROMA SRL","indirizzo":"VIA PRINCIPE EUGENIO 24/26","citta":"ROMA"}},
        {"title":"NAIMA ROMA SRL","acf":{"ragione_sociale":"NAIMA ROMA SRL","indirizzo":"VIA UGO OJETTI 191","citta":"ROMA"}},
        {"title":"NAIMA ROMA SRL","acf":{"ragione_sociale":"NAIMA ROMA SRL","indirizzo":"VIALE EUROPA 99/105","citta":"ROMA"}},
        {"title":"NAIMA ROMA SRL","acf":{"ragione_sociale":"NAIMA ROMA SRL","indirizzo":"PIAZZA ESCHILO 72","citta":"ROMA"}},
        {"title":"NAIMA ROMA SRL","acf":{"ragione_sociale":"NAIMA ROMA SRL","indirizzo":"VIA DEL CORSO 39","citta":"ROMA"}},
        {"title":"NAIMA ROMA SRL","acf":{"ragione_sociale":"NAIMA ROMA SRL","indirizzo":"VIA T.TITTONI, 62","citta":"SAN FELICE CIRCEO"}},
        {"title":"NAIMA ROMA SRL","acf":{"ragione_sociale":"NAIMA ROMA SRL","indirizzo":"VIA PAOLO ORLANDO 10","citta":"OSTIA LIDO"}},
        {"title":"NAIMA ROMA SRL","acf":{"ragione_sociale":"NAIMA ROMA SRL","indirizzo":"VIA BOCCEA, 153","citta":"ROMA"}},
        {"title":"NUUR SRL","acf":{"ragione_sociale":"NUUR SRL","indirizzo":"VIALE ALFA ROMEO 113","citta":"POMIGLIANO D'ARCO"}},
        {"title":"PARFUMERIE SRL","acf":{"ragione_sociale":"PARFUMERIE SRL","indirizzo":"VIA GARIBALDI 21","citta":"AVERSA"}},
        {"title":"PARFUMERIE SRL","acf":{"ragione_sociale":"PARFUMERIE SRL","indirizzo":"CORSO VITTORIO EMANUELE 25","citta":"SAN GIUSEPPE VESUVIANO"}},
        {"title":"PARFUMERIE SRL","acf":{"ragione_sociale":"PARFUMERIE SRL","indirizzo":"VIALE LEONARDO DA VINCI 189","citta":"PORTICI"}},
        {"title":"PARFUMERIE SRL","acf":{"ragione_sociale":"PARFUMERIE SRL","indirizzo":"VIA BARBARULO 39","citta":"NOCERA INFERIORE"}},
        {"title":"PARFUMERIE SRL","acf":{"ragione_sociale":"PARFUMERIE SRL","indirizzo":"STRADA PROVINCIALE TRENTOLA PARETE 22 - CENTRO COMMERCIALE JAMBO 1","citta":"TRENTOLA DUCENTA"}},
        {"title":"PASSAMONTE SRL","acf":{"ragione_sociale":"PASSAMONTE SRL","indirizzo":"VIA PRENESTINA 421/A","citta":"ROMA"}},
        {"title":"PASSAMONTE SRL","acf":{"ragione_sociale":"PASSAMONTE SRL","indirizzo":"VIA RAUL CHIODELLI 186","citta":"ROMA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA FRATELLI ROSSELLI 22","citta":"NOVARA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA MARTIRI DELLA LIBERAZIONE 72","citta":"CHIAVARI"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA BRIGATA PARTIGIANA UGO MUCCINI 9","citta":"SARZANA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"CORSO D'AUGUSTO 130","citta":"RIMINI"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"CORSO ALBERTO PIO 79","citta":"CARPI"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA MAZZINI 86","citta":"CREMA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA SOLFERINO 12","citta":"CREMONA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"CORSO GARIBALDI 59","citta":"FIORENZUOLA D'ARDA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"PIAZZA DELLA VITTORIA 29","citta":"LODI"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA EMILIA CENTRO 241","citta":"MODENA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA VITTORIO EMANUELE 8","citta":"MONZA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA XX SETTEMBRE 135","citta":"PIACENZA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA EMILIA PARMENSE 153 - CENTRO COMMERCIALE GOTICO","citta":"PIACENZA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"CORSO VITTORIO EMANUELE II 2","citta":"PIACENZA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA ROMA 5","citta":"TREVIGLIO"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA GARIBALDI 1","citta":"VIGNOLA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"CORSO SOZZI 2","citta":"CESENA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA CAVOUR 73","citta":"FIDENZA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA MAZZINI 22","citta":"IMOLA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"CORSO CAVOUR 102","citta":"LA SPEZIA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"CORSO CAVOUR 9","citta":"PAVIA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA EMILIA 30","citta":"VOGHERA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"CORSO DELLA REPUBBLICA 12","citta":"FORLI'"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"PIAZZA GHIAIA 43/A","citta":"PARMA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA ROCCO BORMIOLI 33 > PIAZZA BALESTRIERI - CENTRO COMMERCIALE EURO TORRI","citta":"PARMA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"PIAZZA MATTEOTTI 19/20","citta":"BERGAMO"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA STRADA REGIA 4 - CENTRO COMMERCIALE CONTINENTE","citta":"MAPELLO"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA GIRARDENGO 54","citta":"NOVI LIGURE"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"PIAZZA GARIBALDI 7","citta":"SASSUOLO"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"PIAZZA CONCORDIA 1","citta":"SEREGNO"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"STRANA PADANA VERSO PADOVA 162","citta":"VICENZA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"CORSO PALLADIO 92","citta":"VICENZA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA GIOCACCHINO ROSSINI 40","citta":"PESARO"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA INDIPENDENZA 170","citta":"MEDA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA OSS MAZZURANA 10","citta":"TRENTO"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA DEI TERMINI 97/99","citta":"SIENA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA SEBASTIANO GRANDIS 5","citta":"CUNEO"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA XX SETTEMBRE 3","citta":"TREVISO"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"PIAZZA GARIBALDI 21","citta":"PARMA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA PADANA SUPERIORE 154 - CENTRO COMMERCIALE LA CORTE LOMBARDA","citta":"BELLINZAGO LOMBARDO"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA SANTA BARBARA - CENTRO COMMERCIALE GLI ORSI","citta":"BIELLA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA ITALIA 32","citta":"BIELLA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA ITALIA 197 - CENTRO COMMERCIALE IL GLOBO","citta":"BUSNAGO"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA GENERAL LAUGER - CENTRO COMMERCIALE QUATTRO VENTI","citta":"CURTATONE"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"CORSO ITALIA 123/125","citta":"PISA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA NOVI 39","citta":"SERRAVALLE"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"CORSO LIBERTA' 104","citta":"VERCELLI"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA MATTEI 52 - CENTRO COMMERCIALE VALECENTER","citta":"MARCON"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"CORSO STATUTO 26","citta":"MONDOVI'"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA DEL COMMERCIO 3 - CENTRO COMMERCIALE SHOPPING CENTER","citta":"ANTEGNATE"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA NICCOLO' PAGANINI - SETTIMO CIELO RETAIL PARK","citta":"SETTIMO TORINESE"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA PORTICO 71 - CENTRO COMMERCIALE ORIO CENTER","citta":"ORIO AL SERIO"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA FARINI, 11/13","citta":"BOLOGNA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"CORSO AGOSTINO RICCI 49 - CENTRO COMMERCIALE IL GABBIANO","citta":"SAVONA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIALE CARDUCCI 6","citta":"CONEGLIANO"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA ANTONIO MEUCCI","citta":"BARBERINO DEL MUGELLO"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA FEDERICO FELLINI 1 - FIDENZA VILLAGE","citta":"FIDENZA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"PIAZZA GUGLIELMO MARCONI 25","citta":"MANTOVA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"PIAZZA SANTA CATERINA 1 - CC VICOLUNGO OUTLET","citta":"VICOLUNGO"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"CORSO SILVIO TRENTIN 78","citta":"SAN DONA' DI PIAVE"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"CORSO SANT'ANASTASIA 6","citta":"VERONA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA M. BUSSATO 74 - CENTRO COMMERCIALE ESP","citta":"RAVENNA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA ROMA 52","citta":"LEGNAGO"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIALE DELLA VITTORIA 281","citta":"VITTORIO VENETO"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA ENZO FERRARI 5 - OUTLET VAL DI CHIANA","citta":"FOIANO DELLA CHIANA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA COPPARO 132 - CENTRO COMMERCIALE LE MURA","citta":"FERRARA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"PIAZZA CAMILLO CAVOUR 1","citta":"VERCELLI"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA MARCO BIAGI - MANTOVA VILLAGE","citta":"BAGNOLO SAN VITO"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA MILANO 5 - OUTLET SCALO MILANO","citta":"LOCATE DI TRIULZI"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA MAESTRI DEL LAVORO 42 - CC GRANFIUME","citta":"PORDENONE"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA PIETRO ARDUINO 20 - CC NAVE DE VERO","citta":"MARGHERA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"CORSO MAGENTA 33","citta":"BRESCIA"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"CORSO BUENOS AIRES","citta":"MILANO"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"VIA ROMA 49/51","citta":"CASALE MONFERRATO"}},
        {"title":"PINALLI","acf":{"ragione_sociale":"PINALLI","indirizzo":"PIAZZA CASCINA MOIE 1/2 - FRANCIACORTA OUTLET VILLAGE","citta":"RODENGO SAIANO"}},
        {"title":"POWER OF BEAUTY SRL","acf":{"ragione_sociale":"POWER OF BEAUTY SRL","indirizzo":"VIALE DEI PARIOLI 9","citta":"ROMA"}},
        {"title":"PRINCIPATO & MATERIA SRL","acf":{"ragione_sociale":"PRINCIPATO & MATERIA SRL","indirizzo":"VIA MANZONI 35 - PALAZZO FORMICA","citta":"MILAZZO"}},
        {"title":"PRINCIPATO & MATERIA SRL","acf":{"ragione_sociale":"PRINCIPATO & MATERIA SRL","indirizzo":"VIA KENNEDY 369","citta":"BARCELLONA POZZO DI GOTTO"}},
        {"title":"PROFUMANIA SRL","acf":{"ragione_sociale":"PROFUMANIA SRL","indirizzo":"VIA ROMA 208","citta":"GRUMELLO DEL MONTE"}},
        {"title":"PROFUMANIA SRL","acf":{"ragione_sociale":"PROFUMANIA SRL","indirizzo":"VIALE ITALIA 2/A","citta":"VILLONGO"}},
        {"title":"PROFUMERIA ALINE SRL","acf":{"ragione_sociale":"PROFUMERIA ALINE SRL","indirizzo":"CORSO GARIBALDI 40","citta":"BARLETTA"}},
        {"title":"PROFUMERIA ANELLI SNC","acf":{"ragione_sociale":"PROFUMERIA ANELLI SNC","indirizzo":"VIALE COMMENDA 146-148","citta":"BRINDISI"}},
        {"title":"PROFUMERIA ANELLI SNC","acf":{"ragione_sociale":"PROFUMERIA ANELLI SNC","indirizzo":"CORSO GARIBALDI 25","citta":"BRINDISI"}},
        {"title":"PROFUMERIA ASTESANI SRL","acf":{"ragione_sociale":"PROFUMERIA ASTESANI SRL","indirizzo":"VIA PELLEGRINO ROSSI 26","citta":"MILANO"}},
        {"title":"PROFUMERIA CAMPANA SRL","acf":{"ragione_sociale":"PROFUMERIA CAMPANA SRL","indirizzo":"P.ZZA MUNICIPIO 3","citta":"CASTROVILLARI"}},
        {"title":"PROFUMERIA CAMPANA SRL","acf":{"ragione_sociale":"PROFUMERIA CAMPANA SRL","indirizzo":"VIALE MICHELANGELO 14","citta":"ROSSANO"}},
        {"title":"PROFUMERIA CURTI SRL","acf":{"ragione_sociale":"PROFUMERIA CURTI SRL","indirizzo":"VIA DELLA SAPIENZA 11","citta":"VITERBO"}},
        {"title":"PROFUMERIA DEEP SRL","acf":{"ragione_sociale":"PROFUMERIA DEEP SRL","indirizzo":"VIA SAN FRANCESCO 155","citta":"FRANCAVILLA FONTANA"}},
        {"title":"PROFUMERIA DEI F.LLI NAPOLEONE F&G SNC","acf":{"ragione_sociale":"PROFUMERIA DEI F.LLI NAPOLEONE F&G SNC","indirizzo":"CORSO MUSACCHIO, 43A/B","citta":"GRAVINA DI PUGLIA"}},
        {"title":"PROFUMERIA DEI F.LLI NAPOLEONE F&G SNC","acf":{"ragione_sociale":"PROFUMERIA DEI F.LLI NAPOLEONE F&G SNC","indirizzo":"CORSO FEDERICO II DI SVEVIA 106","citta":"ALTAMURA"}},
        {"title":"PROFUMERIA DURANTI DI S. PELLICCIA & C.","acf":{"ragione_sociale":"PROFUMERIA DURANTI DI S. PELLICCIA & C.","indirizzo":"CORSO CAVOUR 109","citta":"ORVIETO"}},
        {"title":"PROFUMERIA DURANTI DI S. PELLICCIA & C.","acf":{"ragione_sociale":"PROFUMERIA DURANTI DI S. PELLICCIA & C.","indirizzo":"VIALE I MAGGIO 6","citta":"ORVIETO SCALO"}},
        {"title":"PROFUMERIA FIAMMELLI SNC DI FIAMMELLI PATRIZIA E SUSANNA","acf":{"ragione_sociale":"PROFUMERIA FIAMMELLI SNC DI FIAMMELLI PATRIZIA E SUSANNA","indirizzo":"VIA XX SETTEMBRE 45","citta":"CREMA"}},
        {"title":"PROFUMERIA FRANCA SRL","acf":{"ragione_sociale":"PROFUMERIA FRANCA SRL","indirizzo":"VIA PESCIATINA 1A","citta":"LUNATA"}},
        {"title":"PROFUMERIA FRANCAVILLA SRL","acf":{"ragione_sociale":"PROFUMERIA FRANCAVILLA SRL","indirizzo":"VIALE MONZA 87","citta":"MILANO"}},
        {"title":"PROFUMERIA LADY SNC","acf":{"ragione_sociale":"PROFUMERIA LADY SNC","indirizzo":"PIAZZA ANNESSIONE 15","citta":"MACERATA"}},
        {"title":"PROFUMERIA LADY SNC","acf":{"ragione_sociale":"PROFUMERIA LADY SNC","indirizzo":"CORSO CAVOUR 100","citta":"MACERATA"}},
        {"title":"PROFUMERIA LADY SNC","acf":{"ragione_sociale":"PROFUMERIA LADY SNC","indirizzo":"VIA FILELFO 9","citta":"TOLENTINO"}},
        {"title":"PROFUMERIA LAUDA SRL","acf":{"ragione_sociale":"PROFUMERIA LAUDA SRL","indirizzo":"VIA MANZONI 124 - CENTRO COMMERCIALE EMISFERO","citta":"ZANE'"}},
        {"title":"PROFUMERIA LAUDA SRL","acf":{"ragione_sociale":"PROFUMERIA LAUDA SRL","indirizzo":"VIA PASINI N, 1","citta":"SCHIO"}},
        {"title":"PROFUMERIA PILLAN SAS","acf":{"ragione_sociale":"PROFUMERIA PILLAN SAS","indirizzo":"VIA ZUCCOLA 24 - CENTRO COMMERCIALE BERTON","citta":"BOLZANO VICENTINO"}},
        {"title":"PROFUMERIA PILLAN SAS","acf":{"ragione_sociale":"PROFUMERIA PILLAN SAS","indirizzo":"STRADA DI CA' BALBI 127","citta":"VICENZA"}},
        {"title":"PROFUMERIA ROSSI SRL","acf":{"ragione_sociale":"PROFUMERIA ROSSI SRL","indirizzo":"VIALE EUGENIO CHIESA 43","citta":"MASSA"}},
        {"title":"PROFUMERIA ROSSI SRL","acf":{"ragione_sociale":"PROFUMERIA ROSSI SRL","indirizzo":"LARGO MATTEOTTI 14","citta":"MASSA"}},
        {"title":"PROFUMERIA SABRINA DI POMPILI VIOLA","acf":{"ragione_sociale":"PROFUMERIA SABRINA DI POMPILI VIOLA","indirizzo":"VIA FRATELLI ROSSELLI 22","citta":"CESENA"}},
        {"title":"PROFUMERIA SAMPAOLI SRL","acf":{"ragione_sociale":"PROFUMERIA SAMPAOLI SRL","indirizzo":"CORSO VITTORIO EMANUELE II 55","citta":"SABAUDIA"}},
        {"title":"PROFUMERIA SAMPAOLI SRL","acf":{"ragione_sociale":"PROFUMERIA SAMPAOLI SRL","indirizzo":"VIA MARITTIMA 126","citta":"FROSINONE"}},
        {"title":"PROFUMERIA SERGNESE S.R.L.","acf":{"ragione_sociale":"PROFUMERIA SERGNESE S.R.L.","indirizzo":"PIAZZA DELLA REPUBBLICA 3/4/5","citta":"TERNI"}},
        {"title":"PROFUMERIA SILVY SRL","acf":{"ragione_sociale":"PROFUMERIA SILVY SRL","indirizzo":"VIA SORTE - CENTRO COMMERCIALE SAN BONIFACIO","citta":"SAN BONIFACIO"}},
        {"title":"PROFUMERIA TANA DI TANA MAURIZIO E TANA MARIA ROSARIA & C. SAS","acf":{"ragione_sociale":"PROFUMERIA TANA DI TANA MAURIZIO E TANA MARIA ROSARIA & C. SAS","indirizzo":"VIA OBERDAN, 22/A","citta":"LECCE"}},
        {"title":"PROFUMERIA TISO SNC","acf":{"ragione_sociale":"PROFUMERIA TISO SNC","indirizzo":"VIA MATTEOTTI, 73","citta":"RHO"}},
        {"title":"PROFUMERIA ZANCHIN DI ZANCHIN G. & C SNC","acf":{"ragione_sociale":"PROFUMERIA ZANCHIN DI ZANCHIN G. & C SNC","indirizzo":"PIAZZA XX SETTEMBRE 16","citta":"SAN GIORGIO DI NOGARO"}},
        {"title":"PROFUMERIE CELLURALE SRL","acf":{"ragione_sociale":"PROFUMERIE CELLURALE SRL","indirizzo":"VIA CINTIA 150","citta":"RIETI"}},
        {"title":"PROFUMERIE FREE SPACE SRL","acf":{"ragione_sociale":"PROFUMERIE FREE SPACE SRL","indirizzo":"CORSO ITALIA 38","citta":"TRANI"}},
        {"title":"PROFUMERIE FREE SPACE SRL","acf":{"ragione_sociale":"PROFUMERIE FREE SPACE SRL","indirizzo":"VIALE ISTRIA 112","citta":"ANDRIA"}},
        {"title":"PROFUMERIE FUSCO SRL","acf":{"ragione_sociale":"PROFUMERIE FUSCO SRL","indirizzo":"CORSO NOVARA 1","citta":"NAPOLI"}},
        {"title":"PROFUMERIE GD SRL","acf":{"ragione_sociale":"PROFUMERIE GD SRL","indirizzo":"VIA MAZZINI 55","citta":"RAPALLO"}},
        {"title":"PROFUMERIE GD SRL","acf":{"ragione_sociale":"PROFUMERIE GD SRL","indirizzo":"VIA MULINETTI 5/C - CENTRO COMMERCIALE I LEUDI","citta":"CARASCO"}},
        {"title":"PROFUMERIE P&P SRL","acf":{"ragione_sociale":"PROFUMERIE P&P SRL","indirizzo":"STRADA STATALE 106 KM 422 - CENTRO COMMERCIALE HERACLEA","citta":"POLICORO"}},
        {"title":"PROFUMERIE P&P SRL","acf":{"ragione_sociale":"PROFUMERIE P&P SRL","indirizzo":"VIA DI PALMA 81","citta":"TARANTO"}},
        {"title":"PROFUMERIE P&P SRL","acf":{"ragione_sociale":"PROFUMERIE P&P SRL","indirizzo":"CORSO ROMA 21","citta":"MASSAFRA"}},
        {"title":"PROFUMERIE PISELLI DI PISELLI & C. SAS","acf":{"ragione_sociale":"PROFUMERIE PISELLI DI PISELLI & C. SAS","indirizzo":"VIA CARLO JUSSI 8/A","citta":"SAN LAZZARO DI SAVENA"}},
        {"title":"PROFUMERIE TEDESCO DI CAPODIECI RITA ADDOLORATA","acf":{"ragione_sociale":"PROFUMERIE TEDESCO DI CAPODIECI RITA ADDOLORATA","indirizzo":"VIA PORTA CASTELLO 12","citta":"ANDRIA"}},
        {"title":"PROFUMERIE TOTI & C. SRL","acf":{"ragione_sociale":"PROFUMERIE TOTI & C. SRL","indirizzo":"PIAZZA ZAMA 21/22","citta":"ROMA"}},
        {"title":"PROFUMERIE TWIN DI GALLI E. & S. SNC","acf":{"ragione_sociale":"PROFUMERIE TWIN DI GALLI E. & S. SNC","indirizzo":"VIALE MAZZINI 8","citta":"VIGNOLA"}},
        {"title":"PROFUMI & PROFUMI SRL","acf":{"ragione_sociale":"PROFUMI & PROFUMI SRL","indirizzo":"VIA TOLEDO 352","citta":"NAPOLI"}},
        {"title":"PROFUMI & PROFUMI SRL","acf":{"ragione_sociale":"PROFUMI & PROFUMI SRL","indirizzo":"VIA ONOFRIO FRAGNITO 108","citta":"NAPOLI"}},
        {"title":"PROFUMI & PROFUMI SRL","acf":{"ragione_sociale":"PROFUMI & PROFUMI SRL","indirizzo":"VIA M. D'AYALA 10","citta":"NAPOLI"}},
        {"title":"R&S SRL","acf":{"ragione_sociale":"R&S SRL","indirizzo":"VIALE DELLA VITTORIA 7","citta":"ASOLA"}},
        {"title":"R&S SRL","acf":{"ragione_sociale":"R&S SRL","indirizzo":"VIA ZANARDELLI 79","citta":"VILLANUOVA SUL CLISI"}},
        {"title":"R&S SRL","acf":{"ragione_sociale":"R&S SRL","indirizzo":"VIA DELLA REPUBBLICA 1","citta":"PISOGNE"}},
        {"title":"R&S SRL","acf":{"ragione_sociale":"R&S SRL","indirizzo":"VIA MONS. PICCINELLI 52","citta":"PALAZZOLO SULL'OGLIO"}},
        {"title":"R&S SRL","acf":{"ragione_sociale":"R&S SRL","indirizzo":"VIA PADANA SUPERIORE 165","citta":"OSPITALETTO"}},
        {"title":"R&S SRL","acf":{"ragione_sociale":"R&S SRL","indirizzo":"VIALE ADUA 35","citta":"ORZINUOVI"}},
        {"title":"R&S SRL","acf":{"ragione_sociale":"R&S SRL","indirizzo":"VIA SAN GIOVANNI 32","citta":"MONIGA DEL GARDA"}},
        {"title":"R&S SRL","acf":{"ragione_sociale":"R&S SRL","indirizzo":"VIA NAZIONALE 48/D - CENTRO COMMERCIALE ITALMARK","citta":"SONICO"}},
        {"title":"R&S SRL","acf":{"ragione_sociale":"R&S SRL","indirizzo":"VIALE STAZIONE 24  - CENTRO COMMERCIALE ITALMARK","citta":"BAGNOLO MELLA"}},
        {"title":"R&S SRL","acf":{"ragione_sociale":"R&S SRL","indirizzo":"VIA CASTIGLIONE 5","citta":"CASTEL GOFFREDO"}},
        {"title":"R&S SRL","acf":{"ragione_sociale":"R&S SRL","indirizzo":"VIA RICHIEDEI 59","citta":"GUSSAGO"}},
        {"title":"R&S SRL","acf":{"ragione_sociale":"R&S SRL","indirizzo":"VIA BRESCIA 31","citta":"CHIARI"}},
        {"title":"R&S SRL","acf":{"ragione_sociale":"R&S SRL","indirizzo":"VIA TONALE 101 - CENTRO COMMERCIALE BENNET","citta":"ALBANO SANT'ALESSANDRO"}},
        {"title":"R&S SRL","acf":{"ragione_sociale":"R&S SRL","indirizzo":"VIA BRESSANORO 73 - CENTRO COMMERCIALE ITALMARK","citta":"CASTELLEONE"}},
        {"title":"R&S SRL","acf":{"ragione_sociale":"R&S SRL","indirizzo":"VIALE SANT'EUFEMIA 108/E","citta":"BRESCIA"}},
        {"title":"RARA SRL","acf":{"ragione_sociale":"RARA SRL","indirizzo":"PIAZZA VITTORIO EMANUELE 25 -  GALLERIA LA POSTA","citta":"PIEVE DI SOLIGO"}},
        {"title":"RARA SRL","acf":{"ragione_sociale":"RARA SRL","indirizzo":"VIA MONTEGRAPPA 19","citta":"MONTEBELLUNA"}},
        {"title":"RAVELLI PROFUMERIE SRL","acf":{"ragione_sociale":"RAVELLI PROFUMERIE SRL","indirizzo":"VIA MATTEI 37 - CENTRO COMMERCIALE LE RONDINELLE","citta":"RONCADELLE"}},
        {"title":"RAVELLI PROFUMERIE SRL","acf":{"ragione_sociale":"RAVELLI PROFUMERIE SRL","indirizzo":"VIA ROVATO 44 - CENTRO COMMERCIALE LE PORTE FRANCHE","citta":"ERBUSCO"}},
        {"title":"RINASCENTE","acf":{"ragione_sociale":"RINASCENTE","indirizzo":"VIA SARDEGNA 1","citta":"CAGLIARI"}},
        {"title":"RINASCENTE","acf":{"ragione_sociale":"RINASCENTE","indirizzo":"VIA AGNELLO","citta":"MILANO"}},
        {"title":"RINASCENTE","acf":{"ragione_sociale":"RINASCENTE","indirizzo":"VIA SANT'EUPLIO 24","citta":"CATANIA"}},
        {"title":"RINASCENTE","acf":{"ragione_sociale":"RINASCENTE","indirizzo":"PIAZZA REPUBBLICA 1","citta":"FIRENZE"}},
        {"title":"RINASCENTE","acf":{"ragione_sociale":"RINASCENTE","indirizzo":"VIA ANIENE 1","citta":"ROMA"}},
        {"title":"RINASCENTE","acf":{"ragione_sociale":"RINASCENTE","indirizzo":"VIA GIOLITTI 14","citta":"TORINO"}},
        {"title":"RINASCENTE","acf":{"ragione_sociale":"RINASCENTE","indirizzo":"VIA GAGINI 7","citta":"PALERMO"}},
        {"title":"RINASCENTE","acf":{"ragione_sociale":"RINASCENTE","indirizzo":"VIA DEL TRITONE 58/B","citta":"ROMA"}},
        {"title":"RINASCENTE","acf":{"ragione_sociale":"RINASCENTE","indirizzo":"VIA AZZONE VISCONTI 1","citta":"MONZA"}},
        {"title":"RIVA CORNICELLO SRL","acf":{"ragione_sociale":"RIVA CORNICELLO SRL","indirizzo":"PIAZZA MATTEOTTI 20","citta":"BARDOLINO"}},
        {"title":"RIZZI SNC DI RIZZI RITA & C.","acf":{"ragione_sociale":"RIZZI SNC DI RIZZI RITA & C.","indirizzo":"VIALE TRENTO 95","citta":"VALDAGNO"}},
        {"title":"RIZZI SNC DI RIZZI RITA & C.","acf":{"ragione_sociale":"RIZZI SNC DI RIZZI RITA & C.","indirizzo":"VIA LOGGIA 1","citta":"MALO"}},
        {"title":"ROCCO SRL","acf":{"ragione_sociale":"ROCCO SRL","indirizzo":"CORSO ROMA 8","citta":"GALLIPOLI"}},
        {"title":"ROCCO SRL","acf":{"ragione_sociale":"ROCCO SRL","indirizzo":"VIA VITTORIO EMANUELE 85","citta":"MAGLIE"}},
        {"title":"RONCHI SRL","acf":{"ragione_sociale":"RONCHI SRL","indirizzo":"VIA ARONA 15/9","citta":"MILANO"}},
        {"title":"RONCHI SRL","acf":{"ragione_sociale":"RONCHI SRL","indirizzo":"STRADA STATALE DEI GIOVI 35 - CENTRO COMMERCIALE BRIANZA CARREFOUR","citta":"PADERNO DUGNANO"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIALE MORANDI 8/H - CENTRO COMMERCIALE ARIOSTO","citta":"REGGIO EMILIA"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA A. FRANZONI 2","citta":"REGGIO EMILIA"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA EMILIA SANTO STEFANO 2","citta":"REGGIO EMILIA"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA EMILIA SANTO STEFANO 7","citta":"REGGIO EMILIA"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA SAN ROCCO 2","citta":"REGGIO EMILIA"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA EMILIA SAN PIETRO 11E","citta":"REGGIO EMILIA"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA EMILIA SAN PIETRO 5/B","citta":"REGGIO EMILIA"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA LEPIDO 186/13 - CENTRO COMMERCIALE BORGO","citta":"BOLOGNA"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA CLAVATURE 20","citta":"BOLOGNA"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA CORTICELLA 31","citta":"BOLOGNA"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA INDIPENDENZA 24","citta":"BOLOGNA"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA SAN VITALE 4","citta":"BOLOGNA"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA CRISTOFORO COLOMBO 15 - CENTRO COMMERCIALE I PORTICI","citta":"FORLI'"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA EMILIA 150 - CENTRO COMMERCIALE I MALATESTA","citta":"RIMINI"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA BERTOLA 20","citta":"TORINO"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA CARLO ALBERTO 26","citta":"TORINO"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"CORSO GIULIO CESARE 178","citta":"TORINO"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA MAZZINI 21","citta":"TORINO"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA DI NANNI 94","citta":"TORINO"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA TRIPOLI 7","citta":"TORINO"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA CLEMENTI 20/E - CENTRO COMMERCIALE LE QUERCE","citta":"REGGIO EMILIA"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"STRADA TRAVERSETOLO 20 - CENTRO COMMERCIALE EUROSIA","citta":"PARMA"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"STRADA ALTESSANO 141 - CENTRO COMMERCIALE AREA 12","citta":"TORINO"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA VILLANOVA 29  - CENTRO COMMERCIALE CENTRONOVA","citta":"CASTENASO"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA ARTURO CARLO JEMOLO 110 - CENTRO COMMERCIALE LUNGOSAVIO","citta":"CESENA"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA BISAURA 3  - CENTRO COMMERCIALE LE MAIOLICHE","citta":"FAENZA"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"PIAZZA CARDUCCI 1/C","citta":"CORREGGIO"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIALE JACOPO BAROZZI 20","citta":"MODENA"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIALE BARILLA 3 > LARGO FAUSTO BOCCHI 18A -23A","citta":"PARMA"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA EMILIA OVEST 1480  - CENTRO COMMERCIALE GRANDEMILIA","citta":"MODENA"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA EMILIA EST 265/277","citta":"MODENA"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"CORSO FANTI 60","citta":"CARPI"}},
        {"title":"ROSSI","acf":{"ragione_sociale":"ROSSI","indirizzo":"VIA MODENA CARPI 330 - CENTRO COMMERCIALE LE GALLERIE","citta":"APPALTO DI SOLIERA"}},
        {"title":"SABBIONI PROFUMI SRL","acf":{"ragione_sociale":"SABBIONI PROFUMI SRL","indirizzo":"VIA EUGENIO CURIEL 44/45/47","citta":"FORLI'"}},
        {"title":"SABBIONI PROFUMI SRL","acf":{"ragione_sociale":"SABBIONI PROFUMI SRL","indirizzo":"VIA FORO BOARIO 30 - CENTRO COMMERCIALE IL GLOBO","citta":"LUGO"}},
        {"title":"SABBIONI PROFUMI SRL","acf":{"ragione_sociale":"SABBIONI PROFUMI SRL","indirizzo":"VIA UNGARETTI 24 - CENTRO COMMERCIALE I PORTICI","citta":"RUSSI"}},
        {"title":"SABBIONI PROFUMI SRL","acf":{"ragione_sociale":"SABBIONI PROFUMI SRL","indirizzo":"VIA BUSSATO 74 - CENTRO COMMERCIALE IPERMERCATO ESP","citta":"RAVENNA"}},
        {"title":"SABBIONI PROFUMI SRL","acf":{"ragione_sociale":"SABBIONI PROFUMI SRL","indirizzo":"VIA FAENTINA 118","citta":"RAVENNA"}},
        {"title":"SABBIONI PROFUMI SRL","acf":{"ragione_sociale":"SABBIONI PROFUMI SRL","indirizzo":"VIA IV NOVEMBRE 13","citta":"RAVENNA"}},
        {"title":"SABBIONI PROFUMI SRL","acf":{"ragione_sociale":"SABBIONI PROFUMI SRL","indirizzo":"PIAZZA DEL POPOLO 34","citta":"FAENZA"}},
        {"title":"SABBIONI PROFUMI SRL","acf":{"ragione_sociale":"SABBIONI PROFUMI SRL","indirizzo":"VIA CADUTI DI NASSIRIYA 20 - CENTRO COMMERCIALE LE BEFANE","citta":"RIMINI"}},
        {"title":"SABBIONI PROFUMI SRL","acf":{"ragione_sociale":"SABBIONI PROFUMI SRL","indirizzo":"VIA AURELIO SAFFI 9","citta":"SANTARCANGELO DI ROMAGNA"}},
        {"title":"SABBIONI PROFUMI SRL","acf":{"ragione_sociale":"SABBIONI PROFUMI SRL","indirizzo":"VIA BOVIO 59","citta":"CATTOLICA"}},
        {"title":"SABBIONI PROFUMI SRL","acf":{"ragione_sociale":"SABBIONI PROFUMI SRL","indirizzo":"VIAlE REPUBBLICA 3 - CENTRO COMMERCIALE PORTA MARCOLFA","citta":"SAN GIOVANNI IN PERSICETO"}},
        {"title":"SBRACCIA","acf":{"ragione_sociale":"SBRACCIA","indirizzo":"VIA CECCARDO ROCCATAGLIATA CECCARDI 34/R","citta":"GENOVA"}},
        {"title":"SBRACCIA","acf":{"ragione_sociale":"SBRACCIA","indirizzo":"VIA CIPRO 28-30 R","citta":"GENOVA"}},
        {"title":"SBRACCIA","acf":{"ragione_sociale":"SBRACCIA","indirizzo":"VIA OBERDAN 193","citta":"GENOVA"}},
        {"title":"SBRACCIA","acf":{"ragione_sociale":"SBRACCIA","indirizzo":"VIA ROMA 53/55/57","citta":"RECCO"}},
        {"title":"SBRACCIA","acf":{"ragione_sociale":"SBRACCIA","indirizzo":"CORSO CAVOUR 5","citta":"LA SPEZIA"}},
        {"title":"SBRACCIA","acf":{"ragione_sociale":"SBRACCIA","indirizzo":"VIA CANEVARI 142R","citta":"GENOVA"}},
        {"title":"SBRACCIA","acf":{"ragione_sociale":"SBRACCIA","indirizzo":"VIA SESTRI 158/R","citta":"GENOVA"}},
        {"title":"SBRUZZI PROFUMERIA DI SBRUZZI TIZIANA & C. SNC","acf":{"ragione_sociale":"SBRUZZI PROFUMERIA DI SBRUZZI TIZIANA & C. SNC","indirizzo":"PIAZZA GARIBALDI 6","citta":"SONCINO"}},
        {"title":"SBRUZZI PROFUMERIA DI SBRUZZI TIZIANA & C. SNC","acf":{"ragione_sociale":"SBRUZZI PROFUMERIA DI SBRUZZI TIZIANA & C. SNC","indirizzo":"VIA VITTORIO VENETO 111","citta":"BRESCIA"}},
        {"title":"SBRUZZI PROFUMERIA DI SBRUZZI TIZIANA & C. SNC","acf":{"ragione_sociale":"SBRUZZI PROFUMERIA DI SBRUZZI TIZIANA & C. SNC","indirizzo":"VIALE EUROPA 6 - CENTRO COMMERCIALE EUROPA","citta":"PALAZZOLO SULL'OGLIO"}},
        {"title":"SBRUZZI PROFUMERIA DI SBRUZZI TIZIANA & C. SNC","acf":{"ragione_sociale":"SBRUZZI PROFUMERIA DI SBRUZZI TIZIANA & C. SNC","indirizzo":"VIA MATTEI 37 - CENTRO COMMERCIALE LE RONDINELLE","citta":"RONCADELLE"}},
        {"title":"SBRUZZI PROFUMERIA DI SBRUZZI TIZIANA & C. SNC","acf":{"ragione_sociale":"SBRUZZI PROFUMERIA DI SBRUZZI TIZIANA & C. SNC","indirizzo":"VIALE DEL PIAVE 50/F","citta":"BRESCIA"}},
        {"title":"SBRUZZI PROFUMERIA DI SBRUZZI TIZIANA & C. SNC","acf":{"ragione_sociale":"SBRUZZI PROFUMERIA DI SBRUZZI TIZIANA & C. SNC","indirizzo":"VIALE SORELLE AMBROSETTI 10 - CENTRO COMMERCIALE NUOVO FLAMINIA","citta":"BRESCIA"}},
        {"title":"SEDE DG BEAUTY","acf":{"ragione_sociale":"SEDE DG BEAUTY","indirizzo":"VIA A.KRAMER, 18","citta":"MILANO"}},
        {"title":"SEMINATI","acf":{"ragione_sociale":"SEMINATI","indirizzo":"CORSO BRIANZA 6","citta":"MARIANO COMENSE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA PECORAINO LOCALITA' ROCCELLA  - CENTRO COMMERCIALE FORUM","citta":"PALERMO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA DEL CORSO 480","citta":"ROMA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"CORSO ITALIA 156/160","citta":"AREZZO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA E. FERMI 1 - CENTRO COMMERCIALE CURNO","citta":"CURNO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA ITALIA  - CENTRO COMMERCIALE IL GLOBO","citta":"BUSNAGO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA SAN QUIRICO 165 - CENTRO COMMERCIALE I GIGLI","citta":"CAMPI BISENZIO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"STRADA PROVINCIALE 208 - CENTRO COMMERCIALE CAROSELLO","citta":"CARUGATE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA SAN COSTANTINO 98 - CENTRO COMMERCIALE IL CUORE ADRIATICO","citta":"CIVITANOVA MARCHE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"CORSO ROMANIA 460 - CENTRO COMMERCIALE AUCHAN","citta":"TORINO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA ITALIA 46","citta":"MONZA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"CORSO VITTORIO EMANUELE II 43","citta":"PIACENZA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA MILANESE 10 - CENTRO COMMERCIALE CENTROSARCA","citta":"SESTO SAN GIOVANNI"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA MILANO FIORI  23- CENTRO COMMERCIALE MILANOFIORI","citta":"ASSAGO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA GARIBALDI 19/B","citta":"TORINO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"STRADA STATALE 527 SARONNO - MONZA - CENTRO COMMERCIALE CARREFOUR","citta":"LIMBIATE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA DANTE 14","citta":"MILANO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"STRADA STATALE 35 DEI GIOVI - CENTRO COMMERCIALE LENTATE","citta":"LENTATE SUL SEVESO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIALE BELFORTE 315 - CENTRO COMMERCIALE BELFORTE","citta":"VARESE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA DEI MARTELLI 10","citta":"FIRENZE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA CREA 10 - CENTRO COMMERCIALE LE GRU (PRIMO PIANO)","citta":"GRUGLIASCO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA CREA 10 - CENTRO COMMERCIALE LE GRU (PIANO TERRA)","citta":"GRUGLIASCO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA MARCO POLO 15","citta":"TORINO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA ROMA 343","citta":"TORINO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"PIAZZA GRAN MADRE DI DIO 14","citta":"TORINO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA CIRCONVALLAZIONE 95","citta":"PAVONE CANAVESE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA GEN. DALLA CHIESA - CENTRO COMMERCIALE LA ALPI","citta":"CIRIE'"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA PORTICO 71 - CENTRO COMMERCIALE ORIO CENTER","citta":"ORIO AL SERIO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"LARGO CALEOTTO 10 - CENTRO COMMERCIALE MERIDIANA","citta":"LECCO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA INDUSTRIA - CENTRO COMMERCIALE DAVERIO","citta":"DAVERIO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"PIAZZA ARGENTINA 5","citta":"MILANO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA ALBERTO LIONELLO - CENTRO COMMERCIALE PORTA DI ROMA","citta":"ROMA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA MANTOVA 36 - CENTRO COMMERCIALE IPER IL LEONE","citta":"LONATO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA PACINOTTI - CENTRO COMMERCIALE CARREFOUR","citta":"PONTECAGNANO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"STRADA STATALE SANNITICA 87 LOCALITA' AURNO - CENTRO COMMERCIALE CAMPANIA","citta":"MARCIANISE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA MAZZINI 70/B","citta":"VERONA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"STRADA STATALE 280 LOCALITA' COMUNI CONDOMINI - CENTRO COMMERCIALE DUE MARI","citta":"MAIDA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA MAQUEDA 443","citta":"PALERMO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA MONTE CRISTALLO 3 - CENTRO COMMERCIALE VERONA UNO","citta":"SAN GIOVANNI LUPATOTO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA ASIAGO 2 - CENTRO COMMERCIALE LARIO CENTER","citta":"COMO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA GIUSTI 4 - CENTRO COMMERCIALE IL CASTELLO","citta":"FERRARA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA GIOLITTI 10 - STAZIONE TERMINI","citta":"ROMA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA POLA 20 - CENTRO COMMERCIALE LE PIRAMIDI","citta":"TORRI DI QUARTESOLO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA GINO ALLEGRI 26","citta":"MESTRE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA COLOMBO 79 - CENTRO COMMERCIALE AIRONE","citta":"MONSELICE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"STRADA PROVINCIALE PER MORTARA 7 -  CENTRO COMMERCIALE BENNET","citta":"SAN MARTINO SICCOMARIO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA MATTEI 52 - CENTRO COMMERCIALE VALECENTER","citta":"MARCON"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"STRADA PADANA VERSO PADOVA 60- CENTRO COMMERCIALE PALLADIO","citta":"VICENZA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"STRADA FLAMINIA KM 147 - CENTRO COMMERCIALE PIAZZA UMBRA","citta":"TREVI"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA MARGARITA 8 - CENTRO COMMERCIALE AUCHAN","citta":"CUNEO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA FARINI 6A/6B","citta":"BOLOGNA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA CANCIANI 15","citta":"UDINE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA COLLATINA 858 - CENTRO COMMERCIALE ROMA EST","citta":"ROMA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"GALLERIA UMBERTO I - VIA SAN CARLO","citta":"NAPOLI"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"CONTRADA RIOMORO - CENTRO COMMERCIALE VAL VIBRATA","citta":"COLONNELLA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA PO 1 - CENTRO COMMERCIALE CENTRO D'ABRUZZO","citta":"SAN GIOVANNI TEATINO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA FIUMARA 15/16 - CENTRO COMMERCIALE FIUMARA","citta":"GENOVA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"CORSO VITTORIO EMANUELE II 10","citta":"PORDENONE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"CORSO PALLADIO 109","citta":"VICENZA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA IMBRIANI 7","citta":"TRIESTE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA MUSEO 32","citta":"BOLZANO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"PIAZZA GIORGIONE 26","citta":"CASTELFRANCO VENETO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA CRISTOFORO COLOMBO 84D","citta":"CONEGLIANO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"CORSO GARIBALDI 59","citta":"FORLI'"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA XX SETTEMBRE 37","citta":"GENOVA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA IMPERATORE AUGUSTO 18","citta":"LECCE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA FILLUNGO 169","citta":"LUCCA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA POSTUMIA OVEST 76 - CENTRO COMMERCIALE TIZIANO","citta":"SAN BIAGIO DI CALLALTA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA CAVOUR 17/B","citta":"PARMA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"PIAZZETTA XXVII FEBBRAIO 1944 N 8","citta":"PORTOGRUARO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"CORSO VITTORIO EMANUELE 46","citta":"SALERNO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA ROMA 73","citta":"SANREMO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"PIAZZA DELLA REPUBBLICA 27/28","citta":"TERNI"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA KENNEDY - CENTRO COMMERCIALE METROPOLIS","citta":"RENDE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA GIANNANTONIO MANCI 52","citta":"TRENTO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA CALMAGGIORE 31","citta":"TREVISO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA LA ROSA 354 - CENTRO COMMERCIALE FUENTES","citta":"PIANTEDO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA VENEZIA 61 - CENTRO COMMERCIALE GIOTTO","citta":"PADOVA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"PIAZZA DEGLI ETRUSCHI - CENTRO COMMERCIALE MERIDIANA","citta":"CASALECCHIO DI RENO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA AURELIA NORD KM 76 - CENTRO COMMERCIALE LA SCAGLIA","citta":"CIVITAVECCHIA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA E. FERRI 8 - CENTRO COMMERCIALE LA ROMANINA","citta":"ROMA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIALE P.L. NERVI - CENTRO COMMERCIALE LATINAFIORI","citta":"LATINA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA MAMMURANO LOCALITA' SANTA CROCE - CENTRO COMMERCIALE ITACA","citta":"FORMIA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"CORSO UMBERTO I 61 - CENTRO COMMERCIALE SAN NICOLA","citta":"TERMOLI"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA DELLE SERRE - LOCALITA' QUARTOCCIU - CENTRO COMMERCIALE MILLENIUM","citta":"CAGLIARI"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA CHIAIA 145","citta":"NAPOLI"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA DANTE 6/8","citta":"RICCIONE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"CORSO VITTORIO EMANUELE 24/28","citta":"MILANO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA DEL TRITONE 75/78","citta":"ROMA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIALE MARCONI 124","citta":"ROMA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"PIAZZA DUCA D'AOSTA","citta":"MILANO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"CORSO VERCELLI 38","citta":"MILANO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"CONTRADA CUBBA MARLETTA - CENTRO COMMERCIALE CENTRO SICILIA TENUTELLA","citta":"MISTERBIANCO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA SANTA LUCIA 11","citta":"PADOVA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA XX SETTEMBRE 7","citta":"BERGAMO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA SPARANO 39","citta":"BARI"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA ETNEA 151/153","citta":"CATANIA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA ACHILLE GRANDI 26 - CENTRO COMMERCIALE LE MASSERIE","citta":"RAGUSA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA GIUSEPPE LANZA DI SCALEA 1963 - CENTRO COMMERCIALE CONCA D'ORO","citta":"PALERMO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"SESTIERE CANNAREGIO","citta":"VENEZIA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"FONDAMENTA SANTA LUCIA 1 - STAZIONE SANTA LUCIA","citta":"VENEZIA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"PIAZZA GAE AULENTI","citta":"MILANO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"CORSO ITALIA 155","citta":"PISA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"CORSO ITALIA 9","citta":"NOVARA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA EMILIA 134","citta":"MODENA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"LOCALITA' MARANUZ 2 - CENTRO COMMERCIALE TIARE SHOPPING","citta":"VILLESSE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"LOCALITA' CANOVE 1 - CENTRO COMMERCIALE SHOP CENTER GRAND'AFFI","citta":"AFFI"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA DE TILLIER 57","citta":"AOSTA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA ARDUINO - CENTRO COMMERCIALE NAVE DE VERO","citta":"MARGHERA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA EMILIA SAN PIETRO 8","citta":"REGGIO EMILIA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"PIAZZA COLOMBO 3 - CENTRO COMMERCIALE ROMAGNA CENTER","citta":"SAVIGNANO SUL RUBICONE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA LUCA GIORDANO 59","citta":"NAPOLI"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"PIAZZA DELLA STAZIONE-STAZIONE SANTA MARIA NOVELLA","citta":"FIRENZE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA EUGENIO LURAGHI - CENTRO COMMERCIALE ARESE SHOPPING CENTER","citta":"ARESE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA EINAUDI - CENTRO COMMERCIALE RONCADELLE SHOPPING CENTER","citta":"RONCADELLE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA DELLE NAZIONI 1 - CENTRO COMMERCIALE ADIGEO","citta":"VERONA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"CORSO BUENOS AIRES 41","citta":"MILANO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA STUPINIGI 182 - CENTRO COMMERCIALE MONDO JUVE","citta":"VINOVO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"PIAZZA TRE TORRI - CITY LIFE","citta":"MILANO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA ADRIANO OLIVETTI - CENTRO COMMERCIALE LA MONGOLFIERA","citta":"MOLFETTA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"CORSO MATTEOTTI 52","citta":"VARESE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA INDIPENDENZA 36","citta":"BOLOGNA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA BANCHI DI SOPRA 27","citta":"SIENA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA COLOMBO 714 - CENTRO COMMERCIALE EUROMA 2","citta":"ROMA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA LUINI 10","citta":"COMO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA TORINO 22-24","citta":"MILANO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA COLA DI RIENZO 156","citta":"ROMA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA CESARE PAVESE 2","citta":"OLBIA"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA SACCHI 8","citta":"TORINO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA GIUSEPPE SPINETTI 13","citta":"FORTE DEI MARMI"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA CALZAIUOLI 54-56","citta":"FIRENZE"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA MARILYN MONROE 2 - CENTRO COMMERCIALE GRAN RENO","citta":"CASALECCHIO DI RENO"}},
        {"title":"SEPHORA","acf":{"ragione_sociale":"SEPHORA","indirizzo":"VIA CURIEL 25 - CENTRO COMMERCIALE FIORDALISO","citta":"ROZZANO"}},
        {"title":"SO.F.I.S. SRL.","acf":{"ragione_sociale":"SO.F.I.S. SRL.","indirizzo":"VIALE VENEZIA 61","citta":"LIGNANO SABBIADORO"}},
        {"title":"SO.F.I.S. SRL.","acf":{"ragione_sociale":"SO.F.I.S. SRL.","indirizzo":"PIAZZA INDIPENDENZA 50","citta":"LATISANA"}},
        {"title":"TALEA GROUP","acf":{"ragione_sociale":"TALEA GROUP","indirizzo":"VIA GUGLIEMO MARCONI 84","citta":"VIAREGGIO"}},
        {"title":"THALER SRL","acf":{"ragione_sociale":"THALER SRL","indirizzo":"VIA DEI PORTICI 69","citta":"BOLZANO"}},
        {"title":"TORTELLI SRL","acf":{"ragione_sociale":"TORTELLI SRL","indirizzo":"VIA CARLO CATTANEO 46/Q","citta":"VITERBO"}},
        {"title":"TURINA ANDREA & C. SNC","acf":{"ragione_sociale":"TURINA ANDREA & C. SNC","indirizzo":"VIA BUTTURINI 30","citta":"SALO'"}},
        {"title":"URBANI","acf":{"ragione_sociale":"URBANI","indirizzo":"VIA VITTORELLI 39/41","citta":"BASSANO DEL GRAPPA"}},
        {"title":"VAIFRO MISSANA SERVICE SRL","acf":{"ragione_sociale":"VAIFRO MISSANA SERVICE SRL","indirizzo":"PIAZZA DONDI DALL'OROLOGIO 8","citta":"ABANO TERME"}},
        {"title":"VALLACQUA PROFUMI SAS DI R. VALLACQUA & C.","acf":{"ragione_sociale":"VALLACQUA PROFUMI SAS DI R. VALLACQUA & C.","indirizzo":"VIA DE TILLIER 5","citta":"AOSTA"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"CORSO ITALIA 34","citta":"ACQUI TERME"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA VITTORIO EMANUELE 21","citta":"ALBA"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA GRAMSCI 4","citta":"AOSTA"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA XX SETTEMBRE 43","citta":"CUNEO"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"CORSO NIZZA 16","citta":"CUNEO"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA ROMA 154","citta":"FOSSANO"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"CORSO PIEMONTE 54","citta":"SALUZZO"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA FEDELE SAVIO 13","citta":"SAVIGLIANO"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA MARCO POLO 1 - CENTRO COMMERCIALE OUTLET","citta":"NOVENTA DI PIAVE"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA MONTU' SNC - CENTRO COMMERCIALE IL GIALDO","citta":"CHIERI"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA NUOVA ANTICA ROMANA - CENTRO COMMERCIALE SHOPPINN BRUGNATO 5 TERRE","citta":"BRUGNATO"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"PIAZZA VITTORIO ALFIERI 56","citta":"ASTI"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA TORINO 160 - OUTLET VILLAGE","citta":"SETTIMO TORINESE"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA G. MARCONI 92 - CENTRO COMMERCIALE ALIPER","citta":"FONTANIVA"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA CONSELVANA 151/I - CENTRO COMMERCIALE ALIPER","citta":"MASERA' DI PADOVA"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"PIAZZALE ROVIGNO 1 - CENTRO COMMERCIALE IL PORTO","citta":"ADRIA"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIALE BRUXELLES 3 - CENTRO COMMERCIALE ALIPER","citta":"PAESE"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA PIO LA TORRE 5  - CENTRO COMMERCIALE LE PIAZZE","citta":"CASTEL MAGGIORE"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIALE PADOVA 5","citta":"CHIOGGIA"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIALE DELL'INDUSTRIA 2/34 - CENTRO COMMERCIALE LANDO","citta":"CONSELVE"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA VITTORIO SAETTA 6 - CENTRO COMMERCIALE ALIPER","citta":"PADOVA"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA BIASUZZI 28 - CENTRO COMMERCIALE LA CASTELLANA","citta":"PAESE"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIALE PORTA ADIGE - CENTRO COMMERCIALE ALIPER","citta":"ROVIGO"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"STRADA STATALE 16 - CENTRO COMMERCIALE LA FATTORIA","citta":"ROVIGO"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"PIAZZA VITTORIO EMANUELE 35","citta":"ROVIGO"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"CORSO TRENTIN 124","citta":"SAN DONA' DI PIAVE"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA SCAPACCHIO' 133 - CENTRO COMMERCIALE ALIPER","citta":"SELVAZZANO"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA DELLA VITTORIA 206","citta":"VITTORIO VENETO"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA NAZIONALE 8/5","citta":"TAVAGNACCO"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"STRADA DEGLI ANGARIARI 41 - OUTLET THE MARKET SAN MARINO","citta":"FALCIANO"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA ITALO SVEVO 14 - CENTRO COMMERCIALE LE TORRI D'EUROPA","citta":"TRIESTE"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA MAZZINI 33/A","citta":"PARMA"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA ROMA 53","citta":"CHIANCIANO"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA DEL PRIONE 196","citta":"LA SPEZIA"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"PIAZZA PIEROBON > VIA GARIBALDI 1","citta":"CITTADELLA"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"STRADA STATALE DEI GIOVI - CENTRO COMMERCIALE FINIPER","citta":"GRANDATE"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"STRADA PROVINCIALE184 - CENTRO COMMERCIALE IPER","citta":"BREMBATE"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA CALATE 12 - CENTRO COMMERCIALE BENNET","citta":"COLLE UMBERTO"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA MONTELLO 50 - CENTRO COMMERCIALE BENNET PIEVIGINA","citta":"PIEVE DI SOLIGO"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA TIBURTINA VALERIA - CENTRO COMMERCIALE TIBURTINO","citta":"GUIDONIA"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"PIAZZA VITTORIO EMANUELE II 133","citta":"ROMA"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA LUIGI SCHIAVONETTI 420 - CENTRO COMMERCIALE TOR VERGATA","citta":"ROMA"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA MOLINO - CENTRO COMMERCIALE BENNET","citta":"BELFORTE MONFERRATO"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"PIAZZA DEL POPOLO 26","citta":"FAENZA"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA PISTOIESE 176 C/D/E","citta":"PRATO"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIALE MONTE GRAPPA 31","citta":"TREVIGLIO"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"C.SO PALLADIO 94","citta":"VICENZA"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA RIALTO 11","citta":"UDINE"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA TUSCOLANA 893/899 - CENTRO COMMERCIALE OVS","citta":"ROMA"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"VIA MERULANA 273","citta":"ROMA"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"PIAZZA MONDOVICINO 43 - CENTRO COMMERCIALE MONDOVICINO OUTLET","citta":"MONDOVI'"}},
        {"title":"VALLESI","acf":{"ragione_sociale":"VALLESI","indirizzo":"PIAZZA SANTA RITA 9 - CENTRO COMMERCIALE OVS","citta":"TORINO"}},
        {"title":"VANITA'","acf":{"ragione_sociale":"VANITA'","indirizzo":"VIA DEL CORSO 15/17/19R","citta":"FIRENZE"}},
        {"title":"VANITA'","acf":{"ragione_sociale":"VANITA'","indirizzo":"VIA PORTA ROSSA 55","citta":"FIRENZE"}},
        {"title":"VANITA'","acf":{"ragione_sociale":"VANITA'","indirizzo":"PIAZZA MARTIN LUTHER KING 5/7 - CENTRO COMMERCIALE MUGELLO","citta":"BORGO SAN LORENZO"}},
        {"title":"VANITA'","acf":{"ragione_sociale":"VANITA'","indirizzo":"VIA SALCETO 11 - CENTRO COMMERCIALE VALDELSA","citta":"POGGIBONSI"}},
        {"title":"VANITA'","acf":{"ragione_sociale":"VANITA'","indirizzo":"VIA PIETRAPIANA 6/8","citta":"FIRENZE"}},
        {"title":"VANITA'","acf":{"ragione_sociale":"VANITA'","indirizzo":"PIAZZA CAVOUR 12","citta":"CASTELFIORENTINO"}},
        {"title":"VANITA'","acf":{"ragione_sociale":"VANITA'","indirizzo":"VIA PERTINI","citta":"POGGIBONSI"}},
        {"title":"VANITA'","acf":{"ragione_sociale":"VANITA'","indirizzo":"VIA MARCONI 102","citta":"FIRENZE"}},
        {"title":"VANITA'","acf":{"ragione_sociale":"VANITA'","indirizzo":"VIA VITTORIO VENETO 2","citta":"LUCCA"}},
        {"title":"VANITA'","acf":{"ragione_sociale":"VANITA'","indirizzo":"VIA MASACCIO 264","citta":"FIRENZE"}},
        {"title":"VENEZIA PROFUMI SRL","acf":{"ragione_sociale":"VENEZIA PROFUMI SRL","indirizzo":"VIA PUBLIO VALERIO 62/64","citta":"ROMA"}},
        {"title":"VOGUE PROFUMERIE SRL","acf":{"ragione_sociale":"VOGUE PROFUMERIE SRL","indirizzo":"VIA CROCIFISSA DI ROSA 52","citta":"BRESCIA"}},
        {"title":"VOGUE PROFUMERIE SRL","acf":{"ragione_sociale":"VOGUE PROFUMERIE SRL","indirizzo":"VIA ANNIBALE CALINI 5","citta":"BRESCIA"}},
        {"title":"VON ZIEGLAUER PARFUMERIE DI VON ZIEGLAUER MARION","acf":{"ragione_sociale":"VON ZIEGLAUER PARFUMERIE DI VON ZIEGLAUER MARION","indirizzo":"VIA BASTIONI 20","citta":"BRUNICO"}},
        {"title":"VON ZIEGLAUER PARFUMERIE DI VON ZIEGLAUER MARION","acf":{"ragione_sociale":"VON ZIEGLAUER PARFUMERIE DI VON ZIEGLAUER MARION","indirizzo":"VIA RAGEN DI SOPRA 14","citta":"BRUNICO"}},
        {"title":"YOOYOO S.R.L.","acf":{"ragione_sociale":"YOOYOO S.R.L.","indirizzo":"VIA FUSANA 2 - CENTRO COMMERCIALE ARSENALE","citta":"RONCADE"}},
        {"title":"ZIGO' PROFUMI SRL","acf":{"ragione_sociale":"ZIGO' PROFUMI SRL","indirizzo":"VIA BELVEDERE 58/64","citta":"NAPOLI"}},
        {"title":"ZIGO' PROFUMI SRL","acf":{"ragione_sociale":"ZIGO' PROFUMI SRL","indirizzo":"VIA DELL'EPOMEO 196/198","citta":"NAPOLI"}},
        {"title":"ZIGO' PROFUMI SRL","acf":{"ragione_sociale":"ZIGO' PROFUMI SRL","indirizzo":"CORSO DELLA REPUBBLICA 225","citta":"CASSINO"}},
        {"title":"ZIGO' PROFUMI SRL","acf":{"ragione_sociale":"ZIGO' PROFUMI SRL","indirizzo":"VIA VITRUVIO 130/132","citta":"FORMIA"}},
        {"title":"ZIGO' PROFUMI SRL","acf":{"ragione_sociale":"ZIGO' PROFUMI SRL","indirizzo":"CORSO DURANTE 187","citta":"FRATTAMAGGIORE"}},
        {"title":"ZIGO' PROFUMI SRL","acf":{"ragione_sociale":"ZIGO' PROFUMI SRL","indirizzo":"VIA GEMITO 70/72","citta":"NAPOLI"}},
        {"title":"ZIGO' PROFUMI SRL","acf":{"ragione_sociale":"ZIGO' PROFUMI SRL","indirizzo":"VIA CHIAIA 210","citta":"NAPOLI"}},
        {"title":"ZIGO' PROFUMI SRL","acf":{"ragione_sociale":"ZIGO' PROFUMI SRL","indirizzo":"STRADA PROVINCIALE 336 SANNITICA - OUTLET VILLAGE LA REGGIA","citta":"MARCIANISE"}},
        {"title":"ZIGO' PROFUMI SRL","acf":{"ragione_sociale":"ZIGO' PROFUMI SRL","indirizzo":"PIAZZA IV NOVEMBRE, 14","citta":"FONDI"}},
        {"title":"ZIGO' PROFUMI SRL","acf":{"ragione_sociale":"ZIGO' PROFUMI SRL","indirizzo":"VIA EPOMEO 90","citta":"NAPOLI"}},
        {"title":"ZIGO' PROFUMI SRL","acf":{"ragione_sociale":"ZIGO' PROFUMI SRL","indirizzo":"VIA LEPANTO 99","citta":"NAPOLI"}},
        {"title":"AEREOPORTO MILANO_MALPENSA","acf":{"ragione_sociale":"AEREOPORTO MILANO_MALPENSA","indirizzo":"National Road Aeroporto della Malpensa","citta":"FERNO"}},
        {"title":"AEREOPORTO MILANO_LINATE","acf":{"ragione_sociale":"AEREOPORTO MILANO_LINATE","indirizzo":"Viale Enrico Forlanini","citta":"SEGRATE"}},
        {"title":"AEREOPORTO ROMA_FIUMICINO","acf":{"ragione_sociale":"AEREOPORTO ROMA_FIUMICINO","indirizzo":"Via dell'Aeroporto di Fiumicino","citta":"ROMA"}}
        ,
        {"title":"AEREOPORTO ORIO AL SERIO","acf":{"ragione_sociale":"AEREOPORTO ORIO AL SERIO","indirizzo":"Via Leonardo Da Vinci","citta":"BERGAMO"}},
        {"title":"AEREOPORTO NAPOLI_CAPODICHINO","acf":{"ragione_sociale":"AEREOPORTO NAPOLI_CAPODICHINO","indirizzo":"Viale F. Ruffo di Calabria","citta":"NAPOLI"}},
        {"title":"AEREOPORTO VENEZIA","acf":{"ragione_sociale":"AEREOPORTO VENEZIA","indirizzo":"Viale Galileo Galilei","citta":"VENEZIA"}},
        {"title":"AEREOPORTO PALERMO","acf":{"ragione_sociale":"AEREOPORTO PALERMO","indirizzo":"Via dell'Aeroporto Falcone e Borsellino","citta":"PALERMO"}}
        ,
        {"title":"AEREOPORTO CATANIA","acf":{"ragione_sociale":"AEREOPORTO CATANIA","indirizzo":"Via Fontanarossa","citta":"CATANIA"}}
        ]
        );
    const [pv, setPv] = useState("");
    const [citta, setCitta] = useState([]);
    const [indirizzi, setIndirizzi] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [tappaSelected, setTappaSelected] = useState("");
    const [webinarSelected, setWebinarSelected] = useState("");
    const [isErrored, setIsErrored] = useState(false);

    const nomiPuntiVendita = useMemo(() => {
        let result = {};
        puntiVendita.forEach((el) => {
            result[el.acf?.ragione_sociale] = el.acf?.ragione_sociale;
        });

        return Object.values(result).sort();
    }, [puntiVendita]);

    // useEffect(() => {
    //     fetch("https://dev-web-137.becreatives.com/dg/wp-json/dolcegabbana/v1/punti-vendita")
    //         .then((response) => response.json())
    //         .then((data) => setPuntiVendita(data));
    // }, []);

    const changeCitta = (puntoVendita) => {
        const newCitta = _.uniqBy(
            puntiVendita.filter((el) => {
                return el.title === puntoVendita;
            }),
            (el) => el.acf?.citta
        );

        newCitta.sort((a, b) => a.acf.citta.toLowerCase().localeCompare(b.acf.citta.toLowerCase()));

        setCitta(newCitta);
    };

    const selectIndirizzi = (citta) => {
        const newIndirizzi = puntiVendita.filter((el) => {
            return el.acf?.citta === citta && el.acf?.ragione_sociale === pv;
        });
        newIndirizzi.sort((a, b) => a.acf.indirizzo.toLowerCase().localeCompare(b.acf.indirizzo.toLowerCase()));
        setIndirizzi(newIndirizzi);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        const body = serialize(form, { hash: true });

        const formData = new FormData();
        for (const name in body) {
            formData.append(name, body[name]);
        }

        formData.append("newsletter", "false");

        setIsSubmitting(true);
        const response = await fetch(
            "https://dev-web-137.becreatives.com/dgzzz/wp-json/contact-form-7/v1/contact-forms/5/feedback",
            {
                method: "POST",
                body: formData,
            }
        );

        if (!response.ok) {
            setIsSubmitting(false);
        } else {
            const data = await response.json();
            if (data.status === "validation_failed") {
                const tappaError = data.invalid_fields.find((item) => item.field === "webinar");

                if (tappaError) {
                    setIsErrored(true);
                }
            }

            setIsSubmitting(false);
            setIsSubmitted(true);

            // const tappa = TAPPE.find((item) => item.value === body.tappa);
            // setTappaSelected(tappa.label);
            const webinar = WEBINAR.find((item) => item.value === body.webinar);
            setWebinarSelected(webinar.thankyou);

            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    return (
        <div>
            <header className="App-header items-start md:items-center">
                <img src={logo} alt="logo" className="px-4 max-w-[196px] md:max-w-[362px]" />
            </header>

            <div className="container">
                {isSubmitted ? (
                    <>
                        {!isErrored ? (
                            <>
                                <h2 className="text-2xl font-bold py-8 mt-8 text-center">ISCRIZIONE AVVENUTA</h2>
                                <p className="text-center">L'appuntamento è il</p>
                                <p className="text-center font-bold text-lg my-4" dangerouslySetInnerHTML={{__html: webinarSelected}}></p>
                                {/* <p className="text-center">Riceverai una mail <br/>con il link allo zoom webinar</p> */}
                            </>
                        ) : (
                            <>
                                <h2 className="text-2xl font-bold py-8 mt-8 text-center">
                                    ISCRIZIONI CHIUSE
                                </h2>
                                <p className="text-center">
                                    Abbiamo raggiunto il numero massimo di iscritti. <br/>Grazie per averci contattato
                                </p>
                            </>
                        )}
                    </>
                ) : isSubmitting ? (
                    <div className="py-8 mt-8 text-center text-2xl">
                        <svg
                            className="animate-spin mx-auto"
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                        >
                            <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                        </svg>
                    </div>
                ) : (
                    <form className="form" onSubmit={onSubmit}>
                        <div className="form-group">
                            <label>NOME *</label>
                            <input type="text" name="nome" placeholder="IL TUO NOME" required />
                        </div>
                        <div className="form-group">
                            <label>COGNOME *</label>
                            <input type="text" name="cognome" placeholder="IL TUO COGNOME" required />
                        </div>
                        <div className="form-group">
                            <label>MAIL *</label>
                            <input type="email" name="email" placeholder="LA TUA E-MAIL" required />
                        </div>
                        <div className="form-group">
                            <label>NUMERO DI TELEFONO</label>
                            <input type="tel" name="tel" placeholder="IL TUO NUMERO DI TELEFONO" />
                        </div>

                        <h2 className="text-lg">PUNTO VENDITA</h2>
                        <div className="form-group">
                            <label>NOME DEL PUNTO VENDITA *</label>
                            <div className="relative">
                                <select
                                    required
                                    name="puntoVendita"
                                    onChange={(e) => {
                                        setPv(e.target.value);
                                        changeCitta(e.target.value);
                                        selectIndirizzi(citta);
                                    }}
                                >
                                    <option value="">Seleziona un punto vendita</option>
                                    {nomiPuntiVendita.map((pv) => (
                                        <option key={pv}>{pv}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>CITTA *</label>
                            <div className="relative">
                                <select name="citta" onChange={(e) => selectIndirizzi(e.target.value)} required>
                                    <option value="">Seleziona una città</option>
                                    {citta.map((pv) => (
                                        <option key={pv.title + pv.acf?.citta}>{pv.acf?.citta}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>INDIRIZZO *</label>
                            <div className="relative">
                                <select name="indirizzo" required>
                                    <option value="">Indirizzo del punto vendita</option>
                                    {indirizzi.map((pv) => (
                                        <option key={pv.acf?.indirizzo+pv.title}>{pv.acf?.indirizzo}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <h2 className="text-lg">SCEGLI LA TUA SESSIONE</h2>
                        <div className="form-group">
                            <label>SELEZIONA LA DATA *</label>
                            <div className="relative">
                                <select name="webinar" required>
                                    <option value="">Seleziona la data</option>
                                    {WEBINAR.map((item) => {
                                        return (
                                            <option key={item.value} value={item.value} disabled>
                                                {item.label}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="mb-16 checkbox-container mt-4">
                            <label className="block mb-2 text-[14px]">
                                <input type="checkbox" name="profiling" value="yes" defaultChecked={false} required />{" "}
                                Ho preso visione sull’
                                <a
                                    href="/privacy-policy.pdf"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="text-underline"
                                >
                                    informativa privacy
                                </a>
                            </label>
                        </div>
                        <div className="mt-4 mb-12">
                            <button className="uppercase bg-black hover:bg-grey text-white w-full md:w-[150px] h-[40px] mt-4 mb-8">
                                Invia
                            </button>

                            {/* <img src={webinarImage} className="mx-auto mb-2 max-w-[99%] ld:max-w-[90%] h-auto" alt="D&G webinar campaign image" />

                            <img src={webinarImage2} className="mx-auto mb-8 max-w-[99%] ld:max-w-[90%] h-auto" alt="D&G webinar campaign image" /> */}
                        </div>
                    </form>
                )}
            </div>

            <footer className="bg-black pt-16 pb-16 text-center text-white text-[8px]">
                <img src={logoWhite} className="mx-auto mb-8" alt="logo" />
                Site – managed by The Level S.r.l – Copyright © Dolce & Gabbana S.r.l. 2023 – All rights reserved – any
                reproduction of the contents is strictly forbidden
            </footer>

            {/* Version: 0.1 */}
        </div>
    );
}

export default App;
